import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { JobDetails } from './JobDetails.js';
import { Jobs } from './JobPage';
import { Paths } from 'app/navigate';
import { NotFound } from '@tradetrax/web-common';

export const JobRoutes = () => {
  return (
    <Switch>
      <Route path={Paths.JobDetails} component={JobDetails} />
      <Route exact path={Paths.Job} component={Jobs} />
      <Route component={NotFound} />
    </Switch>
  );
};
