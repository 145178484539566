import React, { useEffect, useRef } from 'react';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import navigate from 'app/navigate';
import {
  SubTasksList,
  SubTaskStatusList,
  getRowHeightListView,
  TaskNameContainer,
} from '@tradetrax/web-common/lib/Stages';
import {
  mongoToTrx,
  isTaskOverdue,
  getAssigneeBadgeClassName,
  handleTableScrollStickyColumn2,
} from '@tradetrax/web-common/lib/utils';
import { STATUS_MAP, ChipStatus, OnTime, EmptyState, EMPTY_ROW, renderCell } from '@tradetrax/web-common';

const COL_WIDTHS = {
  span: 32,
  assignee: 16 * 16,
  duration: 6 * 16,
  confirmed: 8 * 16,
  expStart: 8 * 16,
  expFinish: 8 * 16,
  status: 160,
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const Schedule = ({
  tasks,
  totalCount,
  account,
  expandedRows,
  tableRef,
  controller,
  isFilterSet,
  isLoading,
}) => {
  const expandedRowsListView = expandedRows.get('list');
  const showDaysBehind = false;
  const scrollDivRef = useRef(null);
  const assigneeKind = assigneeAccount => {
    const value = getAssigneeBadgeClassName(assigneeAccount.get('companyId'), account.get('_id'));
    return value;
  };
  const getRowHeight = ({ index }) => getRowHeightListView(index, tasks, expandedRowsListView);
  const onToggleRow = (rowData, index) => controller.toggleRow('list', rowData, index);
  const getIsExpanded = data => {
    const id = String(data.get('id'));
    return !!expandedRowsListView.get(id);
  };

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);
  useEffect(() => {
    handleTableScrollStickyColumn2({ target: scrollDivRef.current }, scrollDivRef);
  }, [tasks]);
  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!tasks.get(index)}
        loadMoreRows={() => {}}
        rowCount={isLoading ? 7 : totalCount}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div ref={scrollDivRef} style={{ flex: '1 1 auto', overflowX: 'auto', overflowY: 'hidden' }}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1250 ? width : 1250;
                return (
                  <Table
                    ref={ref => {
                      registerChild(ref);
                      tableRef.current = ref;
                    }}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyColumn2"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    onRowClick={() => {}}
                    overscanRowCount={2}
                    rowHeight={getRowHeight}
                    rowCount={isLoading ? 7 : totalCount}
                    rowClassName={({ index }) => (tasks.get(index) ? '' : 'loading')}
                    rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                    noRowsRenderer={noRowsRenderer(isFilterSet, isLoading)}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                    <Column
                      label="Task Name"
                      dataKey="name"
                      className="h-100  pt-3"
                      minWidth={350}
                      width={minWidth - WIDTHS - COL_WIDTHS.span}
                      cellRenderer={renderCell(({ cellData: taskName, rowData, rowIndex }) => {
                        const subTasks = rowData.get('children');
                        const canExpand = subTasks.size > 0;
                        const isExpanded = getIsExpanded(rowData);
                        const icon = isExpanded ? 'chevron-up' : 'chevron-down';
                        const status = rowData.get('status') === 'completed' ? 'completed' : 'open';
                        const isCritical = rowData.get('isCritical');
                        return (
                          <>
                            <div
                              className={cn('d-flex flex-row align-items-center text-truncate font-weight-bold', {
                                'ml-3 pl-1': !canExpand,
                              })}
                            >
                              {canExpand && (
                                <FontAwesomeIcon
                                  icon={icon}
                                  className="mr-2"
                                  onClick={() => onToggleRow(rowData, rowIndex)}
                                />
                              )}
                              <TaskNameContainer
                                to={{
                                  pathname: navigate.from.JobDetails.to.TaskDetails(
                                    { status, taskId: rowData.get('id') },
                                    false
                                  ),
                                  state: { referrer: 'schedule' },
                                }}
                                text={taskName}
                                width={350}
                                isKeyFinish={rowData.get('isKeyFinish')}
                                isKeyStart={rowData.get('isKeyStart')}
                                isCritical={isCritical}
                                isPreConstruction={rowData.get('isPreConstruction')}
                              />
                            </div>
                            {isExpanded && (
                              <SubTasksList subTasks={subTasks} rowData={rowData} controller={controller} />
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Account Assignee"
                      dataKey="assigneeAccount"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.assignee}
                      cellRenderer={renderCell(({ cellData: assigneeAccount }) => (
                        <span
                          className={cn(`text-truncate not-permission ${assigneeKind(assigneeAccount)}`, {
                            'sub-domain': !!assigneeAccount.get('company'),
                          })}
                          style={{ maxWidth: COL_WIDTHS.assignee }}
                        >
                          {assigneeAccount.get('company') || 'Not Assigned'}
                        </span>
                      ))}
                    />
                    <Column
                      label="Confirmed"
                      dataKey="startDateConfirmed"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.confirmed}
                      cellRenderer={renderCell(({ cellData }) => (
                        <div
                          className={cn('text-right cursor-default mr-5 pr-2', {
                            'text-success': !!cellData,
                            'text-gray-200': !cellData,
                          })}
                        >
                          <FontAwesomeIcon icon="check" />
                        </div>
                      ))}
                    />
                    <Column
                      label="Exp. Start"
                      dataKey="startDate"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.expStart}
                      cellRenderer={renderCell(({ cellData: startDate, rowData: task }) => {
                        const { isOverdueStart } = isTaskOverdue(task);
                        return (
                          <span className={cn('with-sublabel', { 'text-danger': isOverdueStart })}>
                            {mongoToTrx(startDate) || 'MM/DD/YY'}
                            {isOverdueStart && <span>Missed Start</span>}
                          </span>
                        );
                      })}
                    />
                    <Column
                      label="Exp. Finish"
                      dataKey="expectedFinishDate"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.expFinish}
                      cellRenderer={renderCell(({ cellData: endDate, rowData: task }) => {
                        const { isOverdueFinish } = isTaskOverdue(task);
                        return (
                          <span className={cn('with-sublabel', { 'text-danger': isOverdueFinish })}>
                            {mongoToTrx(endDate) || 'MM/DD/YY'}
                            {isOverdueFinish && <span>Missed Finish</span>}
                          </span>
                        );
                      })}
                    />
                    <Column
                      label="Duration"
                      dataKey="durationDays"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.duration}
                      cellRenderer={renderCell(({ cellData }) => `${cellData}d`)}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      className="h-100 mt-2 pt-3"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const isExpanded = getIsExpanded(rowData);
                        return (
                          <>
                            <div className="d-flex flex-column align-items-center">
                              <ChipStatus {...STATUS_MAP[cellData]} />
                              {showDaysBehind && (
                                <span className="position-absolute  pt-2">
                                  <OnTime onTime={rowData.get('daysBehind')} hideOnSchedule />
                                </span>
                              )}
                            </div>
                            {isExpanded && (
                              <SubTaskStatusList
                                task={rowData}
                                subTasks={rowData.get('children')}
                                controller={controller}
                                readonly
                              />
                            )}
                          </>
                        );
                      })}
                    />
                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};

const noRowsRenderer = (isFilterSet, isLoading) => () => {
  if (isLoading) return;
  if (isFilterSet)
    return (
      <EmptyState
        icon="wrench"
        title="No Matches Found"
        body="When there's a Task that meets the filters, it will appear here."
      />
    );
  return <EmptyState icon="bell" title="No Tasks Assigned" body="Access here Tasks once assigned." />;
};
