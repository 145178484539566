import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, EmptyState, TableRowDropDown, PrimaryButton } from '@tradetrax/web-common';
import { plural } from '@tradetrax/web-common/lib/utils';
import { UL } from './UL';
import navigate from 'app/navigate';
import { TextPlaceholder } from '@tradetrax/web-common/lib/LoadingPlaceholder';

export function CustomRoles({ context: { state, controller, selectedRole } }) {
  const isLoading = state.getIn(['customRoles', 'isLoading']);
  const rolesSize = state.get('customRoles').size;
  const { active, inactive } = state.toObject();
  const activeSize = active.size;
  const inactiveSize = inactive.size;
  const isEmpty = !isLoading && rolesSize === 0;

  const onToggleRole = cr => (_, e) => {
    e.stopPropagation();
    controller.toggleRoleActive(cr);
  };

  if (isEmpty)
    return (
      <>
        <Header controller={controller} />
        <span className="ml-4 pt-3 mb-5 font-weight-bold">0 Custom Roles</span>
        <EmptyState
          icon="gear"
          title="No Custom Roles Created"
          body="When you create a Custom Role, it will appear here."
        />
      </>
    );

  return (
    <>
      <Header controller={controller} />
      <Wrapper>
        <div className="list-area">
          <UL>
            <span className="pt-3 pb-3 font-weight-bold">{`${activeSize} Custom ${plural(activeSize, 'Role')}`}</span>
            {isLoading ? (
              <LoadingPlaceholder />
            ) : (
              <>
                {active.map((cr, index) => (
                  <li key={`cr-${index}`} className={cn({ selected: selectedRole?.get('_id') === cr.get('_id') })}>
                    <Link
                      className="main-link w-75"
                      to={{
                        pathname: navigate.to.CustomRoleDetails({ customRoleId: cr.get('_id') }, false),
                      }}
                    >
                      <span className="text-truncate d-block">{cr.get('name')}</span>
                    </Link>
                    <TableRowDropDown deactivate onSelect={onToggleRole(cr)} />
                  </li>
                ))}
                {!!inactiveSize && (
                  <div className="mt-3 ml-4 pl-2 pb-3 font-weight-bold">{`${inactiveSize} Deactivated Custom ${plural(
                    inactiveSize,
                    'Role'
                  )}`}</div>
                )}
                {inactive.map((cr, index) => (
                  <li
                    key={`cr-${index}`}
                    className={cn('w-100 inactive', { selected: selectedRole?.get('_id') === cr.get('_id') })}
                  >
                    <span className="text-gray-200 text-truncate w-75 d-block">
                      <FontAwesomeIcon icon="ban" className="mr-2" style={{ color: colors.red_30 }} />
                      {cr.get('name')}
                    </span>
                    <TableRowDropDown reactivate onSelect={onToggleRole(cr)} />
                  </li>
                ))}
              </>
            )}
          </UL>
        </div>
      </Wrapper>
    </>
  );
}

const Header = ({ controller }) => (
  <div className="d-flex flex-column flex-lg-row">
    <LabelHeaderContainer className="pl-4">
      With Custom Roles you can set default configurations for the different types of customers you may have. Create,
      edit and apply these pre-configured settings for your new users.
    </LabelHeaderContainer>
    <PrimaryButton className="ml-auto mr-5 text-nowrap" onClick={() => controller.openCreateRoleModal()}>
      Create Custom Role
    </PrimaryButton>
  </div>
);

const LoadingPlaceholder = () => {
  const n = 6;
  return [...Array(n)].map((_, i) => {
    const width = 250 + 100 * Math.floor(Math.random() * 2);
    return (
      <li key={i} className="is-loading">
        <TextPlaceholder style={{ width: `${width}px` }} />
      </li>
    );
  });
};

const Wrapper = styled.div`
  border-bottom: 1px solid ${colors.gray};
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .main-link {
    &:hover {
      color: ${colors.blue300};
      text-decoration: underline;
    }
    color: ${colors.gray800};
  }
  span {
    padding-left: 2rem;
  }
  .list-area {
    overflow: scroll;
    height: 100%;
  }

  li.is-loading {
    border-top: none;
    span {
      margin-left: 2rem;
    }
  }
`;

const LabelHeaderContainer = styled.p`
  width: 100%;
  padding-right: 80px;
  @media (min-width: 992px) {
    padding-right: 50px;
  }
`;
