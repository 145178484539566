import React from 'react';
import { fromJS, Map } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/AppContext';
import * as actions from './SubTasksActions';

const emptyState = fromJS({
  expanded: null,
  globalTasks: [],
  selectedTaskTypes: Map({ isLoading: true }),
});

export function SubTasksContext(globalTasks) {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState.set('globalTasks', globalTasks), appContext);

  React.useEffect(() => {
    controller.readTaskTypes();
  }, [controller]);

  return {
    state,
    controller,
  };
}
