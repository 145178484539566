import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common/lib/theme';
import { plural } from '@tradetrax/web-common/lib/utils';

const EMPTY_TITLE = 'Select multiple Tasks to assign them or send Update Requests.';

export function BulkHeader({ emptyTitle = EMPTY_TITLE, bulkContext, appContext, tasks, children }) {
  const { state, controller } = bulkContext;
  const selected = state.get('selectedTasks');
  const isManaging = state.get('isManaging');
  const isEmpty = !selected.size;
  const { getPermission } = appContext;
  const showSendUpdateRequests = getPermission('manage_update_request') !== 'no';
  const showAssignLeadInstaller = getPermission('task_update_installer') !== 'no';

  return (
    <BulkHeaderContainer className="ml-4 mx-1" isManaging={isManaging}>
      <Wrapper className={cn({ 'd-none': !isManaging })}>
        {isEmpty ? (
          <div className="text-gray-400 font-size-16 empty-label">{emptyTitle}</div>
        ) : (
          <div className="d-flex flex-row justify-content-between selected-wrapper">
            <span className="task-counter">
              {`${selected.size} ${plural(selected.size, 'Task')} Selected`}
              <button className="ml-2 p-1 xmark" onClick={() => controller.clearSelection()}>
                <FontAwesomeIcon icon="xmark" className="font-size-12" />
              </button>
            </span>
            <div className="d-flex flex-row justify-content-between">
              <button
                className={cn('btn btn-link btn-action', { 'd-none': !showSendUpdateRequests })}
                onClick={() => controller.openModalBulkUR(tasks)}
              >
                Send Update Request
              </button>
              <VerticalLine className={cn({ 'd-none': !showAssignLeadInstaller || !showSendUpdateRequests })} />
              <button
                className={cn('btn btn-link btn-action', { 'd-none': !showAssignLeadInstaller })}
                onClick={() => controller.bulkAssignLeadInstaller(tasks)}
              >
                Assign Lead Installer
              </button>
            </div>
          </div>
        )}
      </Wrapper>
      {children}
    </BulkHeaderContainer>
  );
}

const BulkHeaderContainer = styled.div`
  position: sticky;
  top: 24px;
  z-index: 1;
  width: 1130px;

  &::before {
    ${props => `
        ${props.isManaging && "content: '';"};
      `}
    position: absolute;
    height: 70px;
    width: 100px;
    background-color: ${colors.white};
    left: 0;
    top: -24px;
    z-index: -1;
    width: 100%;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: auto;
  background-color: ${colors.gray200};
`;

const Wrapper = styled.div`
  background-color: ${colors.blue_50};
  border-radius: 10px;
  padding: 0px;
  margin-bottom: 1rem;

  .empty-label {
    padding: 1rem;
  }
  .selected-wrapper {
    padding: 0.75rem;
  }
  .task-counter {
    font-size: 16px;
    background-color: ${colors.white};
    border-radius: 8px;
    padding: 0px 8px;
    color: ${colors.gray800};
  }
  button.xmark {
    height: 2rem;
    background: transparent;
    border: 0px;
    color: ${colors.gray800};
  }
  .btn-action {
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: bold;
  }
`;
