import React from 'react';
import moment from 'moment';
import { fromJS } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { markAsSync, markAsSideEffect, CustomDialog } from '@tradetrax/web-common';
import { plural } from '@tradetrax/web-common/lib/utils';
import { subsService } from 'services';

const DATE_FORMAT_ISO = 'YYYY-MM-DD';
const OVERDUE = 'overdue';
const OPEN = 'open';
const UPCOMING = 'upcoming';

markAsSync(selectTab);
export function selectTab(state, selectedTab) {
  if ((selectedTab === OPEN || selectedTab === OVERDUE) && state.get('isInvalidated')) {
    state = state.set('isInvalidated', false).set(OPEN, fromJS({ isLoading: true }));
    this.controller.readCounters();
    this.controller.readSchedulesForCommitments();
  }

  if (selectedTab === UPCOMING && state.getIn([UPCOMING, 'isLoading']) === true) {
    this.controller.readUpcomingCommitments();
  }

  return state.merge({ selectedTab });
}

markAsSync(initialize);
export function initialize(state) {
  const selectedTab = state.get('selectedTab');

  // will load the upcoming requests as initial loading.
  if (selectedTab === UPCOMING) {
    setTimeout(() => {
      // we need to defer the next action, to make sure the current one finish first.
      this.controller.readUpcomingCommitments();
    }, 1);
    // will cause open|overdue to load when either tab is selected.
    return state.set('isInvalidated', true);
  }

  // by default load open+overdue... then upcoming will be loaded when tab is selected.
  this.controller.readSchedulesForCommitments();
  return state;
}

export function readSchedulesForCommitments() {
  const currentDate = moment().format(DATE_FORMAT_ISO);

  return subsService
    .getCommitmentsByTask({}, { query: { currentDate } })
    .then(fromJS)
    .then(intervals => {
      const byStatus = overdue => interval => {
        return interval.update('commitments', commitments =>
          commitments.filter(item => item.getIn(['itemStatus', OVERDUE]) === overdue)
        );
      };
      const open = intervals.map(byStatus(false)).filter(interval => interval.get('commitments').size > 0);
      const overdue = intervals.map(byStatus(true)).filter(interval => interval.get('commitments').size > 0);
      return state => state.merge({ open, overdue });
    });
}

markAsSync(readUpcomingCommitments);
export function readUpcomingCommitments(state) {
  const status = state.getIn([UPCOMING, 'isLoading']);
  if (status === 'fetching' || status === false) return state;

  const timeIntervalType = UPCOMING;
  const currentDate = moment().format(DATE_FORMAT_ISO);
  subsService
    .getPreviousOrUpcomingCommitmentsByTask({}, { query: { currentDate, timeIntervalType } })
    .then(fromJS)
    .then(upcoming => {
      this.controller.dispatch([state => state.merge({ upcoming })]);
    });

  return state.setIn([UPCOMING, 'isLoading'], 'fetching');
}

markAsSideEffect(readCounters);
export function readCounters() {
  subsService
    .getCommitmentsByTaskCount()
    .then(fromJS)
    .then(counters => this.controller.dispatch([state => state.merge({ counters })]));
}

// do not export
function invalidate(state, kind) {
  if (kind !== OVERDUE) return state;
  return state.set('isInvalidated', true);
}

markAsSync(denyCommunity);
export function denyCommunity(state, kind, week, task, community) {
  const commitment = getCommitment(week, task, community, 'denied');
  const weekIndex = state.get(kind).indexOf(week);
  const taskIndex = week.get('commitments').indexOf(task);
  const path = [kind, weekIndex, 'commitments', taskIndex, 'buildersByTask', 0, 'communitiesByTask'];
  const communityIndex = state.getIn(path).indexOf(community);
  const { taskNumber } = commitment;

  path.push(communityIndex);
  // TODO: We need `createCommitments` to get fixed in order to get to know result payload.
  subsService
    .createCommitments([commitment])
    .then(() =>
      this.addAlert(
        `Successfully denied to commit to this request for ${commitment.taskNumber} ${task.get('name')} ${plural(
          taskNumber,
          'task'
        )} on ${community.get('name')}.`
      )
    )
    .then(this.controller.readCounters)
    .catch(() => {
      const community = state.getIn(path);
      this.controller.dispatch([state => state.setIn(path, community)]);
      this.addAlert('There was a problem denying to commit to this request. Please try again.', 'danger');
    });

  return invalidate(state, kind).updateIn(path, community => community.set('status', 'denied'));
}

markAsSync(commitCommunity);
export function commitCommunity(state, kind, week, task, community) {
  const commitment = getCommitment(week, task, community, 'committed');
  const weekIndex = state.get(kind).indexOf(week);
  const taskIndex = week.get('commitments').indexOf(task);
  const path = [kind, weekIndex, 'commitments', taskIndex, 'buildersByTask', 0, 'communitiesByTask'];
  const communityIndex = state.getIn(path).indexOf(community);
  path.push(communityIndex);

  // TODO: We need `createCommitments` to get fixed in order to get to know result payload.
  subsService
    .createCommitments([commitment])
    .then(() =>
      this.addAlert(
        `Successfully committed to request for ${commitment.taskNumber} ${task.get('name')} ${plural(
          commitment.taskNumber,
          'task'
        )} on ${community.get('name')}.`
      )
    )
    .then(this.controller.readCounters)
    .catch(() => {
      const community = state.getIn(path);
      this.controller.dispatch([state => state.setIn(path, community)]);
      this.addAlert('There was a problem committing to this request. Please try again.', 'danger');
    });

  return invalidate(state, kind).updateIn(path, community => community.set('status', 'committed'));
}

markAsSideEffect(denyTaskWeek);
export async function denyTaskWeek(kind, week, task) {
  const { isAccept } = await openDenyTaskWeekDialog(this.modal, task);
  if (!isAccept) return;

  const weekIndex = this.state.get(kind).indexOf(week);
  const taskIndex = week.get('commitments').indexOf(task);
  const path = [kind, weekIndex, 'commitments', taskIndex, 'buildersByTask', 0, 'communitiesByTask'];
  const communities = this.state.getIn(path);
  const commitments = communities
    .filter(community => community.get('status') === null)
    .map(community => getCommitment(week, task, community, 'denied'));
  const totalTasks = commitments.reduce((total, value) => total + value.taskNumber, 0);

  this.controller.dispatch([
    state =>
      invalidate(state, kind).updateIn(path, communities =>
        communities.map(community => community.update('status', status => (status === null ? 'denied' : status)))
      ),
  ]);

  // TODO: We need `createCommitments` to get fixed in order to get to know result payload.
  subsService
    .createCommitments(commitments.toArray())
    .then(() => {
      this.addAlert(
        `Successfully denied to commit to ${totalTasks} ${plural(totalTasks, 'request')} for ${task.get(
          'name'
        )} ${plural(totalTasks, 'task')}.`
      );
    })
    .then(this.controller.readCounters)
    .catch(() => {
      this.controller.dispatch([state => state.setIn(path, communities)]);
      this.addAlert('There was a problem denying to commit to this request. Please try again.', 'danger');
    });
}

markAsSideEffect(commitTaskWeek);
export async function commitTaskWeek(kind, week, task) {
  const { isAccept } = await openCommitTaskWeekDialog(this.modal, task);
  if (!isAccept) return;

  const weekIndex = this.state.get(kind).indexOf(week);
  const taskIndex = week.get('commitments').indexOf(task);
  const path = [kind, weekIndex, 'commitments', taskIndex, 'buildersByTask', 0, 'communitiesByTask'];
  const communities = this.state.getIn(path);
  const commitments = communities
    .filter(community => community.get('status') === null)
    .map(community => getCommitment(week, task, community, 'committed'));
  const totalTasks = commitments.reduce((total, value) => total + value.taskNumber, 0);

  this.controller.dispatch([
    state =>
      invalidate(state, kind).updateIn(path, communities =>
        communities.map(community => community.update('status', status => (status === null ? 'committed' : status)))
      ),
  ]);

  // TODO: We need `createCommitments` to get fixed in order to get to know result payload.
  subsService
    .createCommitments(commitments.toArray())
    .then(() => {
      this.addAlert(
        `Successfully committed to ${totalTasks} ${plural(totalTasks, 'request')} for ${task.get('name')} ${plural(
          totalTasks,
          'task'
        )}.`
      );
    })
    .then(this.controller.readCounters)
    .catch(() => {
      this.controller.dispatch([state => state.setIn(path, communities)]);
      this.addAlert('There was a problem committing to this request. Please try again.', 'danger');
    });
}

function getCommitment(week, task, community, status) {
  const communityId = community.get('communityId');
  const taskNumber = community.get('count');
  const gtlTaskId = task.get('_id');
  const from = moment(week.getIn(['interval', 'from']), DATE_FORMAT_ISO).format(DATE_FORMAT_ISO);
  const to = moment(week.getIn(['interval', 'to']), DATE_FORMAT_ISO).format(DATE_FORMAT_ISO);
  const commitment = { communityId, taskNumber, gtlTaskId, from, to, status };

  return commitment;
}

function openDenyTaskWeekDialog(modal, task) {
  return modal.open(CustomDialog, {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Deny All
      </>
    ),
    message: (
      <>
        By denying to these requests, you won't be able to change them later. Are you sure you want to deny all{' '}
        <strong>{task.get('name')}</strong> requests?
      </>
    ),
    titleAccept: 'Yes, Deny All',
    titleCancel: 'Cancel',
  });
}

function openCommitTaskWeekDialog(modal, task) {
  return modal.open(CustomDialog, {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Commit All
      </>
    ),
    message: (
      <>
        By committing to these requests, you won't be able to change them later. Are you sure you want to commit all{' '}
        <strong>{task.get('name')}</strong> requests?
      </>
    ),
    titleAccept: 'Yes, Commit All',
    titleCancel: 'Cancel',
  });
}
