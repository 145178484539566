import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styled from 'styled-components';
import { Breadcrumb } from 'react-bootstrap';
import { ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { Paths } from 'app/navigate';
import { CTRPage } from './Configuration/CTRPage';
import { TasksOfInterestPage } from './TasksOfInterest/TasksOfInterest.page';

export const DefinitionOfReadyPage = ({ location }) => {
  const tab = React.useMemo(() => {
    const { hash } = location;
    return hash === '#tasks-of-interest' ? hash : '#configuration';
  }, [location]);
  const setTab = () => {};

  return (
    <>
      <h4 className="font-weight-bold mx-4 my-4 ">Definition of Ready</h4>
      <div className="d-flex flex-row align-items-center justify-content-between ml-4">
        <Breadcrumb tag="nav">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.UpcomingTasks }}>
            Upcoming
          </Breadcrumb.Item>
          <Breadcrumb.Item active tag="span">
            Definition of Ready
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="d-flex flex-row justify-content-between mt-4" style={{ marginBottom: '36px' }}>
        <TabButtons onChange={setTab} type="radio" name="options" value={tab}>
          <ToggleButtonContainer
            as={Link}
            to={Paths.DefinitionOfReady}
            className={cn('btn btn-primary', { active: tab === '#configuration' })}
            value="#configuration"
          >
            Configuration
          </ToggleButtonContainer>
          <ToggleButtonContainer
            as={Link}
            to={Paths.TasksOfInterest}
            value="#tasks-of-interest"
            className={cn('btn btn-primary', { active: tab === '#tasks-of-interest' })}
          >
            Tasks of Interest
          </ToggleButtonContainer>
        </TabButtons>
      </div>
      <Content tab={tab} />
    </>
  );
};

const Content = ({ tab }) => {
  if (tab === '#configuration') {
    return <CTRPage />;
  }

  if (tab === '#tasks-of-interest') {
    return <TasksOfInterestPage />;
  }

  return <span />;
};

const TabButtons = styled(ToggleButtons)`
  .btn.btn-primary {
    width: 260.2px;
    height: 39px;
  }
`;

const ToggleButtonContainer = styled(ToggleButton)`
  ${props => `
    padding: ${props.subTasks ? '10px 5px' : '10px 10px '};
  `}
  max-width: 140px;

  @media (min-width: 992px) {
    max-width: 196px;
  }
  @media (min-width: 1200px) {
    max-width: 210px;
  }
`;
