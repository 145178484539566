import React from 'react';
import { useController } from '@tradetrax/web-common';
import { fromJS } from 'immutable';
import { useAppContext } from 'app/AppContext';
import * as actions from './WorkloadPlanningActions';

const emptyState = fromJS({
  isInvalidated: false,
  counters: { open: 0, overdue: 0 },
  open: { isLoading: true },
  overdue: { isLoading: true },
  upcoming: { isLoading: true },
});

export function WorkloadPlanningContext(selectedTab) {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState.merge({ selectedTab }), appContext);
  const isReadOnly = appContext.getPermission('commitment_request_management') === 'no';

  React.useEffect(() => {
    controller.initialize();
    controller.readCounters();
  }, [controller]);

  return { state, controller, isReadOnly };
}
