import React from 'react';

export const usePopover = popOverId => {
  const [show, setShow] = React.useState(false); // controls the popover

  React.useEffect(() => {
    const isInsidePoper = node => {
      if (!node) return false;
      if (node.id === popOverId) return true;
      return isInsidePoper(node.parentElement);
    };

    const onClick = e => {
      if (show) {
        if (isInsidePoper(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        setShow(false);
      }
    };

    global.document.addEventListener('click', onClick);
    return () => global.document.removeEventListener('click', onClick);
  }, [show, setShow, popOverId]);

  return [show, setShow];
};
