import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { fromJS } from 'immutable';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STATUS_MAP, ChipStatus, ChipStatusDropdown, DaysBehind, EMPTY_ROW, renderCell } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import {
  mongoToTrx,
  isTaskOverdue,
  hasPermissionForAssignedTask,
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  handleTableScrollStickyColumn2,
} from '@tradetrax/web-common/lib/utils';
import { AssigneeSelect } from '@tradetrax/web-common/lib/AssigneeSelect';
import navigate from 'app/navigate';

const MINIMUM_BATCH_SIZE = 25;

const COL_WIDTHS = {
  span: 16 * 2,
  jobName: 12 * 16,
  assignee: 16 * 16,
  duration: 6 * 16,
  confirmed: 8 * 16,
  expStart: 8 * 16,
  expFinish: 8 * 16,
  status: 160,
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export function TasksView({ appContext, context, isOpen, status, filter }) {
  const { state, controller, loaderRef, tableRef, filterContext } = context;
  const { tasks, totalCount, maxCount } = state.toObject();
  const showDaysBehind = false;
  const { account, hasPermission, appState } = appContext;
  const userId = appState.getIn(['user', '_id']);
  const { isFiltering } = filterContext;

  const rowClassName = rowData => cn('cursor-default', { loading: rowData === null || rowData === EMPTY_ROW });
  const hasPermissionToAssign = task =>
    hasPermissionForAssignedTask('task_update_installer', hasPermission, task, userId);
  const hasPermissionToUpdateStatus = task =>
    hasPermissionForAssignedTask('task_update_status', hasPermission, task, userId);

  const scrollDivRef = useRef(null);
  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);

  useEffect(() => {
    handleTableScrollStickyColumn2({ target: scrollDivRef.current }, scrollDivRef);
  }, [tasks]);

  return (
    <InfiniteLoader
      ref={loaderRef}
      isRowLoaded={({ index }) => !!tasks.get(index)}
      loadMoreRows={params => controller.loadMoreRows({ status, ...params })}
      rowCount={Math.min(maxCount, totalCount)}
      threshold={0}
      minimumBatchSize={MINIMUM_BATCH_SIZE}
      style={{ position: 'relative' }}
    >
      {({ onRowsRendered, registerChild }) => (
        <div ref={scrollDivRef} style={{ flex: '1 1 auto', overflowX: 'auto', overflowY: 'hidden' }}>
          <AutoSizer>
            {({ width, height }) => {
              const minWidth = width > 1200 ? width : 1200;
              return (
                <Table
                  ref={elm => {
                    tableRef.current = elm;
                    registerChild(elm);
                  }}
                  onRowsRendered={onRowsRendered}
                  className="trx-table stickyColumn2"
                  headerHeight={40}
                  width={minWidth}
                  height={height}
                  overscanRowCount={2}
                  rowHeight={80}
                  rowCount={Math.min(maxCount, totalCount)}
                  rowClassName={({ index }) => rowClassName(tasks.get(index))}
                  rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                  noRowsRenderer={() => (
                    <EmptyStateCenterContainer top="150px">
                      <TasksEmptyState isOpen={isOpen} isFiltering={isFiltering} />
                    </EmptyStateCenterContainer>
                  )}
                  onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                >
                  <Column width={COL_WIDTHS.span} label="" dataKey="" />
                  <Column
                    label="Task Name"
                    dataKey="name"
                    minWidth={250}
                    width={minWidth - WIDTHS - COL_WIDTHS.span}
                    cellRenderer={renderCell(({ cellData: taskName, rowData: task }) => (
                      <Link
                        className="main-link"
                        to={{
                          pathname: navigate.from.TaskStatus.to.TaskDetails(
                            { jobId: task.getIn(['job', 'id']), taskId: task.get('id') },
                            false
                          ),
                          state: { referrer: 'tasks' },
                        }}
                      >
                        <div className="text-truncate ">{taskName}</div>
                      </Link>
                    ))}
                  />
                  <Column
                    label="Job"
                    dataKey="job"
                    width={COL_WIDTHS.jobName}
                    cellRenderer={renderCell(({ cellData }) => {
                      const name = cellData ? cellData.get('name') : '';
                      const lotNumber = cellData ? cellData.get('lotNumber') : '';
                      const jobId = cellData ? cellData.get('id') : 'aaaabbbbccccdddd';
                      return (
                        <>
                          <Link to={navigate.to.JobDetails({ jobId }, false)} className="secondary-link">
                            <div className="text-truncate">{name}</div>
                          </Link>
                          {lotNumber && <span className="d-block text-muted text-truncate">{`LOT #${lotNumber}`}</span>}
                        </>
                      );
                    })}
                  />
                  <Column
                    label="Lead Installer Assignee"
                    dataKey="assigneeAccount"
                    width={COL_WIDTHS.assignee}
                    cellRenderer={renderCell(({ cellData: assigneeAccount, rowData: task, index }) => {
                      const { installerId, installerName } = assigneeAccount.toJS();
                      const isAssignable = hasPermissionToAssign(task);
                      const loggedUserId = appState.getIn(['user', '_id']);
                      const installer = installerId
                        ? fromJS({
                            _id: installerId,
                            name: installerName,
                          })
                        : null;
                      return (
                        <AssigneeSelect
                          index={index}
                          users={account.get('assignees')}
                          options={account.get('assigneesActive')}
                          currentUser={installer}
                          loggedUserId={loggedUserId}
                          onChange={assignee => isAssignable && controller.assignTask({ task, assignee })}
                          placeholder="Choose Installer"
                          hasPermission={isAssignable}
                        />
                      );
                    })}
                  />
                  {isOpen && (
                    <Column
                      label="Confirmed"
                      dataKey="startDateConfirmed"
                      width={COL_WIDTHS.confirmed}
                      cellRenderer={renderCell(({ cellData }) => (
                        <div
                          className={cn('text-right mr-5 pr-2', {
                            'text-success': !!cellData,
                            'text-gray-200': !cellData,
                          })}
                        >
                          <FontAwesomeIcon icon="check" />
                        </div>
                      ))}
                    />
                  )}
                  <Column
                    label="Exp. Start"
                    dataKey="startDate"
                    width={COL_WIDTHS.expStart}
                    cellRenderer={renderCell(({ cellData: startDate, rowData: task }) => {
                      const { isOverdueStart } = isTaskOverdue(task);
                      return (
                        <span className={cn('ml-1 with-sublabel', { 'text-danger': isOverdueStart })}>
                          {mongoToTrx(startDate) || 'MM/DD/YY'}
                          {isOverdueStart && <span>Missed Start</span>}
                        </span>
                      );
                    })}
                  />
                  <Column
                    label="Exp. Finish"
                    dataKey="expectedFinishDate"
                    width={COL_WIDTHS.expFinish}
                    cellRenderer={renderCell(({ cellData: endDate, rowData: task }) => {
                      const { isOverdueFinish } = isTaskOverdue(task);
                      return (
                        <span className={cn('ml-1 with-sublabel', { 'text-danger': isOverdueFinish })}>
                          {mongoToTrx(endDate) || 'MM/DD/YY'}
                          {isOverdueFinish && <span>Missed Finish</span>}
                        </span>
                      );
                    })}
                  />
                  <Column
                    label="Duration"
                    dataKey="durationDays"
                    width={COL_WIDTHS.duration}
                    cellRenderer={renderCell(({ cellData }) => `${cellData}d`)}
                  />
                  <Column
                    label="Status"
                    dataKey="status"
                    width={COL_WIDTHS.status}
                    cellRenderer={renderCell(({ cellData: status, rowData: task }) => {
                      const daysBehind = showDaysBehind && (isNaN(task.get('daysBehind')) ? 0 : task.get('daysBehind'));
                      const canUpdateStatus = hasPermissionToUpdateStatus(task);
                      return (
                        <div className="d-flex flex-column align-items-center">
                          {isOpen && canUpdateStatus ? (
                            <ChipStatusDropdown
                              {...STATUS_MAP[status]}
                              notStarted={status !== NOT_STARTED}
                              progress={status !== IN_PROGRESS}
                              completed={status !== COMPLETED}
                              onSelect={status => controller.updateStatus(task, status)}
                            />
                          ) : (
                            <ChipStatus {...STATUS_MAP[status]} />
                          )}
                          {daysBehind !== 0 && <DaysBehind daysBehind={daysBehind} />}
                        </div>
                      );
                    })}
                  />
                  <Column width={COL_WIDTHS.span} label="" dataKey="" />
                </Table>
              );
            }}
          </AutoSizer>
        </div>
      )}
    </InfiniteLoader>
  );
}

function TasksEmptyState({ isOpen, isFiltering }) {
  const titleEmptyState = isFiltering ? 'Not Matches Found' : 'No Open Tasks';
  const bodyEmptyState = isFiltering
    ? "When there's a Task that meets the filter, it will appear here."
    : 'When a task is not started or still in progress it will appear here.';

  if (isOpen) {
    return <EmptyState icon="wrench" title={titleEmptyState} body={bodyEmptyState} />;
  }
  return (
    <EmptyState icon="circle-check" title="No Completed Tasks" body="When a task is completed it will appear here." />
  );
}
