import { useController } from '@tradetrax/web-common';
import { fromJS } from 'immutable';
import { useEffect } from 'react';
import { useAppContext } from 'app/AppContext';
import * as actions from './TasksOfInterst.actions';

const emptyState = fromJS({
  isLoading: true,
  expanded: null,
  globalTasks: [],
  tasksOfInterest: [],
});

export function TasksOfInterestContext() {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, appContext);
  const canEdit = appContext.appState.getIn(['user', 'role']) === 'manager';

  useEffect(() => {
    controller.loadGTL();
    controller.loadTasksOfInterest();
  }, [controller]);

  return { state, controller, canEdit };
}
