import React from 'react';
import { TaskDefaultContent } from './TaskDefaultContent';
import { TaskExpFinishRequest } from './TaskExpFinishRequest';

const NEW_EXP_END_DATE = 'new-expected-end-date';
export const NEW_SYSTEM_START_DATE = 'new-start-date-request';

export function TaskDetailsContent({ task, handleChangeAssignee, controller, isSelfAssigned }) {
  // TODO: @leo we need to review here `changeRequest` is not comming but
  //       `changeRequestOnReviewByBuilder` is present.

  const Component = (() => {
    if (isSelfAssigned) {
      if (task.get('startDateConfirmationRequest')) return TaskDefaultContent;

      const type = task.getIn(['changeRequest', 'type']);
      const activeForCurrentUser = task.getIn(['changeRequest', 'activeForCurrentUser']);
      if (activeForCurrentUser) {
        if (type === NEW_EXP_END_DATE || type === NEW_SYSTEM_START_DATE) return TaskExpFinishRequest;
      }
    }

    return TaskDefaultContent;
  })();

  return (
    <Component
      task={task}
      controller={controller}
      handleChangeAssignee={handleChangeAssignee}
      isSelfAssigned={isSelfAssigned}
    />
  );
}
