import React from 'react';

import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';

export function Billing({ companyName }) {
  return (
    <div className="ml-4 text-gray-800">
      <BillingContainer>
        <UserNameLabel>{companyName}</UserNameLabel>
        <p className="font-size-16 mt-3">This Trade Account is currently on a free trial.</p>
      </BillingContainer>
    </div>
  );
}

const UserNameLabel = styled.span`
  font-weight: 700;
  font-size: 20px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const BillingContainer = styled.div`
  border-radius: 6px;
  background-color: ${colors.blue_50};
  padding: 25px 25px;
  width: 476px;
`;
