import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { InputGroup } from 'react-bootstrap';
import { TasksTypeahead } from '@tradetrax/web-common/lib/Typeahead/TasksTypeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';

// CTR = Custom Task Readiness
export const AddPrecedingTask = ({ context: { controller, state }, ctrTask }) => {
  const globalTasks = state.get('globalTasks');
  const precedingTasks = ctrTask.get('precedingTasks');
  const gtlTaskId = ctrTask.get('gtlTaskId');
  const [selected, setSelected] = React.useState([]);

  const tasks = React.useMemo(() => {
    const keys = precedingTasks.map(pt => pt.get('_id')).push(gtlTaskId);
    return globalTasks.filter(gt => keys.indexOf(gt.get('_id')) === -1).toArray();
  }, [globalTasks, precedingTasks, gtlTaskId]);

  const addTask = e => {
    e.preventDefault();
    const [globalTask] = selected;
    if (globalTask) {
      controller.addPrecedingTask(ctrTask, globalTask);
      setSelected([]);
    }
  };

  const onCancelClick = e => {
    e.preventDefault();
    setSelected([]);
  };

  return (
    <form onSubmit={addTask}>
      <InputGroup
        className={cn('position-relative', {
          'mb-5': precedingTasks.size > 0,
          'mb-4': precedingTasks.size === 0 && selected.length > 0,
        })}
      >
        <TasksTypeahead
          id="gtl"
          name="taks"
          bsSize="lg"
          placeholder="Choose Tasks for Definition of Ready"
          options={tasks}
          selected={selected}
          labelKey={option => option.get('name')}
          onChange={setSelected}
        />
        <AddCancelButtons className={cn({ 'd-none': selected.length === 0 })}>
          <IconButton type="submit">
            <span>
              <FontAwesomeIcon icon="check" />
            </span>
          </IconButton>
          <IconButton onClick={onCancelClick}>
            <span>
              <FontAwesomeIcon icon="xmark" />
            </span>
          </IconButton>
        </AddCancelButtons>
      </InputGroup>
    </form>
  );
};

const AddCancelButtons = styled.div`
  position: absolute;
  bottom: -34px;
  right: 0;
`;

const IconButton = styled.button`
  border-style: none;
  background-color: transparent;
  color: ${colors.gray400};
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 4px;

  &:disabled {
    color: ${colors.blue_30};
  }

  > span {
    width: 25px;
    height: 25px;
    display: inline-block;
    background-color: ${colors.gray};
    border-radius: 30px;
    border: solid 1px ${colors.white};
    box-shadow: 0px 1px 2px ${colors.gray700};
  }
`;
