import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '@tradetrax/web-common';

export function RemoveTaskOfInterestModal({ accept, cancel }) {
  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" /> Remove Task Type
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          By removing it, you will no longer be aware of this Task of Interest status for this Task Type. You can add it
          again later from the Global Task Library.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton variant="secondary" onClick={() => cancel()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={() => accept()}>Yes, Remove Task Type</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}
