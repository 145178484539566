import moment from 'moment';
import { fromJS } from 'immutable';
import { subsService } from 'services';
import { markAsSync, markAsSideEffect, EMPTY_JOB_FEED } from '@tradetrax/web-common';
import { setCheckedInStatus } from '@tradetrax/web-common/lib/utils';
import { ATTACHMENTS_JOB_VIEW } from '@tradetrax/web-common/lib/Photos/AlbumController';
import { CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { LIST_VIEW, GRID_VIEW } from './JobDetailsContext';
import { emptyFeedCount } from 'app/entities';

export { toggleView } from '@tradetrax/web-common/lib/CalendarView/CalendarActions';
export * from './JobDetails.RealTime.actions';

export function readJob(jobId) {
  return subsService
    .readJobDetail({}, { params: { jobId } })
    .then(fromJS)
    .then(job => state => state.set('job', job))
    .catch(error => {
      let hasPermission = true;
      if (error.httpCode === 404) hasPermission = false;
      return state => state.set('hasPermission', hasPermission);
    });
}

export function readJobUsers(jobId) {
  return subsService
    .readJobUsers({}, { params: { jobId } })
    .then(fromJS)
    .then(users => state => state.set('users', users));
}

export function readTasks(jobId) {
  return subsService
    .readJobTasks({}, { params: { jobId } })
    .then(fromJS)
    .then(tasks => tasks.map(setCheckedInStatus))
    .then(tasks => state =>
      state.merge({
        tasks,
        taskTypes: tasks.filter((task, i, self) => i === self.findIndex(t => t.get('name') === task.get('name'))),
        isLoading: false,
      })
    );
}

export function readJobFeed({ jobId, startIndex = 0, stopIndex = 10 }) {
  const utcOffset = moment().format('Z');
  const currentJobFeedCount = this.state.get('jobFeedCount');
  const isEmptyFeed = currentJobFeedCount === emptyFeedCount;

  const getFeedCount = () => {
    if (!isEmptyFeed) return Promise.resolve(currentJobFeedCount);
    return subsService.readJobFeedCount({}, { params: { jobId }, query: { utcOffset } }).then(fromJS);
  };

  const jobFeed = subsService
    .readJobFeed({}, { params: { jobId }, query: { utcOffset, start_index: startIndex, stop_index: stopIndex } })
    .then(fromJS);

  return Promise.all([getFeedCount(), jobFeed]).then(([feedCount, newFeed]) => state =>
    state
      .set('jobFeedCount', feedCount)
      .update('feed', feed => feed.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(newFeed).toArray()))
  );
}

markAsSync(setTab);
export function setTab(state, tab) {
  const currentURL = new URL(document.URL);
  const view = tab === 'schedule' && currentURL.hash === '#calendar' ? CALENDAR_VIEW : LIST_VIEW;
  if (view === LIST_VIEW) window.location.hash = tab;
  if (tab === 'feed') {
    this.feedLoaderRef.current?.resetLoadMoreRowsCache(true);
    state = state.set('feed', EMPTY_JOB_FEED).set('jobFeedCount', emptyFeedCount);
  }

  return state.set('tab', tab).set('view', view);
}

markAsSideEffect(updateTable);
export function updateTable(rowIndexes) {
  setTimeout(() => {
    rowIndexes.forEach(index => this.tableRef.current.recomputeRowHeights(index));
    this.tableRef.current.forceUpdateGrid();
  }, 50);
}

markAsSync(toggleRow);
export function toggleRow(state, view, row, index) {
  // const jobId = state.getIn(['job', '_id']);
  const id = String(row.get('id'));
  const path = ['expandedRows', view, id];
  const isExpanded = !!state.getIn(path);
  const newState = isExpanded ? state.deleteIn(path) : state.setIn(path, true);
  // const expandedRows = newState.get('expandedRows');
  // localStorage.setItem(JOB_STAGES_EXPANDED_STATE, JSON.stringify({ jobId, expandedRows }));
  this.controller.updateTable([index]);

  return newState;
}

markAsSync(toggleAttachmentsView);
export function toggleAttachmentsView(state, type) {
  this.attachmentsRef.current?.toggleView(type);
  const view = state.get('attachmentsView') === LIST_VIEW ? GRID_VIEW : LIST_VIEW;
  localStorage.setItem(ATTACHMENTS_JOB_VIEW, view);
  return state.set('attachmentsView', view);
}

markAsSync(setAttachmentsCount);
export function setAttachmentsCount(state, count) {
  return state.set('attachmentsCount', count);
}

markAsSync(loadAttachmentsView);
export function loadAttachmentsView(state) {
  const defaultView = localStorage.getItem(ATTACHMENTS_JOB_VIEW);
  return state.set('attachmentsView', defaultView || 'grid');
}
