import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

function formatTimeSpan(milliseconds) {
  const secs = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(secs / 60) % 60;
  const hours = Math.floor(secs / 3600);

  if (hours === 0) return minutes === 0 ? '0' : `${minutes} min`;

  return `${hours} h, ${minutes} min`;
}

export const WorkSummary = ({ summary }) => {
  const clockInDate = moment(summary.clockInDate);
  const clockOutDate = moment(summary.clockOutDate);
  const { workingTime, breakTime } = summary;

  let dateFormat = 'h:mm a';
  if (!clockInDate.isSame(clockOutDate, 'day')) dateFormat += ', MMM D';

  return (
    <UL>
      <li>
        <span>Start Time</span>
        <strong>{clockInDate.format(dateFormat)}</strong>
      </li>
      <li>
        <span>Finish Time</span>
        <strong>{clockOutDate.format(dateFormat)}</strong>
      </li>
      <li>
        <span>Total Time</span>
        <strong>{formatTimeSpan(workingTime + breakTime)}</strong>
      </li>
      <li>
        <span>Work Time</span>
        <strong>{formatTimeSpan(workingTime)}</strong>
      </li>
      <li>
        <span>Break Time</span>
        <strong>{formatTimeSpan(breakTime)}</strong>
      </li>
    </UL>
  );
};

const UL = styled.ul`
  background: #f9f9fa;
  border-radius: 6px;
  padding: 0;
  margin: 0;

  li {
    padding: 16px 24px 16px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      letter-spacing: 0.2px;
    }
  }

  li:not(:last-child) {
    border-bottom: 1px solid #ecf0fa;
  }
`;
