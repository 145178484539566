import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '@tradetrax/web-common/lib/Button';
import { Modal } from 'react-bootstrap';
import { colors } from '@tradetrax/web-common/lib/theme';

export const ForbiddenSection = ({ label, tasks }) => {
  if (!tasks || tasks.size === 0) return null;

  return (
    <>
      <p>{label}</p>
      <ForbiddenTasksList>
        <li className="header">
          <span>Tasks</span>
          <span>Job or LOT #</span>
        </li>
        {tasks
          .map(task => (
            <li className="task">
              <span>{task.get('name')}</span>
              <span>{task.getIn(['job', 'lotNumber']) ?? task.getIn(['job', 'name'])}</span>
            </li>
          ))
          .valueSeq()}
      </ForbiddenTasksList>
    </>
  );
};

export const StepPermission = ({ next, close, ok, variant, children }) => (
  <>
    <Modal.Header closeButton>
      <Modal.Title>
        <h4 className="font-weight-bold">
          <FontAwesomeIcon
            icon={variant}
            className={cn('mr-3', {
              'text-yellow-100': variant === 'warning',
              'text-danger': variant === 'circle-exclamation',
            })}
          />
          Important
        </h4>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className={cn('mt-n3 pb-3')}>{children}</Modal.Body>
    <Modal.Footer className="mt-3">
      {close ? (
        <PrimaryButton variant="secondary" className="mr-1" onClick={() => close()}>
          Cancel
        </PrimaryButton>
      ) : null}
      {next ? (
        <PrimaryButton className="ml-3" onClick={() => next()}>
          Next
        </PrimaryButton>
      ) : null}
      {ok ? (
        <PrimaryButton className="ml-3" onClick={() => ok()}>
          OK
        </PrimaryButton>
      ) : null}
    </Modal.Footer>
  </>
);

export const ForbiddenTasksList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    border-bottom: solid 1px ${colors.gray};
    span {
      display: inline-block;
      padding-top: 8px;
      padding-bottom: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:first-child {
        width: 58%;
      }
      &:last-child {
        width: 42%;
        padding-left: 12px;
      }
    }
  }

  li.header {
    color: ${colors.gray400};
    font-size: 14px;
  }

  li.task {
    font-size: 16px;
    color: ${colors.gray800};
    > :first-child {
      font-weight: bold;
    }
  }
`;
