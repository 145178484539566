import React from 'react';
import { RelevantTasks as RelevantTasksDetails } from '../../../Users/MyProfile/Notifications/RelevantTasks';
import { emptyList } from 'app/entities';

const filterGTLTasks = (gtl, relevantTasks) => {
  return gtl.filter(task => !relevantTasks.find(rTask => rTask.get('name') === task.get('name')));
};

export function RelevantTasks({ globalTasks, state, controller }) {
  const { customRole } = state.toObject();
  const relevantTasks = customRole.getIn(['settings', 'notifications', 'relevantTasks']) || emptyList;
  const gtlTasks = React.useMemo(() => filterGTLTasks(globalTasks, relevantTasks), [relevantTasks, globalTasks]);

  return (
    <div className="mb-5 pb-5" style={{ maxWidth: '620px' }}>
      <RelevantTasksDetails
        isMyProfile={false}
        controller={controller}
        state={state.set('relevantTasks', relevantTasks).set('globalTasks', gtlTasks)}
      />
    </div>
  );
}
