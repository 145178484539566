import { useEffect } from 'react';
import { fromJS, Map } from 'immutable';
import { useController } from '@tradetrax/web-common';
import * as actions from '@tradetrax/web-common/lib/UserProfile/UserProfileCommonActions';
import { subsService as userProfileService } from 'services';
import { useAppContext } from 'app/AppContext';

export const emptyUser = Map({ isEmpty: true });
export const emptyState = fromJS({ user: emptyUser, hasPermission: true, tab: 'profile' });

export const UserProfileController = userId => {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, { ...appContext, userProfileService });
  const { alert } = appContext;

  useEffect(() => {
    controller.getUser(userId);
  }, [controller, userId]);

  return { state, controller, alert };
};
