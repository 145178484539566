import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from '@tradetrax/web-common/lib/EditableField/InlineInputEdit';
import { preventConsecutiveSpaceChar } from '@tradetrax/web-common/lib/utils';
import { SubTaskList } from './SubTaskList';
import * as yup from 'yup';

export function TaskCard({ expanded, controller, task, toggle }) {
  const subTasks = task.get('children');
  const isExistingName = (value, setError) => {
    if (!subTasks) return false;
    const nameMatch = subTasks.filter(subTask => subTask.get('name').toLowerCase() === value.toLowerCase());
    if (nameMatch.size === 0) return false;

    setError('name', { type: 'notMatch', message: 'Name already exists for this Task Type' });
    return true;
  };
  const removeSubTask = subTask => controller.removeSubTask(task, subTask);
  return (
    <li className={cn('task-card ', { expanded: expanded })}>
      <div className="mt-3">
        <button className="btn btn-link p-0 text-secondary" onClick={() => controller.deleteTaskType(task)}>
          <FontAwesomeIcon icon="trash" />
        </button>
        <strong data-testid="label-task-name" className="ml-3 mr-auto align-self-center">
          {task.get('gtlTaskName')}
        </strong>
        <button className="btn btn-link p-0 text-secondary" onClick={() => toggle(task)}>
          <FontAwesomeIcon icon={`chevron-${expanded ? 'up' : 'down'}`} className="mt-2" />
        </button>
      </div>
      <div className={cn('font-size-14 mt-3 flex-column', { 'd-none': !expanded })}>
        <Editor
          field="name"
          placeholder="Create a new Custom Sub-Task"
          maxWidth="auto"
          schema={schema}
          update={(_, subTaskName) => controller.addSubTask(task, subTaskName)}
          customValidation={isExistingName}
          onKeyDown={preventConsecutiveSpaceChar}
        />
        <SubTaskList subTasks={subTasks} removeSubTask={removeSubTask} />
      </div>
    </li>
  );
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required.')
    .matches(/^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~\s]+$/, 'Character not allowed')
    .min(2, 'Min. 2 characters')
    .max(99, 'Max. 99 characters'),
});
