import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import navigate, { Paths } from 'app/navigate';
import { Breadcrumb } from 'react-bootstrap';
import { PrimaryButton, ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { useFilterContext } from '@tradetrax/web-common/lib/Filters/Filters.context';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';
import { ALL_TASKS, TASK_TYPE, TasksBySelect } from '@tradetrax/web-common/lib/OuttakeIntake/TasksBySelect';
import { IntakeController, SCHEDULER_TAB, SUPER_TAB, INSTALLER_TAB } from './IntakeController';
import { IntakeTasks } from './IntakeTasks';
import { useAppContext } from 'app/AppContext';
import { IntakeTasksByType } from './IntakeTasksByType';

export function Intake({ match }) {
  const { communityId } = match.params;
  const intakeContext = IntakeController(communityId);
  const { state, controller, filterName } = intakeContext;
  const filterContext = useFilterContext(filterName);
  const appContext = useAppContext();
  const { community, tab, tasksBy } = state.toObject();
  const { account } = appContext;
  const assigneeOptions = tab === INSTALLER_TAB ? 'assigneesActive' : 'regularUsersActive';
  const assignees = account.get(assigneeOptions);
  const lookups = { assignees };

  const isFilterDisabled =
    tasksBy === TASK_TYPE ||
    state.getIn(['selectedTasks', tab, 'taskIds']).size > 0 ||
    state.getIn(['selectedTasks', tab, 'selectAll']);

  const isAssignButtonDisabled =
    (tasksBy === TASK_TYPE && !state.getIn(['selectedTasks', tab, 'taskNames']).size) ||
    (tasksBy === ALL_TASKS &&
      !state.getIn(['selectedTasks', tab, 'taskIds']).size &&
      !state.getIn(['selectedTasks', tab, 'selectAll']));

  return (
    <>
      <Breadcrumb className="mx-4 mt-4" tag="nav">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Community }}>
          Communities
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: navigate.to.CommunityDetails({ communityId }, false) }}>
          {community.get('name')}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Intake</Breadcrumb.Item>
      </Breadcrumb>
      <div className="mx-4 d-flex flex-row align-items-center justify-content-between">
        <span className="h4 pt-0 pt-md-3 mt-1 mb-0 font-weight-bold">{`Intake for ${community.get('name')}`}</span>
        <div className="d-flex">
          <PrimaryButton
            disabled={isAssignButtonDisabled}
            onClick={() => controller.openBulkAssignmentModal(tasksBy)}
            className="mr-4 text-nowrap"
          >
            Assign Tasks
          </PrimaryButton>
          <FilterImplementation filterContext={filterContext} lookups={lookups} disabled={isFilterDisabled} />
        </div>
      </div>
      <div className="d-flex  flex-column flex-lg-row justify-content-between mt-4 mr-4 mb-2">
        <ToggleButtons onChange={controller.setTab} type="radio" name="options" value={tab}>
          <ToggleButtonContainer value={SUPER_TAB}>Super</ToggleButtonContainer>
          <ToggleButtonContainer value={SCHEDULER_TAB}>Scheduler</ToggleButtonContainer>
          <ToggleButtonContainer value={INSTALLER_TAB}>Installers</ToggleButtonContainer>
        </ToggleButtons>
        <div className="mt-4 ml-3 mb-4 mt-lg-2 ml-lg-3 mb-lg-0">
          <TasksBySelect tasksBy={tasksBy} onChange={controller.setTasksBy} />
        </div>
      </div>
      {tasksBy === ALL_TASKS && <IntakeTasks tab={tab} communityId={communityId} {...intakeContext} />}
      {tasksBy === TASK_TYPE && <IntakeTasksByType tab={tab} communityId={communityId} {...intakeContext} />}
    </>
  );
}

const ToggleButtonContainer = styled(ToggleButton)`
  min-width: 135px !important;
  max-width: 200px;

  @media (min-width: 992px) {
    width: 135px;
    max-width: 135px;
  }
  @media (min-width: 1200px) {
    width: 200px;
    max-width: 200px;
  }
`;
