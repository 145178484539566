import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { JobPageController } from './JobPageController';
import cn from 'classnames';

import { mongoToTrx, handleTableScrollStickyColumn2, scrollbarStyles } from '@tradetrax/web-common/lib/utils';
import { ChipStatus, STATUS_MAP, EMPTY_ROW, renderCell } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import navigate from 'app/navigate';

const MINIMUM_BATCH_SIZE = 25;

const COL_WIDTHS = {
  span: 16 * 2,
  community: 30 * 14,
  startDate: 20 * 7,
  finishDate: 20 * 7,
  lotNumber: 16 * 10,
  status: 160,
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const Jobs = () => {
  const { state, controller } = JobPageController();
  const { jobs, totalCount, maxCount } = state.toObject();
  const scrollDivRef = useRef(null);
  const rowClassName = rowData => (rowData === null || rowData === EMPTY_ROW ? 'loading' : '');

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);

  return (
    <>
      <div className="mx-4 mt-4 d-flex flex-row justify-content-between">
        <h4 className="font-weight-bold">Jobs</h4>
      </div>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!jobs.get(index)}
        loadMoreRows={controller.loadMoreRows}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <ScrollDiv ref={scrollDivRef}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1200 ? width : 1200;

                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyColumn2"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    // onRowClick={() => {}}
                    overscanRowCount={2}
                    rowHeight={80}
                    rowCount={Math.min(maxCount, totalCount)}
                    rowClassName={({ index }) => rowClassName(jobs.get(index))}
                    rowGetter={({ index }) => jobs.get(index) || EMPTY_ROW}
                    noRowsRenderer={() => (
                      <EmptyStateCenterContainer top="150px">
                        <EmptyState
                          icon="house"
                          title="No Jobs Assigned"
                          body="When a Job is assigned to your account it will appear here."
                        />
                      </EmptyStateCenterContainer>
                    )}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column width={COL_WIDTHS.span} dataKey="" label="" />
                    <Column
                      label="Name"
                      dataKey="name"
                      style={{ marginTop: '25px' }}
                      minWidth={250}
                      width={minWidth - WIDTHS - COL_WIDTHS.span}
                      cellRenderer={renderCell(({ cellData: jobName, rowData: job }) => (
                        <Link
                          className="main-link"
                          to={{
                            pathname: navigate.to.JobDetails({ jobId: job.get('_id') }, false),
                            state: { referrer: 'schedule' },
                          }}
                        >
                          <div className="text-truncate">{jobName}</div>
                        </Link>
                      ))}
                    />
                    <Column
                      label="LOT #"
                      dataKey="lotNumber"
                      maxWidth={COL_WIDTHS.lotNumber}
                      width={COL_WIDTHS.lotNumber}
                      className="overflow-hidden"
                      cellRenderer={renderCell(({ cellData }) => (
                        <span className={cn('d-inline-block text-truncate w-100', { 'text-gray-200': !cellData })}>
                          {cellData || 'Not Set'}
                        </span>
                      ))}
                    />
                    <Column
                      label="Community"
                      dataKey="communityName"
                      width={COL_WIDTHS.community}
                      cellRenderer={renderCell(({ cellData, rowData }) => (
                        <Link
                          className="secondary-link"
                          to={navigate.to.CommunityDetails({ communityId: rowData.get('communityId') }, false)}
                        >
                          {cellData}
                        </Link>
                      ))}
                    />
                    <Column
                      label="Exp. Start"
                      dataKey="startDate"
                      width={COL_WIDTHS.startDate}
                      cellRenderer={renderCell(({ cellData }) => (
                        <span>{mongoToTrx(cellData)}</span>
                      ))}
                    />
                    <Column
                      label="Exp. Finish"
                      width={COL_WIDTHS.finishDate}
                      dataKey="endDate"
                      cellRenderer={renderCell(({ cellData }) => (
                        <span>{mongoToTrx(cellData)}</span>
                      ))}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ cellData }) => (
                        <ChipStatus {...STATUS_MAP[cellData]} />
                      ))}
                    />
                    <Column width={COL_WIDTHS.span} dataKey="" label="" />
                  </Table>
                );
              }}
            </AutoSizer>
          </ScrollDiv>
        )}
      </InfiniteLoader>
    </>
  );
};

const ScrollDiv = styled.div`
  flex: 1 1 auto;
  ${scrollbarStyles}
`;
