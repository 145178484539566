import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { SecondaryButton, useTimeAgo } from '@tradetrax/web-common';
import { mongoToText } from '@tradetrax/web-common/lib/utils';

export const StartDateConfirmation = ({ controller, task, isSelfAssigned, canSendUpdateRequest }) => {
  const confirmationRequestDate = useTimeAgo(task.get('startDateConfirmationRequestDate'));

  if (!isSelfAssigned) return null;
  if (task.get('startDateConfirmed')) return null;
  if (!task.get('startDateConfirmationRequest')) return null;

  return (
    <Confirm>
      <div className="confirm-panel">
        <strong>Start Date Confirmation - {mongoToText(task.get('startDate'))}</strong>
        <p>By confirming this request, you agree that you will begin work on the Task on that date.</p>
        <div className="d-flex flex-row  justify-content-end">
          <Button
            variant="link"
            className="text-secondary font-weight-bold mr-3"
            disabled={!canSendUpdateRequest}
            onClick={() => controller.changeDate(task, canSendUpdateRequest)}
          >
            Change Date
          </Button>
          <SecondaryButton variant="outline-primary" onClick={() => controller.confirmDate(task)}>
            Confirm Start Date
          </SecondaryButton>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-end text-secondary font-size-14 mt-2">
        {confirmationRequestDate}
      </div>
    </Confirm>
  );
};

const Confirm = styled.div`
  margin-top: 32px;
  width: 32rem;
  .confirm-panel {
    background-color: #f0f1f7;
    border-radius: 6px;
    padding: 20px;
  }

  p {
    font-size: 14px;
    margin-top: 4px;
  }

  strong {
    font-size: 14px;
    font-weight: bold;
  }
`;
