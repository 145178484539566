import React from 'react';

export const useTimer = currentTime => {
  const delta = React.useMemo(() => new Date().getTime() - currentTime, [currentTime]);
  const [timer, setTimer] = React.useState(currentTime);

  React.useEffect(() => {
    // this interval will not always get executed exactly each 1000ms
    // that's why we need to calculate ellapsed time instead of just add 1sec
    // each cycle.
    const timeout = setInterval(() => {
      setTimer(new Date().getTime() - delta);
    }, 1000);

    return () => {
      clearInterval(timeout);
    };
  }, [setTimer, delta]);

  return timer;
};
