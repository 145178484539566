import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { useAppContext } from 'app/AppContext';
import { getSearch } from '@tradetrax/web-common';

const exp = search => installer =>
  search === '' ||
  installer
    .get('name')
    .toLowerCase()
    .includes(search);

export const InstallerPage = () => {
  const appController = useAppContext();
  const { installers: getInstallers } = appController;
  const search = getSearch();
  const [installers, setInstallers] = useState(getInstallers.filter(exp(search)));
  useEffect(() => setInstallers(getInstallers.filter(exp(search))), [getInstallers, search]);

  return (
    <div className="container-fluid">
      <Installers installers={installers} />
    </div>
  );
};

const InstallerRow = ({ installer }) => (
  <tr>
    <td>{installer.get('name')}</td>
    <td>{installer.get('addedDate')}</td>
  </tr>
);

const Installers = ({ installers }) => (
  <Card>
    <Card.Body>
      <Container fluid>
        <Row>
          <Col>
            <Card.Title>Installers</Card.Title>
          </Col>
        </Row>
      </Container>
    </Card.Body>
    <Card.Body>
      <div className="table-responsive">
        <table className="table table-bordered" width="100%" cellSpacing="0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Added Date</th>
            </tr>
          </thead>
          <tbody>
            {installers.map(installer => (
              <InstallerRow installer={installer} key={installer.get('installerUserId')} />
            ))}
          </tbody>
        </table>
      </div>
    </Card.Body>
  </Card>
);
