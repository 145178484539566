import React from 'react';
import styled from 'styled-components';

export const LoadingCards = ({ howMany = 5 }) => {
  return (
    <>
      <PlaceHolder className="h6" style={{ width: 256 }} />
      {Array.from({ length: howMany }, (v, k) => (
        <LoadingCard key={k} />
      ))}
    </>
  );
};

const PlaceHolder = styled.span`
  height: 12px;
  background-color: #e0e2e7;
  border-radius: 5px;
  display: inline-block;
`;

const LoadingCard = () => {
  return (
    <svg
      className="mb-2 mt-1"
      width="100%"
      height="84"
      viewBox="0 0 1015 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <rect x="2" y="1" width="1011" height="80" rx="8" fill="#F9F9FA" />
      </g>
      <rect x="23" y="24" width="304" height="12" rx="5" fill="#E0E2E7" />
      <rect x="502" y="36" width="142" height="12" rx="5" fill="#E0E2E7" />
      <rect x="711" y="36" width="150" height="12" rx="5" fill="#E0E2E7" />
      <rect x="908" y="42" width="81" height="12" rx="5" fill="#E0E2E7" />
      <rect x="908" y="60" width="81" height="12" rx="5" fill="#E0E2E7" />
      <rect x="23" y="49" width="214.122" height="12" rx="5" fill="#E0E2E7" />
      <path d="M882 11L882 71" stroke="#EDEDED" />
      <rect x="933" y="12" width="32" height="24" rx="8" fill="#E0E2E7" />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="1015"
          height="84"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
