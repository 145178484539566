import React, { useEffect } from 'react';
import { fromJS, Map } from 'immutable';
import { emptyJob, emptyTasks, emptyFeedCount } from 'app/entities';
import { useController, EMPTY_JOB_FEED } from '@tradetrax/web-common';
import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';
import * as actions from './JobDetailsActions';

export const LIST_VIEW = 'list';
export const GRID_VIEW = 'grid';

export const emptyExpandedState = Map({ stages: Map(), list: Map() });
export const emptyState = fromJS({
  job: emptyJob,
  tasks: emptyTasks,
  totalCount: 10,
  feed: EMPTY_JOB_FEED,
  jobFeedCount: emptyFeedCount,
  tab: 'schedule',
  hasPermission: true,
  expandedRows: emptyExpandedState,
  taskTypes: emptyTasks,
  users: [],
  view: LIST_VIEW,
  attachmentsView: GRID_VIEW,
  attachmentsCount: 0,
  isLoading: true,
});

export const JobDetailsContext = (jobId, defaultTab) => {
  const filterContext = useFilterContext(FILTER_NAME.JOB);
  const { filterState } = filterContext;
  const tableRef = React.useRef();
  const feedLoaderRef = React.useRef();
  const attachmentsRef = React.useRef();
  const currentURL = new URL(document.URL);
  const initView = currentURL.hash === '#calendar' ? CALENDAR_VIEW : LIST_VIEW;
  const initState = emptyState
    .setIn(['job', '_id'], jobId)
    .set('tab', defaultTab)
    .set('view', initView);
  const [state, controller] = useController(actions, initState, {
    filterState,
    tableRef,
    feedLoaderRef,
    attachmentsRef,
  });
  const { tab } = state.toObject();

  useEffect(() => {
    controller.loadAttachmentsView();
    controller.readJob(jobId);
    controller.readJobUsers(jobId);
    controller.readTasks(jobId);
  }, [controller, jobId, feedLoaderRef]);

  useEffect(() => {
    if (tab === 'feed') {
      window.location.hash = tab;
      controller.dispatch([state => state.set('feed', EMPTY_JOB_FEED).set('jobFeedCount', emptyFeedCount)]).then(() => {
        feedLoaderRef.current?.resetLoadMoreRowsCache(true);
      });
    }
  }, [tab, controller, feedLoaderRef, jobId]);

  useChannel(CHANNELS.JOB, jobId, controller.setTaskDetails);

  return { state, controller, feedLoaderRef, tableRef, attachmentsRef, filterContext };
};
