import { fromJS } from 'immutable';
import { markAsSync, markAsSideEffect } from '@tradetrax/web-common';
import { subsService } from 'services';
import { RemoveCTRModal } from './RemoveCTRModal';

markAsSync(toggle);
export function toggle(state, task) {
  return state.update('expanded', expanded => (expanded !== task ? task : null));
}

markAsSideEffect(loadGTL);
export function loadGTL() {
  subsService
    .readGTL({}, { query: { isChildTask: 'all' } })
    .then(fromJS)
    .then(tasks => this.controller.dispatch([state => state.set('globalTasks', tasks)]));
}

markAsSideEffect(loadCTR);
export function loadCTR() {
  const isLoading = false;
  subsService
    .getCtrTasks()
    // .then(() => [])
    .then(fromJS)
    .then(ctrTasks => this.controller.dispatch([state => state.merge({ ctrTasks, isLoading })]));
}

export function addCTRTask(globalTask) {
  const gtlTaskId = globalTask.get('_id');
  const name = globalTask.get('name');
  const body = { gtlTaskId, name, precedingTasks: [] };

  return subsService
    .createCtrTasks([body])
    .then(([ctrTask]) => {
      this.addAlert('Task Type successfully added.');
      return state =>
        state.set('expanded', gtlTaskId).update('ctrTasks', ctrTasks => ctrTasks.unshift(fromJS(ctrTask)));
    })
    .catch(this.errorMessage('There was a problem adding this Task Type', 'danger'));
}

markAsSideEffect(deleteCTRTask);
export async function deleteCTRTask(ctrTask) {
  const { isCancel } = await this.modal.open(RemoveCTRModal);
  if (isCancel) return;

  const taskId = ctrTask.get('_id');
  subsService
    .deleteCtrTask({}, { params: { taskId } })
    .then(() => {
      this.controller.dispatch([
        state =>
          state.update('ctrTasks', ctrTasks => {
            const index = ctrTasks.indexOf(ctrTask);
            return index >= 0 ? ctrTasks.remove(index) : ctrTasks;
          }),
      ]);
      this.addAlert('Task Type successfully removed.');
    })
    .catch(this.errorMessage('There was a problem removing this Task Type. Please try again.'));
}

export function addPrecedingTask(ctrTask, globalTask) {
  const taskId = ctrTask.get('_id');
  const _id = globalTask.get('_id');
  const name = globalTask.get('name');
  const body = { _id, name, status: 'in-progress' };

  return subsService
    .addPrecedingTasks([body], { params: { taskId } })
    .then(updatedCTRTask => state => {
      const index = state.get('ctrTasks').indexOf(ctrTask);
      return state.setIn(['ctrTasks', index], fromJS(updatedCTRTask));
    })
    .catch(this.errorMessage('There was a problem adding this Task for Definition of Ready. Please try again.'));
}

export function deletePrecedingTask(ctrTask, task) {
  const taskId = ctrTask.get('_id');
  const precedingTaskId = task.get('_id');

  return subsService
    .deletePrecedingTask({}, { params: { taskId, precedingTaskId } })
    .then(() => state => {
      const ctrIndex = state.get('ctrTasks').indexOf(ctrTask);
      this.addAlert('Task for Definition of Ready successfully removed.');

      return state.updateIn(['ctrTasks', ctrIndex, 'precedingTasks'], precedingTasks => {
        const index = precedingTasks.indexOf(task);
        return precedingTasks.remove(index);
      });
    })
    .catch(this.errorMessage('There was a problem removing this Task for Definition of Ready. Please try again.'));
}

export function precedingTaskInfoOn(ctrTask, task, status) {
  const taskId = ctrTask.get('_id');
  const precedingTaskId = task.get('_id');

  return subsService
    .updatePrecedingTask({ status }, { params: { taskId, precedingTaskId } })
    .then(() => state => {
      const ctrIndex = state.get('ctrTasks').indexOf(ctrTask);

      return state.updateIn(['ctrTasks', ctrIndex, 'precedingTasks'], precedingTasks => {
        const index = precedingTasks.indexOf(task);
        return precedingTasks.setIn([index, 'status'], status);
      });
    })
    .catch(this.errorMessage('There was a problem updating this Task for Definition of Ready. Please try again.'));
}
