import React from 'react';
// import { Link } from 'react-router-dom';
// import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
// import { Paths } from 'app/navigate';
import { CTRContext } from './CTRContext';
import { useAppContext } from 'app/AppContext';
import { CTRTasks } from './CTRTasks';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { SearchGTL } from '@tradetrax/web-common/lib/Inputs/SearchGTL';

// CTR = Custom Task Readiness
export const CTRPage = () => {
  const appContext = useAppContext(); //TODO: move to CTRContext
  const ctrContext = CTRContext(appContext);
  const canEdit = appContext.appState.getIn(['user', 'role']) === 'manager';
  const { state, controller } = ctrContext;
  const isLoading = state.get('isLoading');
  const ctrTasks = state.get('ctrTasks');
  const addTask = task => {
    controller.addCTRTask(task);
  };

  return (
    <div className="ml-0 pl-4">
      <div className={canEdit ? 'mb-4' : 'mb-5'}>
        <span className="font-size-14 d-block pr-1">
          {canEdit
            ? 'Select the Tasks that you and your teams need visibility into to understand whether a Job is truly ready.'
            : 'Only the Account Manager can set up the Definition of Ready.'}
        </span>
      </div>
      <Row className="mt-2 mr-0">
        <Col sm={12} md={10} lg={8} style={{ maxWidth: '575px' }}>
          {canEdit && <SearchGTL selectedTasks={ctrTasks} addTask={addTask} state={state} />}
          <CTRTasks context={ctrContext} canEdit={canEdit} />
        </Col>
      </Row>
      <EmptyStateCenterContainer top="0px">
        <EmptyState
          visible={!isLoading && ctrTasks.size === 0}
          icon="wrench"
          title="No Definition of Ready Added"
          body={
            <>
              When a Definition of Ready is added,
              <br />
              it will appear here.
            </>
          }
        />
      </EmptyStateCenterContainer>
    </div>
  );
};
