import React from 'react';
import { subsService } from 'services';
import { useCommunities, useTaskTypes } from '@tradetrax/web-common';
import { IconButton } from '@tradetrax/web-common/lib/Button';
import { CalendarView } from '@tradetrax/web-common/lib/CalendarView';
import { plural } from '@tradetrax/web-common/lib/utils';
import { FilterSets } from '@tradetrax/web-common/lib/Filters/FilterSets';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';
import { TaskContext, emptyState } from './TaskContext';
import { CALENDAR_VIEW, LIST_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { TasksView } from './TaskViews/TasksView';
import { useAppContext } from 'app/AppContext';
import navigate from 'app/navigate';
import { TextPlaceholder } from '@tradetrax/web-common/lib/LoadingPlaceholder';

export const TaskPage = ({ match }) => {
  const { status } = match.params;
  const appContext = useAppContext();
  const context = TaskContext({ status }, appContext);
  const { state, controller, filterContext } = context;
  const { tasks, totalCount, view, isLoading } = state.toObject();
  const communities = useCommunities(subsService);
  const taskTypes = useTaskTypes(subsService);
  const title = status.replace(/^\w/, c => c.toUpperCase());
  const isOpen = status === 'open';
  const isCalendar = view === CALENDAR_VIEW && isOpen;
  const isListView = view === LIST_VIEW || !isOpen;
  const isEmpty = tasks.size === 0 && state !== emptyState;
  const lookups = { communities, taskTypes };
  const filter = filterContext.filterState.get('values');
  const showOpenTotalTasks = isOpen && isListView && !isEmpty;

  return (
    <>
      <div className="mr-4 ml-5 my-4 d-flex flex-row justify-content-between">
        <div className="d-flex flex-column">
          <h4 className="font-weight-bold">{`${title} Tasks`}</h4>
          {isLoading && isListView ? (
            <TextPlaceholder width="80px" className="mt-3" />
          ) : (
            showOpenTotalTasks && (
              <div className="font-weight-bold mt-2">{`${totalCount} Open ${plural(totalCount, 'Task')}`}</div>
            )
          )}
        </div>
      </div>
      <FilterSets.Panel visible={status === 'open'} filterContext={filterContext}>
        <IconButton
          icon={isCalendar ? 'list' : 'calendar-days'}
          onClick={() => controller.toggleView()}
          className="mr-3 active-on-hover"
        />
        <FilterImplementation filterContext={filterContext} top="8rem" lookups={lookups} title="Open Tasks Filters">
          <FilterSets.Section filterContext={filterContext} />
        </FilterImplementation>
        {/* <TasksFilter top="8rem" /> */}
      </FilterSets.Panel>
      {isListView && (
        <TasksView appContext={appContext} context={context} isOpen={isOpen} status={status} filter={filter} />
      )}
      {isCalendar && (
        <CalendarView filter={filter} appContext={appContext} calendarService={subsService} navigate={navigate} />
      )}
    </>
  );
};
