import moment from 'moment';
import { subsService } from 'services';
import { ResendInviteModal } from './ResendInviteModal';
import { markAsSideEffect } from '@tradetrax/web-common';

markAsSideEffect(resendInvite);
export async function resendInvite(user, path = ['user']) {
  const { isCancel, ...rest } = await this.modal.open(ResendInviteModal, { user });

  if (isCancel) return;

  const userId = user.get('_id');
  const { viaEmail, viaSMS } = rest;
  const params = { userId };
  const form = {};
  const query = {};

  if (viaEmail) {
    form.email = user.get('email');
    query.email = true;
  }
  if (viaSMS) {
    form.phone = user.get('phone');
    query.sms = true;
  }

  this.controller.dispatch([state => state.updateIn(path, user => user.set('sendingInvite', true))]);

  return subsService
    .sendInviteUser(form, { params, query })
    .then(() => {
      this.alert.success({
        message: 'Invite successfully sent. User will receive an invite to join TradeTrax.',
      });
      const lastActivity = moment().toISOString();
      this.controller.dispatch([
        state => state.updateIn(path, user => user.merge({ lastActivity, sendingInvite: false })),
      ]);
    })
    .catch(err => {
      this.controller.dispatch([state => state.updateIn(path, user => user.set('sendingInvite', false))]);
      this.alert.error({ message: 'There was a problem sending this invite. Please try again.' });
      throw err;
    });
}
