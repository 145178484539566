import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UnstyledStartButton = ({ className, controller }) => {
  return (
    <button className={`btn btn-success mr-3 ${className}`} onClick={() => controller.start()}>
      <FontAwesomeIcon icon="stopwatch" />
      <span>Start Day</span>
    </button>
  );
};

export const StartButton = styled(UnstyledStartButton)`
  border-radius: 50%;
  > svg {
    font-size: 18px;
  }

  > span {
    display: none;
  }

  &:hover {
    border-radius: 50rem;
    > span {
      display: inline;
      letter-spacing: 0.1px;
      font-weight: bold;
      font-size: 14px;
      margin: 0 8px;
    }
  }
`;
