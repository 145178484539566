import { subsService } from 'services';
import { markAsSideEffect } from '@tradetrax/web-common/lib/useController';
import { TaskUpdateRequestModal } from './UpdateRequest.modal';
import { fromJS, List } from 'immutable';
import { formatISO } from '@tradetrax/web-common/lib/utils';
import { AssignLeadInstallerModal } from './AssignLeadInstaller.modal';
import { taskId } from './shared';

// TODO: this file is moked/fake not yet implemented
export * from '@tradetrax/web-common/lib/BulkActions/Bulk.shared.actions';

function getSelectedTasks(state, upcomingTasks) {
  const selectedTasksIds = state.get('selectedTasks');
  return upcomingTasks.filter(task => selectedTasksIds.indexOf(taskId(task)) >= 0);
}

markAsSideEffect(openModalBulkUR);
export async function openModalBulkUR(upcomingTasks) {
  const selectedTasks = getSelectedTasks(this.state, upcomingTasks);
  const updateRequestPermission = this.getPermission('manage_update_request');
  const userId = this.appState.getIn(['user', '_id']);
  let forbiddenTasks = List();
  let permitedTasks = List();

  if (updateRequestPermission === 'yes') {
    permitedTasks = selectedTasks;
  } else if (updateRequestPermission === 'assigned') {
    selectedTasks.toArray().forEach(task => {
      if (task.getIn(['userSuper', '_id']) === userId) {
        permitedTasks = permitedTasks.push(task);
      } else if (task.getIn(['userScheduler', '_id']) === userId) {
        permitedTasks = permitedTasks.push(task);
      } else {
        forbiddenTasks = forbiddenTasks.push(task);
      }
    });
  }

  const blockedTasks = permitedTasks.filter(task => !!task.get('startDateConfirmationRequest'));

  const { form, isDueWeather, isAccept } = await this.modal.open(TaskUpdateRequestModal, {
    tasks: permitedTasks,
    forbiddenTasks,
    blockedTasks,
    canProposeFinish: blockedTasks.size === 0,
  });

  if (!isAccept) return;

  const promises = [];
  if (form.startDate) {
    const body = { newStartDate: formatISO(form.startDate), ...(isDueWeather ? { reasons: ['weather'] } : {})};
    const query = { requestType: 'new-start-date-request' };
    const tasks = permitedTasks.filter(task => !task.get('startDateConfirmationRequest'));
    if (tasks.size) promises.push(bulkUpdateRequest(body, query, formatTasks(tasks)));
    if (blockedTasks.size) {
      body.startDateConfirmation = true;
      promises.push(bulkUpdateRequest(body, query, formatTasks(blockedTasks)));
    }
  } else {
    const body = { newEndDate: formatISO(form.finishDate) };
    const query = { requestType: 'new-expected-end-date' };
    promises.push(bulkUpdateRequest(body, query, permitedTasks));
  }

  Promise.all(promises)
    .then(fromJS)
    .then(() => {
      this.parentContext.controller.loadUpcomingTasks();
      this.addAlert('Update request successfully sent.', 'success');
    })
    .catch(err => this.addAlert('There was a problem sending this update request. Please try again.', 'danger'));
}

function bulkUpdateRequest(body, query, tasks) {
  return subsService.sendMultipleRequests({ body, tasks, query }).catch(error => {
    throw error;
  });
}

function filterTasksByPermission(tasks, permission, userId) {
  let forbiddenTasks = List();
  let permitedTasks = tasks;

  if (permission === 'no') {
    permitedTasks = List();
    forbiddenTasks = tasks;
  } else if (permission === 'assigned') {
    permitedTasks = List();
    tasks.toArray().forEach(task => {
      if (task.getIn(['userSuper', '_id']) === userId) {
        permitedTasks = permitedTasks.push(task);
      } else if (task.getIn(['userScheduler', '_id']) === userId) {
        permitedTasks = permitedTasks.push(task);
      } else {
        forbiddenTasks = forbiddenTasks.push(task);
      }
    });
  }

  return { permitedTasks, forbiddenTasks };
}

markAsSideEffect(bulkAssignLeadInstaller);
export async function bulkAssignLeadInstaller(upcomingTasks) {
  const selectedTasks = getSelectedTasks(this.state, upcomingTasks);
  const userId = this.appState.getIn(['user', '_id']);
  const updateInstallerPermission = this.getPermission('task_update_installer');
  const { permitedTasks, forbiddenTasks } = filterTasksByPermission(selectedTasks, updateInstallerPermission, userId);

  const { form, isAccept } = await this.modal.open(AssignLeadInstallerModal, { tasks: permitedTasks, forbiddenTasks });

  if (!isAccept) return;

  const { assignee } = form;
  if (!assignee || assignee.length === 0) {
    console.log('No Lead selected!');
    return;
  }

  const [installer] = assignee;
  const body = {
    installerId: installer.get('userId'),
    installerName: installer.get('name'),
  };
  const tasks = permitedTasks.map(task => ({
    taskId: task.get('id'),
    jobId: task.getIn(['job', 'id']),
  }));

  subsService
    .bulkUpdateTask({ body, tasks })
    .then(fromJS)
    .then(() => {
      // TODO: pending what to do with response details
      //console.log(result.toJS());
      const successMsg =
        tasks.size === 1
          ? 'Lead Installer successfully assigned to this task.'
          : 'Lead Installer successfully assigned to these tasks.';
      this.parentContext.controller.loadUpcomingTasks();
      this.addAlert(successMsg, 'success');
    })
    .catch(() => {
      const errorMsg =
        tasks.size === 1
          ? 'There was a problem assigning a Lead Installer to this task. Please try again.'
          : 'There was a problem assigning a Lead Installer to these tasks. Please try again.';
      this.addAlert(errorMsg, 'danger');
    });
}

const formatTasks = tasks =>
  tasks.map(task => ({
    taskId: task.get('id'),
    jobId: task.getIn(['job', 'id']),
  }));
