import React from 'react';
import { fromJS, Map, List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { markAsSideEffect, markAsSync, CustomDialog } from '@tradetrax/web-common';
import { subsService } from 'services';

const dashKey = task =>
  task
    .get('gtlTaskName')
    .toLowerCase()
    .replace(/\s/g, '-');

export function readTaskTypes() {
  return subsService
    .readInternalTasks()
    .then(fromJS)
    .then(tasks => tasks.map(task => task.set('key', dashKey(task))))
    .then(response => {
      const taskTypes = response.size ? response : Map({ isEmpty: true });
      return state => state.set('selectedTaskTypes', taskTypes);
    });
}

markAsSync(expand);
export function expand(state, key) {
  return state.set('expanded', key);
}

markAsSync(addTask);
export function addTask(state, task) {
  const taskType = fromJS({
    gtlTaskId: task.get('_id'),
    gtlTaskName: task.get('name'),
  });
  const taskKey = dashKey(taskType);
  return state
    .update('selectedTaskTypes', tasks => {
      const isEmpty = tasks.get('isEmpty');
      if (isEmpty) return List([taskType.set('key', taskKey)]);
      return tasks.unshift(taskType.set('key', taskKey));
    })
    .set('expanded', taskKey);
}

markAsSideEffect(addSubTask);
export function addSubTask(task, name) {
  const isAlreadyCreated = !!task.get('createdOn');
  if (isAlreadyCreated) createSubTask.call(this, task, name);
  else createInternalTask.call(this, task, name);
}

function createInternalTask(task, name) {
  const index = this.state.get('selectedTaskTypes').indexOf(task);
  const gtlTaskId = task.get('gtlTaskId');

  subsService.createInternalTask({}, { params: { gtlTaskId } }).then(() => {
    subsService
      .createInternalTaskChild({ name }, { params: { gtlTaskId } })
      .then(fromJS)
      .then(response => {
        this.controller.dispatch([
          state => state.updateIn(['selectedTaskTypes', index], internalTask => internalTask.merge(response)),
        ]);
        this.addAlert('Custom Sub-Task successfully created for this Task Type.', 'success');
      })
      .catch(err => {
        this.addAlert('There was a problem creating this Custom Sub-Task. Please try again.', 'danger');
      });
  });
}

function createSubTask(task, name) {
  const index = this.state.get('selectedTaskTypes').indexOf(task);
  const gtlTaskId = task.get('gtlTaskId');

  subsService
    .createInternalTaskChild({ name }, { params: { gtlTaskId } })
    .then(({ _id }) => {
      const subTask = Map({ name, _id });
      this.controller.dispatch([
        state => state.updateIn(['selectedTaskTypes', index, 'children'], subTasks => List([subTask, ...subTasks])),
      ]);

      this.addAlert('Custom Sub-Task successfully created for this Task Type.', 'success');
    })
    .catch(err => {
      this.addAlert('There was a problem creating this Custom Sub-Task. Please try again.', 'danger');
    });
}

markAsSideEffect(deleteTaskType);
export async function deleteTaskType(task) {
  const { isAccept } = await this.modal.open(CustomDialog, {
    maxWidth: '554px',
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Remove Task Type
      </>
    ),
    message:
      'By removing it, all its Custom Sub-Tasks will be lost. You can add this Task Type again later from the Global Task Library.',
    titleAccept: 'Yes, Remove Task Type',
    titleCancel: 'Cancel',
  });
  if (!isAccept) return;

  const taskIndex = this.state.get('selectedTaskTypes').indexOf(task);
  const onRemoveTask = () => {
    this.addAlert('Task Type successfully removed.', 'success');
    this.controller.dispatch([
      state =>
        state.update('selectedTaskTypes', tasks => {
          const updated = tasks.splice(taskIndex, 1);
          if (!updated.size) return Map({ isEmpty: true });
          return updated;
        }),
    ]);
  };

  if (!task.get('createdOn')) return onRemoveTask();
  const gtlTaskId = task.get('gtlTaskId');
  subsService
    .deleteInternalTask({}, { params: { gtlTaskId } })
    .then(onRemoveTask)
    .catch(err => {
      this.addAlert('There was a problem removing this Task Type. Please try again.', 'danger');
    });
}

markAsSideEffect(removeSubTask);
export function removeSubTask(task, subTask) {
  const taskIndex = this.state.get('selectedTaskTypes').indexOf(task);
  const childIndex = this.state.getIn(['selectedTaskTypes', taskIndex, 'children']).indexOf(subTask);

  const gtlTaskId = task.get('gtlTaskId');
  const childId = subTask.get('_id');

  subsService
    .deleteInternalTaskChild({}, { params: { gtlTaskId, childId } })
    .then(() => {
      this.addAlert('Custom Sub-Task successfully removed from Task Type.', 'success');
      this.controller.dispatch([
        state =>
          state.updateIn(['selectedTaskTypes', taskIndex, 'children'], subTasks => subTasks.splice(childIndex, 1)),
      ]);
    })
    .catch(err => {
      this.addAlert(
        'There was a problem removing this Custom Sub-Task from the Task Type. Please try again.',
        'danger'
      );
    });
}
