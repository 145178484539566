import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import * as actions from './TaskDetailsActions';
import { emptyJob, emptyTask } from 'app/entities';
import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';

const emptyState = fromJS({
  job: emptyJob,
  task: emptyTask,
  hasPermission: true,
});

export function TaskDetailsController({ taskId, jobId, appContext }) {
  const [state, controller] = useController(actions, emptyState, { ...appContext, refreshTask });
  const [, notificationsController] = appContext.notifications;

  // const refreshHistory = React.useMemo(
  //   () => ({ action }) => action === 'update-history' && controller.readTaskHistory(jobId, taskId),
  //   [controller, jobId, taskId]
  // );

  // useChannel(CHANNELS.TASK, `${jobId}_${taskId}`, refreshHistory);
  useChannel(CHANNELS.TASK, `${jobId}_${taskId}`, controller.setTaskDetails);

  React.useEffect(() => {
    controller.load(jobId, taskId);
    controller.readTaskHistory(jobId, taskId);
  }, [controller, taskId, jobId]);

  React.useEffect(() => {
    const loadTask = () => controller.load(jobId, taskId);
    const jobTaskId = `${jobId}_${taskId}`;

    notificationsController.onTaskChange(jobTaskId, loadTask);
    loadTask();

    return () => notificationsController.offTaskChange(jobTaskId, loadTask);
  }, [controller, notificationsController, taskId, jobId]);

  return { state, controller };
}

function refreshTask() {
  return updatedTask =>
    this.controller.dispatch([state => state.update('task', task => task.merge(fromJS(updatedTask)))]);
}
