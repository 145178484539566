import { useEffect } from 'react';
import { fromJS } from 'immutable';
import { useController, markAsSideEffect } from '@tradetrax/web-common';
import { useInfiniteScroll } from '@tradetrax/web-common/lib/ToDo';
import { emptyFilter } from '@tradetrax/web-common/lib/Task/TasksFilter';
import { emptyFilter as emptyRecentActivityFilter } from '@tradetrax/web-common/lib/Filters/RecentActivityFilter';
import * as dashboardActions from '@tradetrax/web-common/lib/Dashboard/DashboardActions';
// import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { subsService as dashboardService } from 'services';
import { UpdateRequestModal } from './v2/Requests/UpdateRequestModal';
import { useFiltersContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

const emptyToDo = {
  startToday: 0,
  finishToday: 0,
  confirmationRequests: 0,
};

const loadingCard = {
  id: '123456789123456789123456',
  isLoading: true,
  job: { id: '123456789123456789123456' },
};
const emptyCardsLoading = [...Array(3)].map(() => loadingCard);

const actions = { ...dashboardActions, openChangeDateModalUR, assignUpcomingTask };

export const emptyState = fromJS({
  tasksUpdateRequest: emptyCardsLoading,
  tasksOverdue: emptyCardsLoading,
  toDo: emptyToDo,
  upcomingTasks: [{ empty: true }],
  recentActivities: { isLoading: true, totalCount: 0, activities: [] },
  filter: {
    toDo: emptyFilter,
    upcoming: emptyFilter,
    requests: emptyFilter,
    recentActivity: emptyRecentActivityFilter,
  },
});

const todoQuery = { includeCtrStatus: true };

export function DashboardPageContext(appContext) {
  const { filtersState } = useFiltersContext();
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    dashboardService,
    todoService: dashboardService,
    todoQuery,
    isTrade: true,
    filtersState,
  });

  const toDoFilter = filtersState.getIn([FILTER_NAME.TO_DO, 'values']);
  const selectedDate = filtersState.getIn([FILTER_NAME.TO_DO, 'sessionValues', 'selectedDate']);
  const requestsFilter = filtersState.getIn([FILTER_NAME.UR_OVERDUE, 'values']);
  const upcomingFilter = filtersState.getIn([FILTER_NAME.UPCOMING, 'values']);
  const recentActivityFilter = filtersState.getIn([FILTER_NAME.RECENT_ACTIVITY, 'values']);
  const containerRef = useInfiniteScroll(controller.loadMoreRowsRecentActivity, 100);

  useEffect(() => {
    controller.readUpdateRequestsDashboard();
    controller.readTasksOverdueDashboard();
  }, [controller, requestsFilter]);

  useEffect(() => {
    controller.readUpcomingTasks();
  }, [controller, upcomingFilter]);

  useEffect(() => {
    controller.getRecentActivities();
  }, [controller, recentActivityFilter]);

  useEffect(() => {
    controller.loadStartTodayTasks();
    controller.loadFinishTodayTasks();
    controller.loadWithConfirmationRequestTasks();
  }, [controller, toDoFilter, selectedDate]);

  // const refreshCounters = React.useMemo(
  //   () => foo => {
  //     loadToDoSection();
  //     loadRequestsOverdueAndUpcoming();
  //   },
  //   [loadToDoSection, loadRequestsOverdueAndUpcoming]
  // );

  // useChannel(CHANNELS.ACCOUNT_TASK, user.get('accountId'), refreshCounters);

  return { state, controller, dashboardService, containerRef };
}

markAsSideEffect(openChangeDateModalUR);
export async function openChangeDateModalUR(task, bulkContext) {
  const isTrade = true;
  const { isCancel, isDeclined, proposedDate, reasonsWeather } = await this.modal.open(UpdateRequestModal, {
    task,
  });
  const reasons = reasonsWeather ? ['weather'] : [];

  if (isCancel) return;
  if (isDeclined) return this.controller.cancelExpFinishDateRequest({ task, isTrade, bulkContext });

  this.controller.acceptExpFinishDateUpdateRequest({ task, isTrade, proposedDate, bulkContext, reasons });
}

markAsSideEffect(assignUpcomingTask);
function assignUpcomingTask(task, assignee) {
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const index = this.state.get('upcomingTasks').indexOf(task);
  const installerId = assignee ? assignee.userId || assignee.installerUserId : null;
  const installerName = assignee ? assignee.name : null;

  return dashboardService
    .updateTask({ installerId, installerName }, { params: { jobId, taskId } })
    .then(() =>
      this.controller.dispatch([
        state =>
          state.updateIn(['upcomingTasks', index, 'assigneeAccount'], assigneeAccount =>
            assigneeAccount.set('installerId', installerId).set('installerName', installerName)
          ),
      ])
    )
    .catch(() => {
      this.alert.error({
        message: 'There was an error assigning this Task. Please try again.',
      });
    });
}
