import { SearchGTL } from '@tradetrax/web-common/lib/Inputs/SearchGTL';
import React from 'react';
import styled from 'styled-components';
import { SubTasksContext } from './SubTasksContext';
import { TaskList } from './TaskList';

export function SubTasks({ settingsContext }) {
  const { globalTasks } = settingsContext.state.toObject();
  const context = SubTasksContext(globalTasks);
  const { controller, state } = context;
  const selectedTaskTypes = state.get('selectedTaskTypes');
  const addTask = task => {
    controller.addTask(task);
    controller.expand(
      task
        .get('name')
        .toLowerCase()
        .replace(/\s/g, '-')
    );
  };
  return (
    <>
      <CustomSubTaskContainer className="d-flex flex-column mx-4 ">
        <p className="font-size-14 info ">
          Select a Task Type from the Global Task Library and create Custom Sub-Tasks for them, so you can manage them
          on the Job. Custom Sub-Tasks will appear similar to regular Sub-Tasks.
        </p>
        <div className="mt-3 tasks col-lg-10">
          <SearchGTL selectedTasks={selectedTaskTypes} addTask={addTask} state={state} />
          <TaskList {...context} />
        </div>
      </CustomSubTaskContainer>
    </>
  );
}

const CustomSubTaskContainer = styled.div`
  max-width: 900px;
  .info {
    padding-right: 0px;
    @media (min-width: 992px) {
      max-width: 650px;
    }
    @media (min-width: 1200px) {
      max-width: 770px;
    }
  }
  .tasks {
    @media (min-width: 1200px) {
      max-width: 550px;
    }
  }
`;
