import { List } from 'immutable';
import { markAsSync } from '../useController';

markAsSync(clearSelection);
export function clearSelection(state) {
  return state.set('selectedTasks', List());
}

markAsSync(toggleTask);
export function toggleTask(state, task, selected = undefined) {
  let selectedTasks = state.get('selectedTasks');

  const index = selectedTasks.indexOf(task);

  if (typeof selected === undefined) {
    // make a toggle: remove if exists push if it doesn't
    if (index >= 0) selectedTasks = selectedTasks.remove(index);
    else selectedTasks = selectedTasks.push(task);
  } else if (selected) {
    // push but prevent duplicated
    if (index === -1) selectedTasks = selectedTasks.push(task);
  } else if (index >= 0) selectedTasks = selectedTasks.remove(index);

  return state.set('selectedTasks', selectedTasks);
}

markAsSync(toggleManaging);
export function toggleManaging(state) {
  const isManaging = state.get('isManaging');

  if (isManaging) state = state.set('selectedTasks', List());

  return state.set('isManaging', !isManaging);
}
