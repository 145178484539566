import React from 'react';
import styled from 'styled-components';
import { MyTasks } from './MyTasks';
import { ReceiveUpdates } from './ReceiveUpdates';
import { RelevantTasks } from './RelevantTasks';
import { LeadTimeTasks } from './LeadTimeTasks';
import { colors } from '@tradetrax/web-common';

const Tabs = styled.div`
  border-right: 1px solid #e0e2e7;
`;

const Tab = styled.div`
  font-size: 16px;
  line-height: 22px;
  padding: 0.25rem 0;
  margin: 0.75rem 0;
  cursor: pointer;
  font-weight: ${props => (props.isSelected ? '700' : '400')};
  color: ${props => (props.isSelected ? colors.blue300 : colors.gray400)};

  &:hover {
    font-weight: ${props => (props.isSelected ? '700' : '400')};
    color: ${colors.blue400};
    text-decoration: underline;
  }
`;

export const Notifications = ({ ...props }) => {
  const { state, controller } = props;
  const [tab, setTab] = React.useState('my-tasks');
  const channels = state.getIn(['settings', 'events-settings', 'notificationsChannels']);
  const pushChecked = channels && channels.indexOf('push') >= 0;
  const emailChecked = channels && channels.indexOf('email') >= 0;

  const update = onlyChannels => controller.updateNotificationEventsSettings(onlyChannels);
  const onChange = (event, value) => controller.updateNotificationEvents(event, value);

  return (
    <div className="d-flex flex-row w-100 py-5">
      <TabsContainter>
        <Tab isSelected={tab === 'my-tasks'} onClick={() => setTab('my-tasks')}>
          My Tasks
        </Tab>
        <Tab
          data-testid="tab-relevant-tasks"
          isSelected={tab === 'relevant-tasks'}
          onClick={() => setTab('relevant-tasks')}
        >
          Other Relevant Tasks
        </Tab>
        <Tab
          data-testid="tab-lead-time-tasks"
          isSelected={tab === 'lead-time-tasks'}
          onClick={() => setTab('lead-time-tasks')}
        >
          Lead Time
        </Tab>
      </TabsContainter>
      <NotificationContainer>
        {tab === 'my-tasks' ? (
          <>
            <MyTasks update={update} pushChecked={pushChecked} emailChecked={emailChecked} />
            <ReceiveUpdates
              onChange={onChange}
              events={state.getIn(['settings', 'events'])}
              pushDisabled={!pushChecked}
              emailDisabled={!emailChecked}
            />
          </>
        ) : null}
        {tab === 'relevant-tasks' ? <RelevantTasks {...props} /> : null}
        {tab === 'lead-time-tasks' ? <LeadTimeTasks {...props} /> : null}
      </NotificationContainer>
    </div>
  );
};

const TabsContainter = styled(Tabs)`
  margin-left: 20px;
  padding-right: 10px;
  width: 25%;

  @media (min-width: 1200px) {
    margin-left: 40px;
    padding-right: 20px;
  }
`;

const NotificationContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  width: 75%;

  @media (min-width: 1200px) {
    padding-left: 40px;
    padding-right: 40px;
    width: 50%;
  }
`;
