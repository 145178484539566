import React from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import navigate, { Paths } from 'app/navigate';
import { ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { plural } from '@tradetrax/web-common/lib/utils';
import { CommunityDetailsContext } from './CommunityDetailsContext';
import { CommunityJobs } from './CommunityJobs';
import { CommunityDetailsHeader } from './CommunityDetailsHeader';

export function CommunityDetails({ match }) {
  const { communityId } = match.params;
  const communityContext = CommunityDetailsContext(communityId);
  const { state, controller } = communityContext;
  const { community, jobs, totalCount } = state.toObject();

  const loadMoreRows = params => controller.loadMoreRows({ ...params, communityId });

  const linkToIntake = () => navigate.from.CommunityDetails.to.CommunityIntake();

  if (!state.get('hasPermission')) return <Redirect to={Paths.NotPermission} />;

  return (
    <>
      <Breadcrumb className="mx-4 mt-4" tag="nav">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Community }}>
          Communities
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{community.get('name')}</Breadcrumb.Item>
      </Breadcrumb>
      <CommunityDetailsHeader communityContext={communityContext} linkToIntake={linkToIntake} />
      <ToggleButtons className="mr-auto" type="radio" name="options" defaultValue={1}>
        <ToggleButton value={1}>{`${totalCount} ${plural(totalCount, 'job', 'jobs')}`}</ToggleButton>
      </ToggleButtons>
      <CommunityJobs
        controller={controller}
        jobs={jobs}
        loadMoreRows={loadMoreRows}
        totalCount={totalCount}
        communityId={communityId}
      />
    </>
  );
}
