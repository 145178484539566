import React from 'react';
import { UserProfileController } from './UserProfileController';
import { Paths } from 'app/navigate';
import { UserProfileContainer } from '@tradetrax/web-common/lib/UserProfile/UserProfileContainer';

export const UserProfile = ({ match }) => {
  const { userId } = match.params;
  const { state, controller, alert } = UserProfileController(userId);

  return <UserProfileContainer state={state} controller={controller} alert={alert} paths={Paths} />;
};
