import { fromJS } from 'immutable';
import { subsService } from 'services';

const MINIMUM_BATCH_SIZE = 25;

export function loadMoreRows({ startIndex, stopIndex }) {
  return subsService.readJobs({}, { query: { start_index: startIndex, stop_index: stopIndex } }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    return state =>
      state
        .set('totalCount', totalCount)
        .set('maxCount', stopIndex + MINIMUM_BATCH_SIZE + 1)
        .update('jobs', jobs => jobs.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()));
  });
}
