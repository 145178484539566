import React from 'react';
import { fromJS, List } from 'immutable';
import { subsService, usersService } from 'services';
import { markAsSideEffect } from '@tradetrax/web-common';
import { DeactivateUserModal } from '@tradetrax/web-common/lib/Modal/DeactivateUserModal';
import { AddUserModal } from './AddUserModal';
export { resendInvite } from '../SharedActions';

markAsSideEffect(rowAction, addUser);

export function loadMoreRows({ startIndex, stopIndex, status = 'all' }) {
  const filter = this.filterState.get('values').toJS();
  const query = getQueryParam.call(this, startIndex, stopIndex, filter);

  return subsService
    .listUsers({}, { query })
    .then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
      return state =>
        state
          .set('totalCount', totalCount)
          .update('users', users => users.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()));
    })
    .catch(() => state => state.set('totalCount', 0).set('users', List()));
}

export async function resetListCache() {
  return Promise.resolve(state => {
    setTimeout(() => this.loaderRef.current.resetLoadMoreRowsCache(true), 1);
    return state.set('users', List()).set('totalCount', 10);
  });
}

export function updateUser(user, properties, extraProps) {
  const userId = user.get('_id');

  return subsService
    .updateUser(properties, { params: { userId } })
    .then(() => state => {
      const index = state.get('users').findIndex(user => user.get('_id') === userId);
      return state.updateIn(['users', index], user => user.merge({ ...properties, ...extraProps }));
    })
    .catch(err => {
      err.detail && this.alert.error({ message: err.detail });
      throw err;
    });
}

export async function rowAction(action, user) {
  if (action === 'reactivate') {
    const status = user.get('statusBeforeInactive') || 'active';
    return this.controller
      .updateUser(user, { status })
      .then(() => {
        this.appController.updateUserPermission(user, status);
        this.alert.success({ message: 'User successfully reactivated.' });
      })
      .catch(err => {
        this.alert.error({ message: 'There was a problem reactivating this user. Please try again later.' });
        throw err;
      });
  }

  if (action === 'deactivate') {
    const statusBeforeInactive = user.get('status');
    const { isAccept } = await this.modal.open(DeactivateUserModal);

    if (isAccept) {
      this.controller
        .updateUser(user, { status: 'inactive' }, { statusBeforeInactive })
        .then(() => {
          this.appController.updateUserPermission(user, 'inactive');
          this.alert.success({
            message: 'User successfully deactivated and revoked access to Tradetrax. You can reactivate user later. ',
          });
        })
        .catch(err => {
          this.alert.error({ message: 'There was a problem deactivating this user. Please try again later.' });
          throw err;
        });
    }
  }
}

export function addUser() {
  return this.modal.open(AddUserModal, {
    controller: this.controller,
    customRoles: this.state.get('customRoles'),
    alert: this.alert,
  });
}

export function readCustomRoles() {
  return subsService
    .listRoles({}, { query: { active: 'true' } })
    .then(response => state => state.set('customRoles', viewModel(response)));
}

function viewModel(response) {
  return fromJS(
    response.map(role => ({
      name: role.name,
      _id: role._id,
    }))
  );
}

export async function createUser(user) {
  //TODO: backend publish subsService.createUser
  return usersService.createUser(user).then(() => state => {
    // defer table refresh.
    setTimeout(() => this.loaderRef.current.resetLoadMoreRowsCache(true), 1);
    this.alert.success({
      message: 'Invite successfully sent. User will receive an invite to join TradeTrax.',
    });
    return state.set('users', List());
  });
}

export async function createInstaller({ email, phone, ...user }, query) {
  if (email) user.email = email;
  if (phone) user.phone = phone;

  return subsService.createInstaller(user, { query }).then(() => state => {
    // defer table refresh.
    setTimeout(() => this.loaderRef.current.resetLoadMoreRowsCache(true), 1);
    this.alert.success(getAddInstallerSuccessMessage(user));
    return state.set('users', List());
  });
}

function getAddInstallerSuccessMessage(user) {
  if (!user.password) {
    return {
      message: 'Invite successfully sent. User will receive an invite to join TradeTrax.',
    };
  }

  const { protocol, host } = window.location;
  const loginPage = `${protocol}//${host.replace('sub.', 'my.')}/login`;

  return {
    autoClose: false,
    size: 'xl',
    message: (
      <>
        <span className="mb-1">
          This user has been successfully created. Please write down these credentials to share them with user.
        </span>
        <br />
        Login:
        <a href={loginPage} className="mx-1 trx-link">
          {loginPage}
        </a>
        <br />
        Username:<strong className="mx-1">{user.username}</strong>
        <br />
        Pass:<strong className="mx-1">{user.password}</strong>
      </>
    ),
  };
}

export function getQueryParam(startIndex, stopIndex, filter) {
  const query = { start_index: startIndex, stop_index: stopIndex, status: 'all' };

  if (!filter) return query;
  if (filter.status) query.status = filter.status;

  return query;
}
