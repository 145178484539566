import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CommunityDetails } from './CommunityDetails/CommunityDetails.js';
import { CommunityPage } from './CommunityPage';
import { Paths } from 'app/navigate';
import { NotFound } from '@tradetrax/web-common';

export const CommunityRoutes = () => {
  return (
    <Switch>
      <Route exact path={Paths.CommunityDetails} component={CommunityDetails} />
      <Route exact path={Paths.Community} component={CommunityPage} />
      <Route component={NotFound} />
    </Switch>
  );
};
