import { fromJS, List } from 'immutable';
import { markAsSync, markAsSideEffect } from '@tradetrax/web-common';
import { redirectToLogin, redirectToHomeWebsite } from '@tradetrax/web-common/lib/redirectToLogin';
import { DEVICE_TOKEN } from '@tradetrax/web-common/lib/Notifications/NotificationsController';
import { readCookies } from '@tradetrax/web-common/lib/utils';
import { LegalAgreement } from '@tradetrax/web-common/lib/Modal';
import { usersService, subsService } from '../services';

export * from './stores/account';
export { updateFiltersOnDataBase } from '@tradetrax/web-common/lib/Dashboard/DashboardActions';

export function readTrades() {
  return subsService.readTrades().then(trades => state => state.set('trades', fromJS(trades)));
}

markAsSync(toggleOption);
export function toggleOption(state, option) {
  return state.set(option, !state.get(option));
}

markAsSideEffect(logout);
export async function logout() {
  const { isDemo, tokenDevices } = this.state.get('user').toObject();
  const token = localStorage.getItem(DEVICE_TOKEN);
  const body = {};
  if (token && tokenDevices.includes(token)) {
    body.deviceToken = token;
  }
  localStorage.clear();
  return subsService.logout(body).then(() => (isDemo ? redirectToHomeWebsite() : redirectToLogin(false)));
}

export function acceptCookies() {
  return usersService.acceptCookieBanner().then(() => {
    return state => state.set('cookieBannerAccepted', true);
  });
}

markAsSync(getCookies);
export function getCookies(state) {
  const cookies = readCookies();
  return state.set('cookieBannerAccepted', !!cookies['cookie-banner-accepted']);
}

markAsSideEffect(getSearchResults);
export function getSearchResults(text) {
  const query = text.replace('#', '');
  const currentResults = this.state.get('filteredResults');
  if (currentResults.size && query.length < 2) {
    this.controller.dispatch([state => state.set('globalSearchResults', List()).set('filteredResults', List())]);
  } else if (query.length >= 2) {
    subsService
      .search({}, { query: { query } })
      .then(fromJS)
      .then(response => {
        this.controller.dispatch([
          state => state.set('globalSearchResults', response).set('filteredResults', response),
        ]);
      });
  }
}

markAsSideEffect(openLegalAgreementModal);
export async function openLegalAgreementModal() {
  const legalAgreements = this.state.getIn(['account', 'legalAgreements']);
  const { isAccept } = await this.modal.open(LegalAgreement, { legalAgreements });
  if (!isAccept) return;

  const payload = {
    eulaAccepted: true,
    privacyPolicyAccepted: true,
    termsOfUseAccepted: true,
  };
  this.controller.dispatch([state => state.setIn(['account', 'isAcceptLegalAgreements'], true)]);

  subsService.updateSelf(payload).catch(err => {
    this.alert.error({ message: 'There was a problem accepting the Legal Agreement. Please try again.' });
  });
}
