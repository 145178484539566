import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { usersService } from './services';
import { redirectToLogin } from '@tradetrax/web-common';
import { getDeviceType } from '@tradetrax/web-common/lib/utils';
import { pendoInit } from '@tradetrax/web-common/lib/pendo';
import { DemoLanding } from '@tradetrax/web-common/lib/Landing/DemoLanding';
import '@tradetrax/web-common/scss/_tradetrax.scss';
import '@tradetrax/web-common/lib/font-awesome';
import './pendo.js';

const { pathname, href } = window.location;
if (/^\/token\/.+/.test(pathname)) {
  const token = pathname.substr(7);
  usersService
    .login({ token })
    .catch(() => window.location.replace(href.replace(/\/\/sub\./, '//my.')))
    .then(prestart);
} else {
  usersService
    .info()
    .catch(() => redirectToLogin())
    .then(prestart);
}

async function prestart(user) {
  if (user.isDemo) {
    ReactDOM.render(
      <DemoLanding onValidate={onValidate} usersService={usersService} />,
      document.getElementById('wrapper')
    );
  } else {
    start(user);
  }

  function onValidate() {
    if (global.location.pathname === '/location-required') {
      global.history.pushState('', 'Tradetrax Demo User', '/');
    }

    ReactDOM.unmountComponentAtNode(document.getElementById('wrapper'));
    start(user);
  }
}

function start(user) {
  if (user.domain !== 'sub') return redirectToLogin();
  // account.installers.forEach(installer => {
  //   installer.addedDate = formatTrx(new Date(installer.addedDate));
  // });
  pendoInit(user);
  document.cookie = 'userType=sub;domain=tradetrax.com';
  const device = getDeviceType({ navigator });
  ReactDOM.render(<App user={user} device={device} />, document.getElementById('wrapper'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
