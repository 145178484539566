import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Users } from './UsersPage/UsersPage';
import { UserProfile } from './UserProfile/UserProfile';
import { Paths } from 'app/navigate';
import { useAppContext } from 'app/AppContext';
import { NotPermission, NotFound } from '@tradetrax/web-common';
import { EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';

export const UserRoutes = () => {
  const { hasPermission } = useAppContext();

  if (!hasPermission('user_management'))
    return (
      <EmptyStateCenterContainer>
        <NotPermission />
      </EmptyStateCenterContainer>
    );

  return (
    <Switch>
      <Route path={Paths.Users} component={Users} exact />
      <Route path={Paths.UserProfile} component={UserProfile} exact />
      <Route component={NotFound} />
    </Switch>
  );
};
