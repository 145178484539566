import { useController } from '@tradetrax/web-common';
import { fromJS } from 'immutable';
import { useEffect } from 'react';
import * as actions from './CTRActions';

const emptyState = fromJS({
  isLoading: true,
  expanded: null,
  globalTasks: [],
  ctrTasks: [],
});

// CTR = Custom Task Readiness
export function CTRContext(appContext) {
  const [state, controller] = useController(actions, emptyState, appContext);

  useEffect(() => {
    controller.loadGTL();
    controller.loadCTR();
  }, [controller]);

  return { state, controller };
}
