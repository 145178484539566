import React from 'react';
import { LeadTimeTasks } from '../../../Users/MyProfile/Notifications/LeadTimeTasks';
import { emptyList } from 'app/entities';

export function LeadTime({ ...props }) {
  const { state, globalTasks } = props;
  const customRole = state.get('customRole');
  const leadTimeTasks = customRole.getIn(['settings', 'notifications', 'leadTimeTasks']) || emptyList;
  return (
    <div className="mr-2 pb-5 mt-2">
      <LeadTimeTasks
        {...props}
        isMyProfile={false}
        state={state.set('leadTimeTasks', leadTimeTasks).set('globalTasks', globalTasks)}
      />
    </div>
  );
}
