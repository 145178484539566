import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopHeader, UserInfoMenu } from '@tradetrax/web-common';
import { GlobalSearchTypeahead } from '@tradetrax/web-common/lib/Typeahead/GlobalSearchTypeahead';
import { useAppContext } from 'app/AppContext';
import navigate, { history, Paths } from 'app/navigate';
import { TimeTracker } from './TimeTracking/TimeTracker';

export default function TopNavBar() {
  const appContext = useAppContext();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const { appState, appController, notifications } = appContext;
  const [notificationsState, notificationsController] = notifications;
  const user = appState.get('user');
  const userName = `${user.get('firstName')} ${user.get('lastName')}`;
  const totalUnread = notificationsState.get('notifications').count(n => !n.get('read'));
  const timeTrackingEnabled = !!appState.getIn(['account', 'timeTrackingEnabled']);
  const onMenuSelect = option => {
    if (option === 'logout') appController.logout();
    if (option === 'my-profile') navigate.to.MyProfile();
    if (option === 'notifications') history.push(Paths.MyProfile, { tab: 'notifications' });
  };

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 1000);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      appController.toggleOption('expanded');
    }
  }, [isSmallScreen, appController]);

  return (
    <TopHeader sticky="top" expanded={appState.get('expanded')}>
      <Navbar.Brand as={Button} variant="link" onClick={e => appController.toggleOption('expanded')}>
        <FontAwesomeIcon icon="bars" className="mx-3" />
      </Navbar.Brand>
      <GlobalSearchTypeahead
        navigateToJob={jobId => navigate.to.JobDetails({ jobId })}
        navigateToTask={(jobId, taskId) => navigate.to.TaskDetails({ jobId, taskId, status: 'open' })}
        options={appState.get('filteredResults').toJS()}
        onInputChange={appController.getSearchResults}
      />
      <Nav className="ml-auto">
        {/* <Nav.Link as={Button} variant="link">
          <FontAwesomeIcon icon="gear" />
        </Nav.Link> */}
        {timeTrackingEnabled ? <TimeTracker appContext={appContext} /> : null}
        <Nav.Link
          data-testid="btn-toggle-notifications"
          as={Button}
          variant="link"
          onClick={e => notificationsController.toggleNotification()}
        >
          <div className="d-flex">
            <FontAwesomeIcon icon="bell" />
            <small className={cn('badge badge-danger', { invisible: totalUnread === 0 })}>{totalUnread}</small>
          </div>
        </Nav.Link>
      </Nav>
      <UserInfoMenu initials={user.get('initials')} name={userName} onSelect={onMenuSelect}>
        <Dropdown.Item eventKey="my-profile">
          <FontAwesomeIcon icon="circle-user" /> My Profile
        </Dropdown.Item>
        <Dropdown.Item eventKey="notifications">
          <FontAwesomeIcon icon="gear" /> Notifications
        </Dropdown.Item>
        <Dropdown.Divider />
      </UserInfoMenu>
    </TopHeader>
  );
}
