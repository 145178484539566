import React from 'react';
import { fromJS } from 'immutable';
import { useAppContext } from 'app/AppContext';
import { useController } from '@tradetrax/web-common';
import * as actions from './CommunityDetailsActions';
import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';

const emptyState = fromJS({
  community: {
    name: '',
    endDate: '',
    areaManagers: [],
  },
  users: [],
  jobs: [],
  totalCount: 10,
  hasPermission: true,
});

export const CommunityDetailsContext = communityId => {
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, appContext);

  React.useEffect(() => {
    controller.readCommunity(communityId);
    controller.readCommunityUsers(communityId);
  }, [controller, communityId]);

  useChannel(CHANNELS.COMMUNITY, communityId, controller.updateCommunityHeader);

  return { state, controller };
};
