import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { colors } from '@tradetrax/web-common';
import { Link } from 'react-router-dom';

const K = () => {};

const UnstyledTabs = ({ open = 39, overdue = 10, selected = 'open', onSelectTab = { K }, className }) => {
  return (
    <div className={className}>
      <div className="commitment-header">Commitment Requests</div>
      <div className="tabs-container">
        <div className={cn('commitment-tab ', { selected: selected === 'open' })}>
          <Link to="#open" className="btn btn-link p-0 text-decoration-none" onClick={() => onSelectTab('open')}>
            <div className="open-commitment">
              <div className="commitment-number">{open}</div>
              <div className="commitment-label">Open</div>
            </div>
          </Link>
        </div>
        <div className={cn('commitment-tab ', { selected: selected === 'overdue' })}>
          <Link to="#overdue" className="btn btn-link p-0 text-decoration-none" onClick={() => onSelectTab('overdue')}>
            <div className="overdue-commitment">
              <div className="commitment-number">{overdue}</div>
              <div className="commitment-label">Overdue</div>
            </div>
          </Link>
        </div>
        <div className={cn('commitment-tab ', { selected: selected === 'upcoming' })}>
          <Link
            to="#upcoming"
            className="btn btn-link p-0 text-decoration-none"
            onClick={() => onSelectTab('upcoming')}
          >
            <div className="upcoming-commitment">
              <div className="commitment-label text-center">Upcoming</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const Tabs = styled(UnstyledTabs)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${colors.gray500};
  margin-bottom: 40px;

  .commitment-header {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .tabs-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;

    @media (min-width: 992px) {
      gap: 30px;
    }

    .commitment-tab.selected {
      padding-bottom: 30px;
      border-bottom: solid 3px ${colors.blue300};
    }

    .open-commitment,
    .overdue-commitment,
    .upcoming-commitment {
      display: flex;
      flex-direction: row;
      width: 170px;
      height: 60px;
      background: ${colors.pearl};
      border: 2px solid ${colors.pearl};
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
      border-radius: 10px;
    }

    .commitment-number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 68px;
      height: 59px;
      margin-left: -1px;
      margin-top: -1px;
      background: ${colors.blue300};
      border-radius: 10px;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: ${colors.white};
    }

    .overdue-commitment .commitment-number {
      background: ${colors.red};
    }

    .commitment-label {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.gray800};
      height: 60px;
      width: 102px;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
    }

    .upcoming-commitment > .commitment-label {
      width: 100%;
    }
  }
`;
