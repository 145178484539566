import React from 'react';
import { fromJS } from 'immutable';
import { useController, markAsSideEffect, markAsSync } from '@tradetrax/web-common';
import { hasPermissionForAssignedTask } from '@tradetrax/web-common/lib/utils';
import { ToDoActions as toDoActions, useInfiniteScroll } from '@tradetrax/web-common/lib/ToDo';
import { updateFiltersOnDataBase } from '@tradetrax/web-common/lib/Dashboard/DashboardActions';
import { emptyFilter } from '@tradetrax/web-common/lib/Task/TasksFilter';
// import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { PrecedingStatusModal } from '@tradetrax/web-common/lib/Dashboard/Upcoming/PrecedingStatusModal';
import { useEvent } from '@tradetrax/web-common/lib/useEvents';
import { subsService as todoService } from 'services';
import navigate from 'app/navigate';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

const emptyList = {
  isLoading: true,
  totalCount: 0,
  tasks: [],
};
const emptyState = fromJS({
  startToday: emptyList,
  finishToday: emptyList,
  confirmationRequests: emptyList,
  isFilterActive: false,
  filter: emptyFilter,
  tab: 'expectedStart',
});

const tdvLink = (taskId, jobId, status) => {
  status = status === 'checked-in' ? 'open' : status;
  return navigate.to.TaskDetails({ taskId, jobId, status }, false);
};

const todoQuery = { includeCtrStatus: true };
const actions = { ...toDoActions, showPrecedingTasks, refresh, onCountersChange, updateFiltersOnDataBase, setTab };

export function ToDoContext(appContext) {
  const filterContext = useFilterContext(FILTER_NAME.TO_DO);
  const { filterState } = filterContext;
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    todoService,
    dashboardService: todoService,
    todoQuery,
    isTrade: true,
    filterState,
  });
  const containerRef = useInfiniteScroll(controller.loadMoreRows);
  const { appState, hasPermission } = appContext;
  const user = appState.get('user');
  const selectedDate = filterState.getIn(['sessionValues', 'selectedDate']);
  const filter = filterState.get('values');

  useEvent('task-counters:update', controller.onCountersChange);

  const canEditTaskStatus = React.useMemo(() => {
    const userId = user.get('_id');
    return task => hasPermissionForAssignedTask('task_update_status', hasPermission, task, userId);
  }, [user, hasPermission]);

  const canSendUpdateRequest = React.useMemo(() => {
    const userId = user.get('_id');
    return task => hasPermissionForAssignedTask('manage_update_request', hasPermission, task, userId);
  }, [user, hasPermission]);

  React.useEffect(() => {
    controller.refresh();
  }, [controller, selectedDate, filter]);

  // const refreshFromWebhook = React.useMemo(
  //   () => foo => {
  //     controller.refresh();
  //   },
  //   [controller]
  // );
  // useChannel(CHANNELS.ACCOUNT_TASK, user.get('accountId'), refreshFromWebhook);

  return { state, controller, canEditTaskStatus, tdvLink, containerRef, canSendUpdateRequest, filterContext, user };
}

markAsSideEffect(showPrecedingTasks);
async function showPrecedingTasks(task) {
  const precedingTasks = task.getIn(['ctr', 'precedingTasksWithStatus']);
  const canEdit = this.appState.getIn(['user', 'role']) === 'manager';
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.get('id');
  const loadTasksOfInterest = () => this.controller.loadTasksOfInterest(jobId, taskId);

  const { isAccept } = await this.modal.open(PrecedingStatusModal, {
    task,
    precedingTasks,
    loadTasksOfInterest,
    canEdit,
  });
  if (canEdit && isAccept && precedingTasks.size === 0) {
    navigate.to.PrecedingTasks({});
  }
}

markAsSideEffect(refresh);
function refresh() {
  this.controller.loadStartTodayTasks();
  this.controller.loadFinishTodayTasks();
  this.controller.loadWithConfirmationRequestTasks();
}

markAsSideEffect(onCountersChange);
function onCountersChange(taskCounters) {
  const { toDoStartCount, toDoEndCount, startDateConfirmationRequestCount } = taskCounters.toObject();
  const totalStartToday = this.state.getIn(['startToday', 'totalCount']) || 0;
  const totalFinishToday = this.state.getIn(['finishToday', 'totalCount']) || 0;
  const totalConfirmationRequests = this.state.getIn(['confirmationRequests', 'totalCount']) || 0;

  if (totalStartToday !== toDoStartCount) {
    this.controller.loadStartTodayTasks();
  }

  if (totalFinishToday !== toDoEndCount) {
    this.controller.loadFinishTodayTasks();
  }

  if (totalConfirmationRequests !== startDateConfirmationRequestCount) {
    this.controller.loadWithConfirmationRequestTasks();
  }
}

markAsSync(setTab);
function setTab(state, tab) {
  return state.set('tab', tab);
}
