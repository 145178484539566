import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import * as actions from './UsersPageActions';
import { useAppContext } from 'app/AppContext';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

export const emptyState = fromJS({
  users: [],
  totalCount: 10,
  customRoles: [],
});

export const UsersPageController = () => {
  const appContext = useAppContext();
  const filterContext = useFilterContext(FILTER_NAME.USERS);
  const { filterState } = filterContext;
  const loaderRef = React.useRef();
  const [state, controller] = useController(actions, emptyState, { ...appContext, loaderRef, filterState });
  const currentUserId = appContext.appState.getIn(['user', '_id']);

  const filter = filterState.get('values');

  React.useEffect(() => {
    controller.loadMoreRows(() => {});
  }, [controller, filter]);

  React.useEffect(() => {
    controller.readCustomRoles();
  }, [controller]);

  return { state, controller, loaderRef, currentUserId, filterContext };
};
