import React from 'react';
import cn from 'classnames';
import { Modal, Form, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { PrimaryButton } from '@tradetrax/web-common/lib/Button';
import { plural } from '@tradetrax/web-common/lib/utils';
import { AssigneesTypeahead as Typeahead } from '@tradetrax/web-common/lib/Typeahead/AssigneesTypeahead';
import { useAppContext } from 'app/AppContext';
import { StepPermission, ForbiddenSection } from './ForbiddenTasks';

export const AssignLeadInstallerModal = ({ tasks, forbiddenTasks, close, accept }) => {
  const { account, appState } = useAppContext();
  const [step, setStep] = React.useState(forbiddenTasks.size > 0 ? 'permission' : 'form');
  const form = React.useMemo(() => {
    const assignees = account.get('assigneesActive').toArray();
    return {
      users: account.get('regularUsersActive').toArray(),
      assignees,
    };
  }, [account]);

  const userId = appState.getIn(['user', '_id']);

  const { handleSubmit, control } = useForm({
    defaultValues: { assignee: [] },
  });

  const StepForm = () => (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          Assign Lead Installer
          <h6 className="font-weight-bold">{`${tasks.size} ${plural(tasks.size, 'Task')}`}</h6>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(form => accept({ form }))}>
        <Modal.Body className={cn('mt-n3')}>
          <Form.Group as={Col} md="12" controlId="formDate" className="d-flex flex-column px-0">
            <small className="text-muted">Assign to Lead Installer</small>
            <Controller
              control={control}
              name={'assignee'}
              defaultValue={[]}
              render={({ onChange, value, name }) => (
                <Typeahead
                  id="installer-assignee"
                  name={name}
                  placeholder="Choose Lead Installer"
                  options={form.assignees}
                  labelKey={option => getFullName(option, 'installer-assignee')}
                  filterSelected={(option, selected) => selected.get('_id') !== option.get('_id')}
                  selected={value}
                  onChange={onChange}
                  isMyId={option => userId === option.get('_id')}
                />
              )}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <PrimaryButton variant="secondary" className="mr-1" onClick={() => close()}>
            Cancel
          </PrimaryButton>
          <PrimaryButton type="submit" className="ml-3">
            Assign
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </>
  );

  const Content = () => {
    if (step === 'permission') {
      if (tasks.size === 0) {
        return (
          <StepPermission ok={close} variant="circle-exclamation">
            <ForbiddenSection
              label="You don’t have permission to assign any of these Tasks. Make sure to select at least one Task with permission."
              tasks={forbiddenTasks}
            />
          </StepPermission>
        );
      }
      return (
        <StepPermission close={close} next={() => setStep('form')} variant="warning">
          <ForbiddenSection
            label="You don’t have permission to assign a Lead Installer for the following Tasks and will be omitted:"
            tasks={forbiddenTasks}
          />
        </StepPermission>
      );
    }

    return <StepForm />;
  };

  return (
    <Modal show={true} onHide={close} id="upcoming-assign-lead-installer-modal" dialogClassName="width-600">
      <Content />
    </Modal>
  );
};

const getFullName = (assignee, assigneeType) => {
  if (!assignee || (Array.isArray(assignee) && !assignee.length)) return '';
  if (assigneeType === 'installer-assignee') return assignee.get('name');
  return `${assignee.get('firstName')} ${assignee.get('lastName')}`;
};
