import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { List } from 'immutable';
import {
  ChipStatus,
  StatusDataOnTime,
  STATUS_MAP,
  IconButton,
  UsersTags,
  ShowMoreUsersModal,
} from '@tradetrax/web-common';
import navigate from 'app/navigate';
import { useAppContext } from 'app/AppContext';
import { JobHeaderLoading } from '@tradetrax/web-common/lib/LoadingPlaceholder';
import { JobHeaderDataContainer } from '@tradetrax/web-common/lib/Jobs/index';

const getListUsers = users =>
  users?.size > 0
    ? users.map(user => ({ name: user.get('name'), status: user.get('status'), initials: user.get('initials') }))
    : List();

export const JobHeader = ({ job, users, isLoading, ...props }) => {
  const { modal } = useAppContext();
  const [collapsed, setCollapsed] = useState(true);
  const supers = getListUsers(users.get('subUsersSuper'));
  const schedulers = getListUsers(users.get('subUsersScheduler'));
  const handleShowMoreUsers = () => {
    const title = `Job: ${job.get('name')}`;
    const community = `Community: ${job.get('communityName')}`;
    modal.open(ShowMoreUsersModal, { supers, schedulers, title, community });
  };

  if (isLoading) return <JobHeaderLoading isTrade />;

  return (
    <div className="mx-4">
      <div className="d-flex flex-row align-items-center mr-auto">
        <span className="h4 mb-0 font-weight-bold">{job.get('name')}</span>
        <ChipStatus {...STATUS_MAP[job.get('status')]} className="mx-2" onSelect={props.onSelectStatus} />
        <StatusDataOnTime data={job.get('statusData')} />
      </div>

      <div className="d-flex justify-content-between mt-4">
        <JobHeaderDataContainer className="d-flex flex-wrap d-flex justify-content-start mt-2 " collapsed={collapsed}>
          <div className="d-flex flex-column mr-5">
            <small className="text-muted">LOT #</small>
            <Value value={job.get('lotNumber')} className="w-auto text-truncate" />
          </div>
          <div className="d-flex flex-column">
            <small className="text-muted">Square Ft.</small>
            <Value value={job.get('squareFootage')} />
          </div>
          <div className="d-flex flex-column mr-5">
            <small className="text-muted">Template</small>
            <Value value={job.get('templateName')} />
          </div>
          <div className="d-flex flex-column mr-5">
            <small className="text-muted">Builder</small>
            <span className="text-dark">{job.get('builderName')}</span>
          </div>
          <div className="d-flex flex-column mr-5">
            <small className="text-muted">Community</small>
            <Link
              className="secondary-link"
              to={navigate.to.CommunityDetails({ communityId: job.get('communityId') }, false)}
            >
              {job.get('communityName')}
            </Link>
          </div>
          <div className="d-flex">
            <UsersTags
              className="mr-4 w-9rem"
              maxTags={2}
              title="Super Assigned"
              users={supers.toArray().slice(0, 3)}
              onShowMore={handleShowMoreUsers}
            />
          </div>

          <div className="d-flex">
            <UsersTags
              className="w-9rem"
              maxTags={2}
              title="Scheduler Assigned"
              users={schedulers.toArray().slice(0, 3)}
              onShowMore={handleShowMoreUsers}
            />
          </div>
        </JobHeaderDataContainer>
        <div>
          <IconButton
            icon={`angles-${collapsed ? 'down' : 'up'}`}
            className={cn({ active: !collapsed })}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
      </div>
    </div>
  );
};

const Value = ({ value, className = '' }) => (
  <span className={cn(className, { 'text-dark': !!value, 'text-gray-200': !value })}>{value || 'Not Set'}</span>
);
