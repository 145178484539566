import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import * as actions from './JobPageActions';

export const emptyState = fromJS({ jobs: [], totalCount: 10, maxCount: 10 });

export const JobPageController = () => {
  const [state, controller] = useController(actions, emptyState);

  return { state, controller };
};
