import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mongoToTextYearComparison, calculateTimeLapse } from '@tradetrax/web-common/lib/utils';

export function TaskChangeRequestReview({ task, className = '' }) {
  const isChangeRequestOnReviewByTrade =
    task.getIn(['changeRequest', 'state']) === 'review-by-sub' &&
    task.getIn(['changeRequest', 'activeForCurrentUser']) &&
    !task.get('startDateConfirmationRequest');

  const isChangeRequestOnReviewByBuilder =
    task.getIn(['changeRequestOnReviewByBuilder', 'state']) === 'review-by-builder';

  if (!isChangeRequestOnReviewByTrade && !isChangeRequestOnReviewByBuilder) return null;
  if (task.getIn(['changeRequestOnReviewByBuilder', 'createdByType']) === 'builder') return null;

  const { changeRequestType, changeRequestDate, createdOn } = getChangeRequestData(task);
  return (
    <div className={`d-flex flex-column pt-3 ${className}`} data-testid="div-change-request-review">
      <div className="bg-pearl rounded-6 p-3">
        <div className="d-flex flex-row justify-content-between">
          <strong className="font-size-14">{`Proposed ${changeRequestType}`}</strong>
          <span>{mongoToTextYearComparison(changeRequestDate)}</span>
        </div>
        <span className="text-secondary font-size-14">
          <FontAwesomeIcon icon="clock" className="mr-2" />
          Pending approval on <span className="text-dark">{task.getIn(['job', 'builderName'])}</span>
        </span>
      </div>
      <span className="align-self-end mt-1 font-size-14">{`Sent ${calculateTimeLapse(createdOn)}`}</span>
    </div>
  );
}

function getChangeRequestData(task) {
  const defaultChangeRequest = task.get('changeRequestOnReviewByBuilder') || task.get('changeRequest');
  const changeRequest = task.get('startDateConfirmationRequest')
    ? task.get('changeRequestOnReviewByBuilder')
    : defaultChangeRequest;
  const type = changeRequest.get('type');
  if (type === 'new-start-date-request') {
    return {
      changeRequestDate: changeRequest.get('proposedStartDate'),
      changeRequestType: 'Start',
      createdOn: changeRequest.get('createdOn'),
    };
  }
  //if (type === 'new-end-date-request') {
  return {
    changeRequestDate: changeRequest.get('proposedFinishDate'),
    changeRequestType: 'Finish',
    createdOn: changeRequest.get('createdOn'),
  };
}
