import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';

export const PrecedingTask = ({ ctrTask, task, canEdit, context: { controller } }) => {
  const informOn = task.get('status');
  const isSubTask = task.get('isChildTask');
  const onChange = status => controller.precedingTaskInfoOn(ctrTask, task, status);
  const parentTaskName = task.get('parentTaskName');
  const subTaskLabel = parentTaskName ? `Sub-Task from ${parentTaskName}` : "This Sub-Task doesn't belong to any Task";
  return (
    <DIV>
      <div className={cn('d-flex flex-row align-items-center', { 'mb-3': !isSubTask })}>
        {canEdit && (
          <button
            className="btn btn-link p-0 text-secondary"
            onClick={() => controller.deletePrecedingTask(ctrTask, task)}
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        )}
        <strong className={cn('mr-auto align-self-center w-75 text-truncate', { 'ml-3': canEdit })}>
          {task.get('name')}
        </strong>
        <div className="font-size-14 font-weight-bold">Inform</div>
      </div>
      <div className="ml-4 pl-2">
        {isSubTask && <div className="text-muted font-size-14 w-75 text-truncate">{subTaskLabel}</div>}
        {!isSubTask && (
          <SettingsRow
            status="in-progress"
            checked={informOn === 'in-progress'}
            onChange={onChange}
            canEdit={canEdit}
          />
        )}
        <SettingsRow
          status="completed"
          checked={informOn === 'completed'}
          onChange={onChange}
          canEdit={canEdit}
          isSubTask={isSubTask}
        />
      </div>
    </DIV>
  );
};

const DIV = styled.div`
  background-color: ${colors.activeState};
  margin-bottom: 1px;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 0.5rem;
`;

const SettingsRow = ({ status, checked, onChange, canEdit, isSubTask }) => {
  const label = isSubTask ? 'On Sub-Task' : 'On Task';
  return (
    <div className="d-flex flex-row my-3">
      <span className="mr-auto">
        {status === 'in-progress' && `${label} Start (In Progress)`}
        {status === 'completed' && `${label} Finish (Completed)`}
      </span>
      <Form.Check
        id={`preceding_${status}`}
        checked={checked}
        type="radio"
        label=""
        disabled={!canEdit}
        onChange={({ target }) => canEdit && target.checked && onChange(status)}
      />
    </div>
  );
};
