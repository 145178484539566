import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { EmptyState } from '@tradetrax/web-common';
import { Paths } from 'app/navigate';
import { Tabs } from './Tabs';
import { PlanningWeeks } from './Week';
import { WorkloadPlanningContext } from './WorkloadPlanningContext';
import { LoadingPlaceHolder } from './LoadingPlaceholder';

const SECTIONS = {
  open: 'Open',
  overdue: 'Overdue',
  upcoming: 'Upcoming',
};

export const WorkloadPlanningPage = ({ location }) => {
  const defaultTab = location.hash.startsWith('#') ? location.hash.slice(1) : 'open';
  const context = WorkloadPlanningContext(defaultTab);
  const { state, controller } = context;
  const selectedTab = state.get('selectedTab');
  const section = SECTIONS[selectedTab] || 'Overdue';
  const title = `No ${section} Commitment Requests`;
  const body = `When there's an ${section} Commitment Request, it will appear here.`;
  const isLoading = !!state.getIn([selectedTab, 'isLoading']);
  const isEmpty = !isLoading && state.get(selectedTab).size === 0;
  const counters = state.get('counters');
  const startDate = moment().add('weeks', selectedTab === 'upcoming' ? 3 : 0);

  return (
    <>
      <div className="ml-2 ml-lg-4 pl-1 mb-4 my-4" style={{ maxWidth: '1140px' }}>
        <Breadcrumb tag="nav">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.DashboardV2 }}>
            Dashboard
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Workload Planning</Breadcrumb.Item>
        </Breadcrumb>
        <div className="pb-2 mb-4">
          <span className="h4 font-weight-bold d-block">Workload Planning</span>
        </div>
        <Tabs
          selected={selectedTab}
          onSelectTab={controller.selectTab}
          open={counters.get('open')}
          overdue={counters.get('overdue')}
        />
        {isLoading ? (
          <LoadingPlaceHolder startDate={startDate} />
        ) : (
          <PlanningWeeks context={context} visible={!isEmpty} kind={selectedTab} />
        )}
        <EmptyState pt={5} visible={isEmpty} icon="handshake" title={title} body={body} />
      </div>
    </>
  );
};
