import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS } from 'immutable';
import { subsService } from 'services';
import { markAsSync, markAsSideEffect, CustomDialog } from '@tradetrax/web-common';
import moment from 'moment';

export { resetPassword } from '@tradetrax/web-common/lib/UserProfile/UserProfileCommonActions';

export function updateUser(user, properties) {
  const userId = user.get('_id');

  return subsService.updateUser(properties, { params: { userId } }).then(() => state => {
    const updatedUser = state.get('user').merge(properties);
    this.appController.dispatch([state => state.set('user', updatedUser)]);
    return state.set('user', updatedUser);
  });
}

export function updateSelf(properties) {
  return subsService.updateSelf(properties).then(() => state => {
    const updatedUser = state.get('user').merge(properties);
    return state.set('user', updatedUser);
  });
}

export function readNotificationSettings() {
  return subsService.readNotificationSettings().then(settings => state => state.set('settings', fromJS(settings)));
}

markAsSync(updateNotificationEvents, updateNotificationEventsSettings);
export function updateNotificationEvents(state, event, { onlyChannels, notice_window, noticeTime }) {
  const eventState = state.getIn(['settings', 'events', event]).remove('enabled');
  let payload = eventState;

  if (event === 'date-change') {
    const current = eventState.get('notice_window') || 7;
    payload = payload.set('notice_window', notice_window || current);
  }
  if (event === 'task-missed-check-in' || event === 'update-request-reminder' || event === 'task-missed-check-out') {
    const current = eventState.get('noticeTime') || 14;
    const selectedTime = noticeTime && moment(noticeTime, 'hha').hours();
    payload = payload.set('noticeTime', selectedTime || current);
  }
  if (event === 'check-in-reminder') {
    const current = eventState.get('noticeTime') || 8;
    const selectedTime = noticeTime && moment(noticeTime, 'hha').hours();
    payload = payload.set('noticeTime', selectedTime || current);
  }
  if (onlyChannels) {
    payload = payload.set(
      'onlyChannels',
      onlyChannels.filter(channel => !!channel)
    );
  }

  subsService.updateNotificationEvents({ [event]: payload.toJS() }).catch(err => {
    this.controller.dispatch([state => state.setIn(['settings', 'events', event], eventState)]);
  });

  return state.setIn(['settings', 'events', event], payload);
}

//updateNotificationEventSettings
export function updateNotificationEventsSettings(state, onlyChannels) {
  const eventState = state.getIn(['settings', 'events-settings', 'notificationsChannels']);
  const channels = (onlyChannels || []).filter(channel => !!channel);
  subsService
    .updateNotificationEventsSettings({
      notificationsChannels: channels,
    })
    .catch(err => {
      this.controller.dispatch([
        state => state.setIn(['settings', 'events-settings', 'notificationsChannels'], eventState),
      ]);
    });

  return state.setIn(['settings', 'events-settings', 'notificationsChannels'], fromJS(channels));
}

// Relevant Tasks Actions

markAsSync(readRelevantTasks);
export function readRelevantTasks(state) {
  subsService
    .readRelevantTasks()
    .then(fromJS)
    .then(tasks => this.controller.dispatch([state => state.set('relevantTasks', tasks)]));
  return state.setIn(['relevantTasks', 'isLoading'], true);
}

export function addRelevantTask(task) {
  return subsService
    .addRelevantTask({ taskId: task.get('_id') })
    .then(result => {
      const addedTask = fromJS({
        name: task.get('name'),
        completed: { onlyChannels: [] },
        'in-progress': { onlyChannels: [] },
        'start-date-or-end-date': { onlyChannels: [], notice_window: 7 },
        'start-date': { onlyChannels: [] },
        'end-date': { onlyChannels: [] },
        'commitment-request-accepted': { onlyChannels: [] },
        isChildTask: task.get('isChildTask'),
        parentTaskName: task.get('parentTaskName'),
        _id: task.get('_id'),
      });
      return state => state.update('relevantTasks', tasks => tasks.unshift(addedTask));
    })
    .catch(error => {
      this.alert.error({ message: 'There was a problem adding this Relevant Task. Please try again.' });
      throw error;
    });
}

markAsSideEffect(deleteRelevantTask);
export async function deleteRelevantTask(task) {
  const isMyProfile = !!this.state?.getIn(['user', '_id']);
  const message = isMyProfile
    ? 'By removing it, you will no longer receive notification updates about this Relevant Task. You can add it again later from the Global Task Library.'
    : 'Are you sure you want to remove this Relevant Task? You can add it again later from the Global Library.';
  const { isAccept } = await this.modal.open(CustomDialog, {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger mr-2 mb-1 font-size-18" />
        Remove Relevant Task
      </>
    ),
    message: <span>{message}</span>,
    titleAccept: 'Yes, Remove Relevant Task',
    titleCancel: 'Cancel',
  });

  if (isAccept) return this.controller.doDeleteRelevantTask(task);
}

export function doDeleteRelevantTask(task) {
  return subsService
    .deleteRelevantTask({}, { query: { taskId: task.get('_id') } })
    .then(() => state => {
      const index = state.get('relevantTasks').findIndex(t => t.get('_id') === task.get('_id'));
      this.alert.success({
        message: `The task ‘${task.get('name')}’ has been successfully removed from your relevant tasks.`,
      });
      return state.deleteIn(['relevantTasks', index]);
    })
    .catch(error => {
      this.alert.error({ message: 'There was a problem removing this Relevant Task from the list. Please try again.' });
      throw error;
    });
}

markAsSync(updateRelevantTask);
export function updateRelevantTask(state, task, event, channels, extra) {
  const update = task =>
    task.setIn(
      [event, 'onlyChannels'],
      channels.filter(x => !!x)
    );
  const index = state.get('relevantTasks').indexOf(task);
  const body = update(task);
  const payload = {
    [event]: body.get(event).toJS(),
  };
  const notice_window = extra?.notice_window;
  if (event === 'start-date-or-end-date') payload['start-date-or-end-date'].notice_window = notice_window || 7;

  subsService.updateRelevantTask(payload, { query: { taskId: task.get('_id') } }).catch(err => {
    this.alert.error({ message: 'There was an error updating the relevant task settings' });
    this.controller.dispatch([state => state.setIn(['relevantTasks', index], task)]);
  });

  const newState = notice_window ? state.setIn(['relevantTasks', index, event, 'notice_window'], notice_window) : state;
  return newState.updateIn(['relevantTasks', index], update);
}

markAsSync(updateRelevantTaskNotificationEvents);
export function updateRelevantTaskNotificationEvents(state, task, event, { notice_window }) {
  const index = state.get('relevantTasks').indexOf(task);
  const payload = {
    [event]: { notice_window, onlyChannels: task.getIn([event, 'onlyChannels']) },
  };

  subsService.updateRelevantTask(payload, { query: { taskId: task.get('_id') } }).catch(err => {
    this.alert.error({ message: 'There was an error updating the relevant task' });
    this.controller.dispatch([state => state.setIn(['relevantTasks', index], task)]);
  });

  return state.setIn(['relevantTasks', index, event, 'notice_window'], notice_window);
}

markAsSideEffect(loadGTL);
export function loadGTL() {
  subsService
    .readGTL({}, { query: { isChildTask: 'all' } })
    .then(fromJS)
    .then(tasks => this.controller.dispatch([state => state.set('globalTasks', tasks)]));
}

markAsSync(readLeadTimeTasks);
export function readLeadTimeTasks(state) {
  subsService
    .readLeadTimeTasks()
    .then(fromJS)
    .then(tasks => (tasks.size ? this.controller.dispatch([state => state.set('leadTimeTasks', tasks)]) : null));
  return state.setIn(['leadTimeTasks', 'isLoading'], true);
}

export function createLeadTimeTask({ taskId: gtlTaskId, taskName }) {
  return subsService
    .createLeadTimeTask({ gtlTaskId })
    .then(result => {
      const task = fromJS({
        name: taskName,
        _id: result._id,
        gtlTaskId: gtlTaskId,
      });
      return state => state.update('leadTimeTasks', tasks => tasks.unshift(task));
    })
    .catch(error => {
      this.alert.error({ message: 'There was a problem adding this Task. Please try again.' });
      throw error;
    });
}

export function updateLeadTimeTask(task, channels, leadTimeDays) {
  const update = task =>
    task
      .setIn(
        ['onlyChannels'],
        channels.filter(x => !!x)
      )
      .setIn(['leadTimeDays'], leadTimeDays);
  const leadTimeTaskId = task.get('_id');
  const body = update(task);

  return subsService
    .updateLeadTimeTask(
      {
        leadTimeDays: body.get('leadTimeDays'),
        onlyChannels: body.get('onlyChannels'),
      },
      { params: { leadTimeTaskId } }
    )
    .then(() => state => {
      const index = state.get('leadTimeTasks').findIndex(t => t.get('_id') === task.get('_id'));
      return state.updateIn(['leadTimeTasks', index], update);
    })
    .catch(err => {
      this.alert.error({ message: 'There was an error updating the lead time task settings' });
      throw err;
    });
}

markAsSideEffect(deleteLeadTimeTask);
export async function deleteLeadTimeTask(task) {
  const isMyProfile = !!this.state?.getIn(['user', '_id']);
  const msg = isMyProfile
    ? 'By removing it, you will no longer receive notifications days in advance before this Task starts. You can add it again later from the Global Task Library.'
    : 'Are you sure you want to remove the Lead Time for this Task? You can add it again later from the Global Library.';
  const { isAccept } = await this.modal.open(CustomDialog, {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger mr-2 mb-1 font-size-18" />
        Remove Lead Time
      </>
    ),
    message: <span>{msg}</span>,
    titleAccept: 'Yes, Remove Lead Time',
    titleCancel: 'Cancel',
  });

  if (isAccept) return this.controller.doDeleteLeadTimeTask(task);
}

export function doDeleteLeadTimeTask(task) {
  const taskId = task.get('_id');

  return subsService
    .deleteLeadTimeTask({}, { params: { leadTimeTaskId: taskId } })
    .then(() => state => {
      const index = state.get('leadTimeTasks').findIndex(t => t.get('_id') === task.get('_id'));
      this.alert.success({
        message: 'Task successfully removed from the Lead Time notifications list.',
      });
      return state.deleteIn(['leadTimeTasks', index]);
    })
    .catch(error => {
      this.alert.error({ message: 'There was a problem removing this Task from the list. Please try again.' });
      throw error;
    });
}

markAsSync(toggleSMSPermission);
export function toggleSMSPermission(state) {
  const current = state.getIn(['user', 'settings', 'allowSMS']);
  const settings = { allowSMS: !current };

  subsService.updateSelf({ settings }).catch(error => {
    this.alert.error({ message: 'There was a problem updating this preference. Please try again.' });
    this.controller.dispatch([state => state.setIn(['user', 'settings', 'allowSMS'], current)]);
  });

  return state.setIn(['user', 'settings', 'allowSMS'], !current);
}
