import React from 'react';
import cn from 'classnames';
import { Initials } from '@tradetrax/web-common';

export const NullMuted = ({ value, className = '' }) => (
  <span className={cn(className, { 'text-muted': !value })}>{value || 'Not Set'}</span>
);

export const MiniProfile = ({ name, initials, email, size = 80 }) => (
  <div className="d-flex flex-row ml-4">
    <Initials name={initials || name} size={size} />
    <div className="d-flex flex-column ml-4 justify-content-center">
      <h4 className="mb-0 font-weight-bold">{name}</h4>
      <NullMuted value={email} />
    </div>
  </div>
);
