import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from '@tradetrax/web-common';
import { DashboardV2 } from './Dashboard';
import { RequestAndOverdueDetails } from './Requests/RequestAndOverdueDetails';
import { WorkloadPlanningPage } from '../WorkloadPlanning/WorkloadPlanningPage';
import { Paths } from 'app/navigate';

export function DashboardRoutes() {
  return (
    <Switch>
      <Route path={Paths.WorkloadPlanning} component={WorkloadPlanningPage}></Route>
      <Route exact path={Paths.DashboardV2} component={DashboardV2} />
      <Route path={Paths.RequestAndOverdueDetails} component={RequestAndOverdueDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}
