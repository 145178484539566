import React from 'react';
import moment from 'moment';

export const LoadingPlaceHolder = ({ startDate = moment() }) => {
  const segments = [0, 1, 2].map(week => {
    const from = moment(startDate)
      .add(week, 'weeks')
      .startOf('week');
    const to = moment(startDate)
      .add(week, 'weeks')
      .endOf('week');
    const isSameMonth = from.month() === to.month();
    const weekLabel = `${from.format('MMMM[ ]D[ - ]')} ${to.format(isSameMonth ? 'D' : 'MMMM D')}`;
    return weekLabel;
  });

  return (
    <>
      {segments.map(weekLabel => (
        <div key={weekLabel}>
          <h4 className="font-weight-bold mt-4">Week: {weekLabel}</h4>
          <PlaceHolder />
        </div>
      ))}
    </>
  );
};

const PlaceHolder = () => (
  <svg width="997" height="197" viewBox="0 0 997 197" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="997" height="197" rx="8" fill="#F9F9FA" />
    <rect x="69" y="72" width="87" height="12" rx="5" fill="#E0E2E7" />
    <rect x="29" y="27" width="177" height="12" rx="5" fill="#E0E2E7" />
    <rect x="873" y="23" width="82" height="12" rx="5" fill="#E0E2E7" />
    <rect x="791" y="23" width="62" height="12" rx="5" fill="#E0E2E7" />
    <rect x="896" y="72" width="59" height="12" rx="5" fill="#E0E2E7" />
    <rect x="813" y="72" width="40" height="12" rx="5" fill="#E0E2E7" />
    <rect x="896" y="115" width="59" height="12" rx="5" fill="#E0E2E7" />
    <rect x="813" y="115" width="40" height="12" rx="5" fill="#E0E2E7" />
    <rect x="896" y="158" width="59" height="12" rx="5" fill="#E0E2E7" />
    <rect x="813" y="158" width="40" height="12" rx="5" fill="#E0E2E7" />
    <rect x="69" y="158" width="87" height="12" rx="5" fill="#E0E2E7" />
    <rect x="36" y="72" width="17" height="12" rx="5" fill="#E0E2E7" />
    <rect x="69" y="115" width="95" height="12" rx="5" fill="#E0E2E7" />
    <rect x="36" y="158" width="17" height="12" rx="5" fill="#E0E2E7" />
    <rect x="36" y="115" width="17" height="12" rx="5" fill="#E0E2E7" />
    <path d="M963 53L29 53" stroke="#E0E2E7" />
  </svg>
);
