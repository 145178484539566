import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { fromJS } from 'immutable';
import cn from 'classnames';
import { EMPTY_ROW, renderCell, StatusDataOnTime, EmptyState } from '@tradetrax/web-common';
import { ChipStatus, STATUS_MAP } from '@tradetrax/web-common/lib/ChipStatus';
import navigate from 'app/navigate';
import { plural, handleTableScrollStickyColumn2 } from '@tradetrax/web-common/lib/utils';

const COL_WIDTHS = {
  span: 16 * 2,
  lotNumber: 16 * 10,
  tasks: 16 * 10,
  progress: 16 * 14,
  status: 160,
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const CommunityJobs = ({ jobs, loadMoreRows, totalCount }) => {
  const scrollDivRef = useRef(null);

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);
  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!jobs.get(index)}
        loadMoreRows={loadMoreRows}
        rowCount={totalCount}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div ref={scrollDivRef} style={{ flex: '1 1 auto', overflowX: 'auto', overflowY: 'hidden' }}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1200 ? width : 1200;
                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyColumn2"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    overscanRowCount={2}
                    rowHeight={80}
                    rowCount={totalCount}
                    rowClassName={({ index }) => (jobs.get(index) ? '' : 'loading')}
                    rowGetter={({ index }) => jobs.get(index) || EMPTY_ROW}
                    noRowsRenderer={() => (
                      <EmptyState
                        icon="house"
                        title="No Jobs Assigned"
                        body="When a Job from this Community is assigned to your account it will appear here."
                      />
                    )}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column label="" dataKey="" width={COL_WIDTHS.span} />
                    <Column
                      label="Job Name"
                      dataKey="name"
                      minWidth={250}
                      width={minWidth - WIDTHS - COL_WIDTHS.span}
                      cellRenderer={renderCell(({ cellData, rowIndex }) => (
                        <Link
                          className="main-link"
                          to={{
                            pathname: navigate.to.JobDetails({ jobId: jobs.getIn([rowIndex, '_id']) }, false),
                            state: {
                              referrer: 'schedule',
                            },
                          }}
                        >
                          <div className="text-truncate">{cellData}</div>
                        </Link>
                      ))}
                    />
                    <Column
                      label="LOT #"
                      dataKey="lotNumber"
                      width={COL_WIDTHS.lotNumber}
                      className="overflow-hidden"
                      cellRenderer={renderCell(({ cellData }) => (
                        <span className={cn('d-block text-truncate', { 'text-gray-200': cellData === null })}>
                          {cellData === null ? 'Not Set' : cellData}
                        </span>
                      ))}
                    />
                    <Column
                      label="Tasks Assigned"
                      dataKey="assignedTasks"
                      width={COL_WIDTHS.tasks}
                      cellRenderer={renderCell(({ cellData }) => (
                        <span>{`${cellData} ${plural(cellData, 'task', 'tasks')} `}</span>
                      ))}
                    />
                    <Column
                      label="Progress"
                      dataKey="progress"
                      width={COL_WIDTHS.progress}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const daysDifference = fromJS({
                          daysDifference: rowData.get('daysDifference'),
                          targetStatus: rowData.get('targetStatus'),
                        });
                        return (
                          <>
                            <StatusDataOnTime data={daysDifference} />
                            <div className="text-muted">{cellData}% progress</div>
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ cellData }) => (
                        <ChipStatus {...STATUS_MAP[cellData]} className="mx-2" />
                      ))}
                    />
                    <Column label="" dataKey="" width={COL_WIDTHS.span} />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};
