import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Modal, Form, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { PrimaryButton } from '@tradetrax/web-common/lib/Button';
import Datepicker from '@tradetrax/web-common/lib/Datepicker';
import {
  datepickerFormatIncremented,
  datepickerFormat,
  mongoToTrx,
  getMinEndDate,
  plural,
  TRX_FULL,
  mongoToMoment,
} from '@tradetrax/web-common/lib/utils';
import WarningCriticalMsg, {
  CriticalDay,
  criticalProposedDateClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';
import { StepPermission, ForbiddenSection } from './ForbiddenTasks';

function getMaxDate(tasks, field) {
  let startDate = moment();
  tasks
    .map(task => task.get(field))
    .map(mongoToMoment)
    .toArray()
    .forEach(day => {
      if (!startDate || day.isAfter(startDate)) startDate = day;
    });

  return datepickerFormatIncremented(mongoToTrx(startDate.format(TRX_FULL)), 1);
}

export const TaskUpdateRequestModal = ({
  tasks,
  forbiddenTasks,
  blockedTasks,
  close,
  accept,
  canProposeFinish = false,
}) => {
  const task = tasks.first();
  const startDate = getMaxDate(tasks, 'startDate');
  const finishDate = getMaxDate(tasks, 'expectedFinishDate');
  // all upcoming tasks are not started by default.
  const isTaskNotStarted = true;
  const [isStartDateUpdateRequest, setUpdateRequestKind] = React.useState(canProposeFinish ? isTaskNotStarted : true);
  const updateRequestKind = isStartDateUpdateRequest ? 'finish' : 'start';
  const defaultDate = isStartDateUpdateRequest ? startDate : finishDate;
  const [date, setDate] = React.useState(defaultDate);
  const title = canProposeFinish ? 'Update Request' : 'Start Date Confirmation Update Request';
  const [step, setStep] = React.useState(forbiddenTasks.size + blockedTasks.size > 0 ? 'permission' : 'form');
  const { handleSubmit, control } = useForm({ defaultDate });
  const [isChecked, setIsChecked] = React.useState(false);

  const toggleUpdateRequestKind = e => {
    e.preventDefault();
    const date = isStartDateUpdateRequest ? finishDate : startDate;
    setDate(date);
    setUpdateRequestKind(!isStartDateUpdateRequest);
  };

  const StepForm = () => (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
          <h6 className="font-weight-bold">{`${tasks.size} ${plural(tasks.size, 'Task')}`}</h6>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(form => accept({ form, isDueWeather: isChecked }))}>
        <Modal.Body className={cn('mt-n3', { 'pb-3': !canProposeFinish })}>
          {isStartDateUpdateRequest && (
            <FormDates
              control={control}
              date={date}
              name="startDate"
              label="Propose Start"
              task={task}
              isStartDate={isStartDateUpdateRequest}
            />
          )}
          {!isStartDateUpdateRequest && (
            <FormDates
              control={control}
              date={date}
              name="finishDate"
              label="Propose Finish"
              task={task}
              minDate={getMinEndDate(task.get('startDate'))}
              isStartDate={isStartDateUpdateRequest}
            />
          )}
          <div className="d-flex align-items-center mt-2">
            <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
            <span className="ml-2">Due to Weather</span>
          </div>
          {canProposeFinish && (
            <button
              className="btn btn-link align-self-start p-0 mt-3"
              onClick={toggleUpdateRequestKind}
            >{`Propose ${updateRequestKind} instead?`}</button>
          )}
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <PrimaryButton variant="secondary" className="mr-1" onClick={() => close()}>
            Cancel
          </PrimaryButton>
          <PrimaryButton type="submit" className="ml-3">
            Send to Builder
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </>
  );

  const Content = () => {
    if (step === 'permission') {
      if (tasks.size === 0) {
        return (
          <StepPermission ok={close} variant="circle-exclamation">
            <ForbiddenSection
              label="You don’t have permission to send an Update Request for any of these Tasks. Make sure to select at least one Task with permission."
              tasks={forbiddenTasks}
            />
          </StepPermission>
        );
      }

      return (
        <StepPermission close={close} next={() => setStep('form')} variant="warning">
          <ForbiddenSection
            label="You don’t have permission to send an Update Request for the following Tasks and will be omitted:"
            tasks={forbiddenTasks}
          />
          <ForbiddenSection
            label="The following Tasks have a pending Start Date Confirmation Request and are not eligible to send a proposed finish; the option will be disabled for all Tasks selected:"
            tasks={blockedTasks}
          />
        </StepPermission>
      );
    }
    return <StepForm />;
  };

  return (
    <Modal show={true} onHide={close} id="task-start-request" dialogClassName="width-600">
      <Content />
    </Modal>
  );
};

const FormDates = ({ control, date, name, label, minDate = null, task, isStartDate }) => {
  let taskDate, lastTaskDate;
  if (isStartDate) {
    taskDate = task.get('startDate') ? datepickerFormat(mongoToTrx(task.get('startDate'))) : '';
    lastTaskDate = task.get('lateStartDate') || undefined;
  } else {
    taskDate = task.get('expectedFinishDate') ? datepickerFormat(mongoToTrx(task.get('expectedFinishDate'))) : '';
    lastTaskDate = task.get('lateEndDate') || undefined;
  }
  const criticalDate = new Date(lastTaskDate);

  return (
    <Form.Group as={Col} md="6" controlId="formDate" className="d-flex flex-column px-0">
      <Form.Label>{label}</Form.Label>
      <Controller
        control={control}
        name={name}
        defaultValue={date}
        render={({ onChange, value, name }) => (
          <Datepicker
            name={name}
            selected={value}
            onChange={onChange}
            placeholderText=""
            minDate={minDate}
            dayClassName={date => criticalProposedDateClass(date, value, taskDate, criticalDate)}
            renderDayContents={(day, date) => CriticalDay(day, date, criticalDate, value)}
            calendarClassName="react-datepicker__critical"
          >
            <WarningCriticalMsg showMsg={showWarningCriticalMsg(value, criticalDate)} />
          </Datepicker>
        )}
      />
    </Form.Group>
  );
};
