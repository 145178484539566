import React from 'react';
import styled from 'styled-components';
import { EmptyState, colors } from '@tradetrax/web-common';
import { LeadTimeTask } from './LeadTimeTask';

const getTaskId = task => task.get('gtlTaskId') || task.get('_id');

export const Tasks = ({ controller, state, expanded, expand }) => {
  const tasks = state.get('leadTimeTasks');
  const toggle = task => {
    const taskId = getTaskId(task);
    expand(taskId === expanded ? null : taskId);
  };

  if (!tasks.size) {
    return (
      <EmptyState
        icon="wrench"
        title="No Lead Time Added"
        body="When you add Lead Time to a Task, it will appear here."
      />
    );
  }

  return (
    <UL data-testid="list-lead-time-tasks">
      {tasks
        .map(task => (
          <LeadTimeTask
            expanded={getTaskId(task) === expanded}
            toggle={toggle}
            task={task}
            key={getTaskId(task)}
            controller={controller}
          />
        ))
        .toArray()}
    </UL>
  );
};

const UL = styled.ul`
  margin-top: 0.25rem;
  list-style: none;
  padding: 0;

  li {
    border-top: solid 1px ${colors.gray100};
    padding: 1rem 1rem 1.5rem 1rem;

    div {
      display: flex;
      flex-direction: row;
    }

    &.expanded {
      background-color: ${colors.activeState};
      flex-direction: column;
      margin-bottom: 1px;
      border-radius: 6px;
    }
  }
`;
