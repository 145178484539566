import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TasksOfInterestItem = ({ context, taskOfInterest, canEdit }) => {
  const { controller } = context;

  return (
    <li className="item">
      <div className="d-flex flex-row">
        {canEdit && (
          <button
            className="btn btn-link p-0 text-secondary"
            onClick={() => controller.deleteTaskOfInterest(taskOfInterest)}
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        )}
        <span
          className={cn('mr-auto align-self-center font-weight-bold text-dark text-decoration-none', {
            'ml-3': canEdit,
          })}
        >
          {taskOfInterest.get('name')}
        </span>
      </div>
    </li>
  );
};
