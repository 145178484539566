import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'app/AppContext';
import { PrimaryButton } from '@tradetrax/web-common';
import {
  mongoToTrx,
  COMPLETED,
  isTaskOverdue,
  plural,
  hasPermissionForAssignedTask,
} from '@tradetrax/web-common/lib/utils';
import { TaskChangeRequestReview } from './TaskChangeRequestReview';
import { TaskChangeAssignee } from './TaskChangeAssignee';
import { StartDateConfirmation } from './StartDateConfirmation';

export function TaskDefaultContent({ controller, task, isSelfAssigned }) {
  const appContext = useAppContext();
  const { hasPermission, appState } = appContext;
  const userId = appState.getIn(['user', '_id']);
  const hasPermissionToUpdateRequest = hasPermissionForAssignedTask(
    'manage_update_request',
    hasPermission,
    task,
    userId
  );

  const startDate = task.get('startDate') ? mongoToTrx(task.get('startDate')) : 'MM/DD/YY';
  const endDate = task.get('expectedFinishDate') ? mongoToTrx(task.get('expectedFinishDate')) : 'MM/DD/YY';
  const isTaskNotCompleted = task.get('status') !== COMPLETED;
  const { isOverdueStart, isOverdueFinish } = isTaskOverdue(task);
  const canSendUpdateRequest = isTaskNotCompleted && isSelfAssigned && hasPermissionToUpdateRequest;

  return (
    <>
      <div className="d-flex flex-row mt-4 pt-3">
        <div className="d-flex flex-column mr-5">
          <small className="text-muted">Expected Start</small>
          <span data-testid="span-task-expected-start">{startDate}</span>
          {isOverdueStart && (
            <div className="text-danger">
              <FontAwesomeIcon icon="circle-exclamation" className="mr-1" />
              Missed Start
            </div>
          )}
        </div>
        <div className="d-flex flex-column mr-5">
          <small className="text-muted">Expected Finish</small>
          <span>{endDate}</span>
          {isOverdueFinish && (
            <div className="text-danger">
              <FontAwesomeIcon icon="circle-exclamation" className="mr-1" />
              Missed Finish
            </div>
          )}
        </div>
        <div className="d-flex d-column">
          {canSendUpdateRequest && (
            <PrimaryButton
              data-testid="btn-send-update-request"
              className="text-nowrap"
              onClick={() => controller.sendDateUpdateRequest(task)}
            >
              Send Update Request
            </PrimaryButton>
          )}
        </div>
      </div>
      {!isSelfAssigned && (
        <div style={{ width: '32rem' }}>
          <TaskChangeRequestReview task={task} />
        </div>
      )}
      <div className="d-flex flex-column mr-5 pt-3">
        <small className="text-muted">Duration</small>
        <span className="text-dark">{plural.day(parseInt(task.get('durationDays')))}</span>
      </div>
      {isSelfAssigned && (
        <div className="d-flex flex-column mt-3 mb-4 pb-3" style={{ width: '32rem' }}>
          <TaskChangeAssignee controller={controller} task={task} />
          <TaskChangeRequestReview task={task} className="mb-3 mt-4" />
        </div>
      )}
      {!isSelfAssigned && (
        <div className="mb-4 mt-4">
          <small className="text-muted">Account Assignee</small>
          <div>{task.getIn(['assigneeAccount', 'company']) || 'Not Assigned'}</div>
        </div>
      )}
      <StartDateConfirmation
        controller={controller}
        task={task}
        isSelfAssigned={isSelfAssigned}
        canSendUpdateRequest={canSendUpdateRequest}
      />
    </>
  );
}
