import { fromJS } from 'immutable';
import { subsService } from 'services';
import { useController } from '@tradetrax/web-common';

const emptyState = fromJS({ communities: [], totalCount: 5 });
const actions = { loadMoreRows };

export const CommunityPageController = () => {
  const [state, controller] = useController(actions, emptyState);

  return { state, controller };
};

function loadMoreRows({ startIndex, stopIndex }) {
  return subsService.readCommunities({}, { query: { start_index: startIndex, stop_index: stopIndex } }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;

    return state =>
      state
        .set('totalCount', totalCount)
        .update('communities', communities =>
          communities.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray())
        );
  });
}
