import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useAppContext } from 'app/AppContext';
import { Typeahead } from '@tradetrax/web-common';
import { colors } from '@tradetrax/web-common/lib/theme';
import { hasPermissionForAssignedTask, isUserInactive } from '@tradetrax/web-common/lib/utils';

export function TaskChangeAssignee({ controller, task }) {
  const { account, hasPermission, appState } = useAppContext();
  const accountUsers = account.get('assignees').toArray();
  const form = React.useMemo(() => {
    const users = account.get('users').toArray();
    const assignees = account.get('assigneesActive').toArray();

    const currentAssigneeId = task.getIn(['assigneeAccount', 'installerId']);
    const currentAssignee = accountUsers.filter(assignee => assignee.get('userId') === currentAssigneeId);

    const currentSuperId = task.getIn(['userSuper', '_id']);
    const currentSuper = users.filter(user => user.get('_id') === currentSuperId);

    const currentSchedulerId = task.getIn(['userScheduler', '_id']);
    const currentScheduler = users.filter(user => user.get('_id') === currentSchedulerId);

    return {
      users: account.get('regularUsersActive').toArray(),
      assignees,
      currentAssignee,
      currentSuper,
      currentScheduler,
      currentAssigneeId,
      currentSuperId,
      currentSchedulerId,
    };
  }, [task, account, accountUsers]);

  const userId = appState.getIn(['user', '_id']);
  const onChangeAssignee = ([assignee]) => controller.assignTask(assignee);
  const onChangeSuper = ([user]) => controller.updateTaskSuper({ user });
  const onChangeScheduler = ([user]) => controller.updateTaskScheduler({ user });

  const canEditSuperAndScheduler = hasPermission('task_user_assignment');
  const canEditInstaller = hasPermissionForAssignedTask('task_update_installer', hasPermission, task, userId);

  const isInactive = isUserInactive(form.currentAssigneeId, accountUsers);
  const isSuperInactive = isUserInactive(form.currentSuperId, accountUsers);
  const isSchedulerInactive = isUserInactive(form.currentSchedulerId, accountUsers);

  return (
    <>
      <Form.Row className="mt-4">
        <Form.Group as={Col} md="12">
          <TrxTypeahead
            id="installer-assignee"
            label="Lead Installer Assignee"
            placeholder="Choose Installer"
            form={form}
            assignee={form.currentAssignee}
            options={form.assignees}
            onChange={onChangeAssignee}
            canEdit={canEditInstaller}
            isInactive={isInactive}
            loggedUserId={userId}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12">
          <TrxTypeahead
            id="super-assignee"
            label="Trade Super"
            placeholder="Choose Super"
            form={form}
            assignee={form.currentSuper}
            onChange={onChangeSuper}
            canEdit={canEditSuperAndScheduler}
            isInactive={isSuperInactive}
            loggedUserId={userId}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12" className="mb-0">
          <TrxTypeahead
            id="scheduler-assignee"
            label="Trade Scheduler"
            placeholder="Choose Scheduler"
            form={form}
            assignee={form.currentScheduler}
            onChange={onChangeScheduler}
            canEdit={canEditSuperAndScheduler}
            isInactive={isSchedulerInactive}
            loggedUserId={userId}
          />
        </Form.Group>
      </Form.Row>
    </>
  );
}

const getFullName = (assignee, assigneeType) => {
  if (!assignee || (Array.isArray(assignee) && !assignee.length)) return '';
  if (assigneeType === 'installer-assignee') return assignee.get('name');
  return `${assignee.get('firstName')} ${assignee.get('lastName')}`;
};

const TrxTypeahead = ({
  canEdit,
  id,
  label,
  placeholder,
  form,
  onChange,
  assignee,
  options = form.users,
  loggedUserId,
}) => {
  if (!canEdit)
    return (
      <>
        <small className="text-muted">{label}</small>
        <br />
        <span style={{ color: assignee.length ? '' : colors.gray200 }}>
          {getFullName(assignee[0], id) || 'Not Assigned'}
        </span>
      </>
    );
  return (
    <>
      <small className="text-muted">{label}</small>
      <Typeahead
        id={id}
        placeholder={placeholder}
        options={options}
        labelKey={option => getFullName(option, id)}
        filterSelected={(option, selected) => selected.get('_id') !== option.get('_id')}
        selected={assignee}
        onChange={onChange}
        isMyId={option => loggedUserId === option.get('_id')}
      />
    </>
  );
};
