import React, { useState } from 'react';
import cn from 'classnames';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CompanyInfo = ({ context: { state, controller } }) => {
  const { trades, gtlTrades, companyInfo } = state.toObject();
  const [hasInteracted, setHasInteracted] = useState(false);

  const handleTradeChange = selected => {
    controller.updateTrades(selected);
    setHasInteracted(true);
  };

  return (
    <div className="mx-4 mb-2" style={{ maxWidth: '716px' }}>
      <div className="w-75">
        <h5 className="mb-3 font-weight-bold">Trade Profile</h5>
        <div>
          <div className="text-muted font-size-14">Company Name</div>
          <span>{companyInfo.get('name')}</span>
        </div>
        <Form.Row className="mt-4 pt-2">
          <Form.Group as={Col}>
            <Form.Label>Trade Type</Form.Label>
            <Typeahead
              id="company-trade"
              name="trades"
              placeholder="Select one or several trades"
              multiple
              options={gtlTrades.toArray()}
              selected={trades}
              onChange={handleTradeChange}
              onBlur={() => setHasInteracted(true)}
              isInvalid={hasInteracted && !trades.length}
            >
              <FontAwesomeIcon icon="magnifying-glass" className="mx-1" />
            </Typeahead>
            <Form.Control.Feedback className={cn({ 'd-block': hasInteracted && !trades.length })} type="invalid">
              You must select at least one Trade
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <h5 className="mb-3 mt-4 pt-2 font-weight-bold">Account Manager</h5>
        <div className="mb-4">
          <div className="text-muted font-size-14">Name</div>
          <span>{companyInfo.getIn(['manager', 'name'])}</span>
        </div>
        <div className="mb-4">
          <div className="text-muted font-size-14">Email</div>
          <span>{companyInfo.getIn(['manager', 'email'])}</span>
        </div>

        <h5 className="mb-3 mt-5 pt-2 font-weight-bold">Task Management</h5>
        <div className="mb-1 d-flex flex-row">
          <span className="mr-5 pr-5">Enable Check-In/Out for Non-Installer users</span>
          <Form.Check
            id="check-in-out"
            className="d-inline ml-auto"
            type="switch"
            label=""
            checked={companyInfo.get('checkInCheckOutForNonInstallerEnabled')}
            onChange={({ target }) => {
              controller.setEnableCheckInOutForTrades(target.checked);
            }}
          />
        </div>
        <div className="text-muted font-size-14 w-75">
          Only applicable when a Trade user is assigned to a Task as a Lead Installer
        </div>
      </div>
    </div>
  );
};
