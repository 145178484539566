import { debounce } from 'lodash';
import { fromJS } from 'immutable';
import { markAsSideEffect } from '@tradetrax/web-common/lib/useController';

const RT_JOB_ACTIONS = {
  CREATE_TASK: 'craete-task',
  UPDATE_TASK: 'update-task',
  DELETE_TASK: 'delete-task',
  UPDATE_HISTORY: 'update-history',
  UPDATE_FEED: 'update-feed',
};

// this method will wait 5 secconds to read the entire job from BE
// if it's called many times withing that time frame it wil wait until the last call
// to perform the request to the BE API.
const FIVE_SECONDS = 5 * 1000;
const refreshJobAfterRealTime = debounce((controller, jobId) => {
  controller.readJob(jobId);
}, FIVE_SECONDS);

markAsSideEffect(setTaskDetails);
export function setTaskDetails({ action, task, jobId }) {
  if (action !== RT_JOB_ACTIONS.UPDATE_HISTORY) {
    refreshJobAfterRealTime(this.controller, jobId);
  }

  if (action !== RT_JOB_ACTIONS.UPDATE_TASK || !task) return;

  const {
    id,
    status,
    startDate,
    endDate,
    duration,
    installerId,
    installerName,
    installerStatus,
    assigneeAccountType,
    assigneeAccountName,
    assigneeAccountId,
    overdue,
  } = task;

  const assignedData = fromJS({
    company: assigneeAccountName,
    companyId: assigneeAccountId,
    companyName: assigneeAccountName,
    companyType: assigneeAccountType,
    installerId,
    installerName,
    installerStatus,
  });

  this.controller.dispatch([
    state => {
      const taskIndex = this.state.get('tasks').findIndex(task => task.get('id') === id);
      if (taskIndex === -1) return state;

      return state.updateIn(['tasks', taskIndex], task =>
        task.merge({
          status,
          startDate,
          expectedFinishDate: endDate,
          durationDays: duration,
          assigneeAccount: assignedData,
          overdue,
        })
      );
    },
  ]);
}
