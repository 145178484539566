import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { SearchGTL } from '@tradetrax/web-common/lib/Inputs/SearchGTL';
import { TasksOfInterestContext } from './TasksOfInterest.context';
import { TasksOfInterestList } from './TasksOfInterest.list';

export const TasksOfInterestPage = () => {
  const toiContext = TasksOfInterestContext();
  const { state, controller, canEdit } = toiContext;
  const isLoading = state.get('isLoading');
  const tasksOfInterest = state.get('tasksOfInterest');
  const addTask = task => {
    controller.addTaskOfInterest(task);
  };

  return (
    <div className="ml-0 pl-4 mt-4">
      <p className="small">
        {canEdit
          ? 'Select the Tasks that you and your teams want visibility into that will help you better manage your resources.'
          : 'Only the Account Manager can set up the Task of interest'}
      </p>
      <Row className="mt-2 mr-0">
        <Col sm={12} md={10} lg={8} style={{ maxWidth: '575px' }}>
          {canEdit && <SearchGTL selectedTasks={tasksOfInterest} addTask={addTask} state={state} />}
          <TasksOfInterestList context={toiContext} canEdit={canEdit} />
        </Col>
      </Row>
      <EmptyStateCenterContainer top="0px">
        <EmptyState
          visible={!isLoading && tasksOfInterest.size === 0}
          icon="wrench"
          title="No Tasks of Interest Added"
          body={
            <>
              When a Task of Interest is added,
              <br />
              it will appear here.
            </>
          }
        />
      </EmptyStateCenterContainer>
    </div>
  );
};
