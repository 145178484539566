import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common/src/theme';
import { plural } from '@tradetrax/web-common/lib/utils';

const DaysInput = styled(Form.Control)`
  width: 80px;
  margin-right: 11px;
  margin-top: -8px;
  &:hover {
    border-color: ${colors.gray200};
  }
`;

export const LeadTimeTask = ({ task, expanded, toggle, controller }) => {
  return (
    <li className={expanded ? 'expanded' : ''}>
      <div>
        <button className="btn btn-link p-0 text-secondary" onClick={() => controller.deleteLeadTimeTask(task)}>
          <FontAwesomeIcon icon="trash" />
        </button>
        <strong className="ml-3 mr-auto align-self-center">{task.get('name')}</strong>
        <button className="btn btn-link p-0 text-secondary" onClick={() => toggle(task)}>
          <FontAwesomeIcon icon={`chevron-${expanded ? 'up' : 'down'}`} className="mt-2" />
        </button>
      </div>
      {expanded && <LeadTimeTaskDetails task={task} controller={controller} />}
    </li>
  );
};

const LeadTimeTaskDetails = ({ task, controller }) => {
  const channels = task.getIn(['onlyChannels']);
  const taskLeadTimeDays = task.getIn(['leadTimeDays']);
  const leadTimeDays = taskLeadTimeDays > 0 ? taskLeadTimeDays : 1;
  const pushChecked = !!(channels && channels.indexOf('push') >= 0);
  const emailChecked = !!(channels && channels.indexOf('email') >= 0);

  const update = (channels, leadTimeDays) =>
    leadTimeDays && controller.updateLeadTimeTask(task, channels, leadTimeDays);

  return (
    <>
      <div className="font-size-14 font-weight-bold my-3">
        <span className="mr-auto">Receive Lead Time Notifications</span>
        <span className="ml-4">Push</span>
        <span className="ml-4">Email</span>
      </div>
      <div className="my-3">
        <DaysInput
          size="lg"
          name="days"
          type="number"
          defaultValue={leadTimeDays}
          min="1"
          max="99"
          title=""
          onChange={({ target }) => {
            if (target.value.length > 2) target.value = target.value.substring(0, 2);
            else update([pushChecked ? 'push' : null, emailChecked ? 'email' : null], target.valueAsNumber);
          }}
        />
        <p className="mr-auto">{`${plural(leadTimeDays, 'Day')} before this Task starts`}</p>
        <Form.Check
          id={`leadtime_push`}
          className="mr-2"
          checked={pushChecked}
          type="switch"
          label=""
          onChange={({ target }) =>
            update([target.checked ? 'push' : null, emailChecked ? 'email' : null], leadTimeDays)
          }
        />
        <Form.Check
          className="ml-3"
          id={`leadtime_email`}
          checked={emailChecked}
          type="switch"
          label=""
          onChange={({ target }) =>
            update([target.checked ? 'email' : null, pushChecked ? 'push' : null], leadTimeDays)
          }
        />
      </div>
    </>
  );
};
