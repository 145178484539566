import { fromJS, Repeat } from 'immutable';
import { subsService } from 'services';
import { markAsSync, EMPTY_ROW, markAsSideEffect } from '@tradetrax/web-common';
import { setCheckedInStatus } from '@tradetrax/web-common/lib/utils';
import { emptyState } from './TaskContext';
import { CALENDAR_VIEW, LIST_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { COMPLETED } from '@tradetrax/web-common/lib/utils';

const MINIMUM_BATCH_SIZE = 25;

markAsSync(loadMoreRows);
export function loadMoreRows(state, { status, startIndex, stopIndex }) {
  const isOpenTasks = status === 'open';
  const query = { open: String(isOpenTasks), start_index: startIndex, stop_index: stopIndex };
  if (isOpenTasks) {
    const filter = this.filterState.get('values').toJS();
    if (filter.myTasks) query.myTasks = 'true';
    if (filter.taskNames?.length) query.taskNames = filter.taskNames;
    if (filter.communityIds?.length) query.communityIds = filter.communityIds;
  }
  subsService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    const openTasks = fromJS(data)
      .map(setCheckedInStatus)
      .toArray();
    this.controller.dispatch([
      state =>
        state
          .set('totalCount', totalCount)
          .set('maxCount', stopIndex + MINIMUM_BATCH_SIZE + 1)
          .set('isLoading', false)
          .update('tasks', tasks => tasks.splice(startIndex, stopIndex - startIndex + 1, ...openTasks)),
    ]);
  });
  const data = Repeat(EMPTY_ROW, stopIndex - startIndex);
  return state.update('tasks', tasks =>
    tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray())
  );
}

markAsSync(invalidateFilter);
export function invalidateFilter(state) {
  setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
  return state.merge(fromJS({ tasks: [], totalCount: 10, maxCount: 10, isLoading: true }));
}

markAsSideEffect(refeshTask);
export function refeshTask(jobId, taskId) {
  const test = task => task.getIn(['job', 'id']) === jobId && String(task.get('id')) === taskId;
  if (!this.state.get('tasks').find(test)) return;

  subsService
    .readJobDetailTask({}, { params: { jobId, taskId } })
    // .then(fromJS)
    .then(task =>
      this.controller.dispatch([
        state => {
          const index = state.get('tasks').findIndex(test);
          if (index === -1) return state;
          // at this point we are only updating `task.status` because the payload `readJobDetailTask`
          // returns is not the same as `readTasks`
          const { status } = task;
          return state.updateIn(['tasks', index], t => t.merge({ status }));
        },
      ])
    );
}

export async function assignTask({ task, assignee }) {
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const installerId = assignee ? assignee.userId || assignee.installerUserId : null;
  const installerName = assignee ? assignee.name : null;

  try {
    await subsService.updateTask({ installerId, installerName }, { params: { jobId, taskId } });
    return state => {
      const index = state.get('tasks').findIndex(t => t.get('id') === taskId && t.getIn(['job', 'id']) === jobId);
      if (index === -1) return state;
      return state.updateIn(['tasks', index, 'assigneeAccount'], assigneeAccount =>
        assigneeAccount.set('installerId', installerId).set('installerName', installerName)
      );
    };
  } catch {
    this.alert.error({ message: 'There was an error assigning this Task. Please try again.' });
  }

  return state => state;
}

markAsSync(updateStatus);
export function updateStatus(state, task, status) {
  const { tasks, status: pageTasksStatus } = state.toObject();
  const index = tasks.indexOf(task);
  const path = ['tasks', index];
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const lastTaskIndex = tasks.size - 1;

  subsService
    .updateTask({ status }, { params: { jobId, taskId } })
    .then(() => {
      if (status === 'completed')
        this.controller.dispatch([
          state => state.removeIn(path).updateIn(['totalCount'], totalCount => totalCount - 1),
        ]);
    })
    .then(() =>
      this.controller.loadMoreRows({ startIndex: lastTaskIndex, stopIndex: tasks.size, status: pageTasksStatus })
    )
    .catch(() => {
      this.alert.error({ message: 'There was a problem updating this Task. Please try again.' });
      this.controller.dispatch([state => state.setIn(path, task)]);
    });

  return state.setIn([...path, 'status'], status);
}

markAsSideEffect(updateURLParams);
export function updateURLParams() {
  const isCalendarView = this.state.get('view') === CALENDAR_VIEW;
  const currentURL = new URL(document.URL);
  currentURL.hash = isCalendarView ? '#calendar' : '';

  window.history.replaceState({}, 'TradeTrax', currentURL.href);
}

markAsSync(toggleView);
export function toggleView(state) {
  const newView = state.get('view') === LIST_VIEW ? CALENDAR_VIEW : LIST_VIEW;
  return state.set('view', newView);
}

markAsSideEffect(updateTaskRealTime);
export function updateTaskRealTime({ action, ...props }, isOpen) {
  const { task } = props;
  if (action === 'delete') {
    const { id } = task;
    this.controller.dispatch([
      state => {
        const taskIndex = this.state
          .get('tasks')
          .findIndex(task => task.get('id') === id && task.getIn(['job', 'id']) === props.jobId);
        if (taskIndex === -1) return state;

        return state.deleteIn(['tasks', taskIndex]).updateIn(['totalCount'], totalCount => totalCount - 1);
      },
    ]);
  }

  if (action === 'update-job') {
    const { job, tasks, jobId } = props;
    const { name, lotNumber, status, accountName } = job;
    this.controller.dispatch([
      state => {
        let newState = state;
        tasks.forEach(updatedTask => {
          const taskIndex = newState
            .get('tasks')
            .findIndex(task => task.get('id') === updatedTask.id && task.getIn(['job', 'id']) === jobId);
          if (taskIndex !== -1) {
            newState = newState.updateIn(['tasks', taskIndex], task =>
              task.merge({
                job: fromJS({
                  name,
                  lotNumber,
                  status,
                  builderName: accountName,
                  id: jobId,
                }),
              })
            );
          }
        });
        return newState;
      },
    ]);
  }
  if (action === 'update') {
    const {
      id,
      status,
      startDate,
      endDate,
      duration,
      installerId,
      installerName,
      installerStatus,
      assigneeAccountType,
      assigneeAccountName,
      assigneeAccountId,
      overdue,
    } = task;

    const assignedData = fromJS({
      company: assigneeAccountName,
      companyId: assigneeAccountId,
      companyName: assigneeAccountName,
      companyType: assigneeAccountType,
      installerId,
      installerName,
      installerStatus,
    });

    const accountId = this.appState.getIn(['user', 'accountId']);
    const isDifferrentAccount = task => task.assigneeAccountId !== accountId;
    const taskIndex = this.state
      .get('tasks')
      .findIndex(task => task.get('id') === id && task.getIn(['job', 'id']) === props.jobId);
    if (taskIndex === -1) return;

    if ((isOpen && status === COMPLETED) || (!isOpen && status !== COMPLETED) || isDifferrentAccount(task)) {
      this.controller.dispatch([
        state => state.deleteIn(['tasks', taskIndex]).updateIn(['totalCount'], totalCount => totalCount - 1),
      ]);
    } else {
      this.controller.dispatch([
        state => {
          const task = state.getIn(['tasks', taskIndex]);
          const updatedTask = task.merge({
            status,
            startDate,
            expectedFinishDate: endDate,
            durationDays: duration,
            assigneeAccount: assignedData,
            overdue,
          });

          return state.updateIn(['tasks', taskIndex], () => updatedTask);
        },
      ]);
    }
  }
}

markAsSync(clearState);
export function clearState(state, status) {
  return emptyState.set('status', status).set('view', state.get('view'));
}
