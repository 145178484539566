import React from 'react';
import { subsService } from 'services';

export function useCommitmentRequestsCounters() {
  const [counters, setCounters] = React.useState({ open: 0, overdue: 0 });

  React.useEffect(() => {
    subsService.getCommitmentsByTaskCount().then(setCounters);
  }, [setCounters]);

  return counters;
}
