import moment from 'moment';
import { fromJS, Map } from 'immutable';
import { markAsSync, markAsSideEffect } from '@tradetrax/web-common';
import { setCheckedInStatus } from '@tradetrax/web-common/lib/utils';
import { PrecedingStatusModal } from '@tradetrax/web-common/lib/Dashboard/Upcoming/PrecedingStatusModal';
import { getUpcomingQuery, getQueryParam } from '@tradetrax/web-common/lib/Dashboard/getQueryParams';
import { subsService } from 'services';
import navigate from 'app/navigate';

const BATCH_SIZE = 15;

const taskKey = task => task.set('key', `${task.getIn(['job', 'id'])}_${task.get('id')}`);

const expectedStartLabel = task => {
  const startDate = moment(task.get('startDate'), 'YYYY-MM-DD');
  return task
    .set('expectedStartDate', startDate.format('YYYY-MM-DD'))
    .set('expectedStartLabel', `Exp. Start ${startDate.format('MMMM D')}`);
};

markAsSync(loadUpcomingTasks);
export function loadUpcomingTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const filter = this.filterState.get('values').toJS();
  const upcommingQuery = getUpcomingQuery({ start_index, stop_index, sort: 'upcoming' }, true);
  const query = getQueryParam(upcommingQuery, filter);

  subsService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    const upcomingTasks = fromJS(data)
      .map(taskKey)
      .map(expectedStartLabel)
      .map(setCheckedInStatus)
      .toArray();

    this.controller.dispatch([
      state =>
        state
          .set('isLoading', false)
          .set('totalCount', totalCount)
          .update('tasks', tasks => tasks.splice(start_index, stop_index - start_index + 1, ...upcomingTasks)),
    ]);
  });

  return start_index === 0 ? state.set('isLoading', true) : state;
}

markAsSideEffect(loadMoreRows);
export function loadMoreRows() {
  const totalCount = this.state.get('totalCount');
  const tasks = this.state.get('tasks');

  if (tasks.size < totalCount) {
    this.controller.loadUpcomingTasks(tasks.size, tasks.size + BATCH_SIZE - 1);
  }
}

markAsSideEffect(setGroups);
export function setGroups() {
  const tasks = this.state.get('tasks');
  const groupsByDate = tasks
    .sort((a, b) => {
      if (a.get('expectedStartDate') < b.get('expectedStartDate')) return -1;
      if (b.get('expectedStartDate') < a.get('expectedStartDate')) return 1;
      return 0;
    })
    .groupBy(task => task.get('expectedStartDate'));
  this.controller.dispatch([state => state.set('groups', groupsByDate)]);
}

// markAsSideEffect(loadTasksOfInterest);
// export function loadTasksOfInterest() {
//   subsService
//     .getTasksOfInterest()
//     .then(fromJS)
//     .then(tasksOfInterest => this.controller.dispatch([state => state.merge({ tasksOfInterest })]));
// }

markAsSideEffect(getTaskDetails);
export function getTaskDetails({ action, task, ...props }) {
  if (action !== 'update' || !task) return;

  const { id } = task;

  this.controller.dispatch([
    state => {
      const taskIndex = this.state
        .get('tasks')
        .findIndex(task => task.get('id') === id && task.getIn(['job', 'id']) === props.jobId);
      if (taskIndex === -1) return state;
      const upcomingTask = expectedStartLabel(Map(task));
      const { status, expectedStartDate, checkedIn, startDate, isCritical } = upcomingTask.toObject();

      return state.updateIn(['tasks', taskIndex], task =>
        task.merge({
          status,
          expectedStartLabel: upcomingTask.get('expectedStartLabel'),
          expectedStartDate,
          checkedIn,
          startDate,
          isCritical,
        })
      );
    },
  ]);
}

markAsSideEffect(showPrecedingTasks);
export async function showPrecedingTasks(task) {
  const precedingTasks = task.getIn(['ctr', 'precedingTasksWithStatus']);
  const canEdit = this.appState.getIn(['user', 'role']) === 'manager';
  const jobId = task.getIn(['job', 'id']);
  const taskId = task.get('id');
  const loadTasksOfInterest = () => this.controller.loadTasksOfInterest(jobId, taskId);

  const { isAccept } = await this.modal.open(PrecedingStatusModal, {
    task,
    precedingTasks,
    canEdit,
    loadTasksOfInterest,
  });
  if (canEdit && isAccept && precedingTasks.size === 0) {
    navigate.to.PrecedingTasks({});
  }
}

markAsSideEffect(loadTasksOfInterest);
export function loadTasksOfInterest(jobId, taskId) {
  return (
    subsService
      .getJobTasksOfInterestForTask({}, { params: { jobId, taskId } })
      // .then(() => [])
      .then(fromJS)
  );
}

markAsSideEffect(assignUpcomingTask);
export function assignUpcomingTask(task, assignee) {
  const index = this.state.get('tasks').indexOf(task);
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const installerId = assignee ? assignee.userId || assignee.installerUserId : null;
  const installerName = assignee ? assignee.name : null;

  return subsService
    .updateTask({ installerId, installerName }, { params: { jobId, taskId } })
    .then(() =>
      this.controller.dispatch([
        state =>
          state.updateIn(['tasks', index, 'assigneeAccount'], assigneeAccount =>
            assigneeAccount.set('installerId', installerId).set('installerName', installerName)
          ),
      ])
    )
    .catch(() => {
      this.alert.error({
        message: 'There was an error assigning this Task. Please try again.',
      });
    });
}
