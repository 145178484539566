import React, { useEffect, useState } from 'react';
import { subsService } from 'services';
import { ToggleButtons, ToggleButton, useCommunities, useTaskTypes } from '@tradetrax/web-common';
import { StartToday, FinishToday, ConfirmationRequests, TodoSelectDate } from '@tradetrax/web-common/lib/ToDo';
import { Row, Col, Container } from 'react-bootstrap';
import { ToDoContext } from './ToDoContext';
import { useAppContext } from 'app/AppContext';
import { FilterSets } from '@tradetrax/web-common/lib/Filters/FilterSets';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';

export const ToDoPage = () => {
  const appContext = useAppContext();
  const { containerRef, filterContext, ...todoContext } = ToDoContext(appContext);
  const { companies } = appContext.appState.toObject();
  const communities = useCommunities(subsService);
  const taskTypes = useTaskTypes(subsService);
  const { state, controller } = todoContext;
  const { tab } = state.toObject();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);
  const lookups = { communities, taskTypes, assignees: companies };

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 1200);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between my-4 mx-4">
        <span className="h4 mb-0 font-weight-bold">To-Dos</span>
      </div>
      <FilterSets.Panel filterContext={filterContext} visible isToDo>
        <TodoSelectDate {...filterContext} />
        <FilterImplementation
          top="8rem"
          title="To-Dos Filters"
          filterContext={filterContext}
          className="ml-3"
          lookups={lookups}
        >
          <FilterSets.Section filterContext={filterContext} />
        </FilterImplementation>
        {/* <TasksFilter top="8rem" className="ml-3" /> */}
      </FilterSets.Panel>
      <Container fluid="lg" className="ml-0 pt-3 pr-3 pl-0 mr-auto" ref={containerRef}>
        {isSmallScreen && (
          <div className="mb-4">
            <ToggleButtons onChange={controller.setTab} type="radio" name="options" value={tab}>
              <ToggleButton value="expectedStart">Expected to Start</ToggleButton>
              <ToggleButton value="expectedFinish">Expected to Finish</ToggleButton>
              <ToggleButton value="SDCR">Start Date Confirmation Requests</ToggleButton>
            </ToggleButtons>
          </div>
        )}
        <Row className="pl-4">
          {(tab === 'expectedStart' || !isSmallScreen) && (
            <Col xl={4} lg={10} md={10} sm={12}>
              <StartToday {...todoContext} />
            </Col>
          )}
          {(tab === 'expectedFinish' || !isSmallScreen) && (
            <Col xl={4} lg={7} md={9} sm={12}>
              <FinishToday {...todoContext} />
            </Col>
          )}
          {(tab === 'SDCR' || !isSmallScreen) && (
            <Col xl={4} lg={7} md={9} sm={12}>
              <ConfirmationRequests {...todoContext} />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};
