import { useEffect, useRef } from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useInfiniteScroll } from '@tradetrax/web-common/lib/ToDo';
import { updateFiltersOnDataBase } from '@tradetrax/web-common/lib/Dashboard/DashboardActions';
import * as upcomingActions from './UpcomingActions';
import { subsService as upcomingService } from 'services';
import { toggleView } from '@tradetrax/web-common/lib/CalendarView/CalendarActions';
import { CALENDAR_VIEW, LIST_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

const emptyState = fromJS({
  isLoading: true,
  totalCount: 0,
  tasks: [],
  groups: [],
});

const actions = { ...upcomingActions, updateFiltersOnDataBase, toggleView };

export function UpcomingContext(appContext) {
  const loaderRef = useRef();
  const currentURL = new URL(document.URL);
  const initView = currentURL.hash === '#calendar' ? CALENDAR_VIEW : LIST_VIEW;
  const filterContext = useFilterContext(FILTER_NAME.UPCOMING);
  const { filterState } = filterContext;
  const [state, controller] = useController(actions, emptyState.set('view', initView), {
    loaderRef,
    ...appContext,
    upcomingService,
    dashboardService: upcomingService,
    filterState,
  });
  const containerRef = useInfiniteScroll(controller.loadMoreRows, 100);
  const tasks = state.get('tasks');
  const filter = filterState.get('values');

  useEffect(() => {
    controller.loadUpcomingTasks();
  }, [controller, filter]);

  useEffect(() => {
    controller.setGroups();
  }, [controller, tasks]);

  return { state, controller, containerRef, loaderRef, upcomingService, filterContext };
}
