import React from 'react';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common';
import { TasksOfInterestItem } from './TasksOfInterest.item';

export const TasksOfInterestList = ({ context, canEdit }) => {
  const { state } = context;
  const tasksOfInterest = state.get('tasksOfInterest');

  return (
    <UL>
      {tasksOfInterest.map(toi => (
        <TasksOfInterestItem key={toi.get('_id')} taskOfInterest={toi} context={context} canEdit={canEdit} />
      ))}
    </UL>
  );
};

const UL = styled.ul`
  margin-top: 0.25rem;
  list-style: none;
  padding: 0;

  li.item {
    border-top: solid 1px ${colors.gray100};
    padding: 1rem 1rem 1.5rem 1rem;

    &.expanded {
      // background-color: ${colors.activeState};
      flex-direction: column;
      margin-bottom: 1px;
      border-radius: 6px;
    }
  }
`;
