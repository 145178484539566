import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Typeahead } from 'react-bootstrap-typeahead';
import { PrimaryButton, PasswordWrapper } from '@tradetrax/web-common';
import { trimSpaces, emptyStringToNull } from '@tradetrax/web-common/lib/utils';
import { randomString } from '@tradetrax/web-common/lib/Modal/ResetPasswordModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import cn from 'classnames';

const isInstaller = value => !!(value && value.find(role => role.value === 'installer'));
const isTrade = value => !!(value && value.find(role => role.value === 'regular'));

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required')
    .matches(/^[^+÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  lastName: Yup.string()
    .required('Last Name is required')
    .matches(/^[^+÷=<>≠/!?#$%{}[\]0-9]*$/, 'Character not allowed')
    .transform(trimSpaces)
    .min(1, 'Min. 1 character')
    .max(30, 'Max. 30 characters'),
  roles: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.string(),
      })
    )
    .required()
    .min(1),
  email: Yup.string().when('roles', {
    is: isInstaller,
    then: Yup.string()
      .email('Invalid email address')
      .transform(emptyStringToNull)
      .nullable(),
    otherwise: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  }),
  phone: Yup.string()
    .matches(/^\+?[1-9]\d{9,14}$/, 'Invalid phone format')
    .transform(emptyStringToNull)
    .nullable(),
  username: Yup.string().when('step', {
    is: 'set-profile',
    then: Yup.string()
      .required('Username is required')
      .matches(/^[a-zA-Z0-9.\s]+$/, 'Character not allowed')
      .matches(/^(?!\.).*/, 'Can not begin with a period')
      .matches(/^.*[^.]$/, 'Can not end with a period')
      .matches(/^[^\s]*$/, 'Space not allowed')
      .min(6, 'Min. 6 characters')
      .max(30, 'Max. 30 characters'),
    otherwise: Yup.string()
      .transform(emptyStringToNull)
      .nullable(),
  }),
  password: Yup.string().when('step', {
    is: 'set-profile',
    then: Yup.string()
      .required('Password is required')
      .matches(/^[a-zA-Z0-9~!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/]+$/, 'Character not allowed')
      .matches(/^.*[0-9].*$/, 'Must include at least one number')
      .matches(/^.*[a-zA-Z].*$/, 'Must include at least one letter')
      .matches(/^.*[A-Z].*$/, 'Must include at least one uppercase letter')
      .matches(/^.*[a-z].*$/, 'Must include at least one lowercase letter')
      .matches(
        /^.*[~!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/].*$/,
        "Must include at least one special character ~!@#$%^&*_-+=`|\\(){}[]:;'<>,.?/"
      )
      .min(7, 'Min. 7 characters')
      .max(64, 'Max. 64 characters'),
    otherwise: Yup.string()
      .transform(emptyStringToNull)
      .nullable(),
  }),
});

const ROLES = [
  { name: 'Regular', value: 'regular' },
  { name: 'Installer', value: 'installer' },
];

export function AddUserModal({ close, controller, customRoles, alert }) {
  const firstNameRef = React.useRef();
  const usernameRef = React.useRef();
  const emailRef = React.useRef();
  const phoneRef = React.useRef();
  const { register, handleSubmit, errors, control, getValues, setValue, setError, trigger, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      roles: null,
      customRoles: null,
      email: '',
      phone: '',
      username: '',
      password: '',
      step: 'add-user',
    },
  });
  const [state, setState] = React.useState({
    step: 'add-user',
    viaEmail: false,
    viaSMS: false,
    hidePassword: true,
    isSubmitting: false,
    isInstallerSelected: isInstaller(getValues('roles')),
  });
  const { step, viaEmail, viaSMS, hidePassword, isInstallerSelected } = state;
  const email = emailRef.current?.value || '';
  const phone = phoneRef.current?.value || '';
  const username = usernameRef.current?.value || '';
  const hasEmail =
    !!email &&
    !errors.email &&
    Yup.string()
      .email()
      .required()
      .isValidSync(email);
  const hasPhone =
    !errors.phone &&
    Yup.string()
      .matches(/^\+?[1-9]\d{9,14}$/)
      .isValidSync(phone);
  const hasUsername =
    !errors.username &&
    Yup.string()
      .matches(/^[a-zA-Z0-9.]+$/, 'Character not allowed')
      .isValidSync(username);

  const isInviteUserVisible = !isInstallerSelected || hasEmail || hasPhone;
  const isCheckboxesHidden = !isInstallerSelected || !(hasEmail || hasPhone);
  const isInviteUserDisabled = isInstallerSelected && !(viaEmail || viaSMS);

  const toggleCheck = name => e => setState({ ...state, [name]: e.target.checked });
  const setIsSubmitting = value => setState({ ...state, isSubmitting: value });
  const setProfileStep = async () => {
    if (await trigger()) {
      setValue('step', 'set-profile', { shouldValidate: true });
      setState({ ...state, step: 'set-profile' });
    }
  };
  const setAddUserStep = () => {
    setValue('step', 'add-user', { shouldValidate: true });
    setState({ ...state, step: 'add-user' });
  };
  const togglePassword = () => setState({ ...state, hidePassword: !hidePassword });
  const toggleUserType = () => setState({ ...state, isInstallerSelected: isInstaller(getValues('roles')) });
  const generatePassword = e => {
    e.preventDefault();
    setValue('password', randomString(), { shouldDirty: true });
    clearErrors('password');
  };

  const handleError = err => {
    if (err.params) {
      const [error] = err.params;
      let message = err.detail;
      if (error?.param === 'username' && err.type === 'entity-conflict') message = 'Username is taken.';
      if (error?.param === 'email' && err.type === 'entity-conflict')
        message = 'This email is associated with an existing user.';
      err.params.forEach(error => setError(error.param, { type: 'manual', message }));
      if (step !== 'add-user' && error?.param === 'email') setAddUserStep();
    } else {
      alert.error({
        message: 'There was a problem adding this user. Please try again.',
      });
    }
  };

  const submitUser = ({ roles, customRoles, step, ...form }, e) => {
    e.preventDefault();
    const [role = {}] = roles;
    const [customRole = {}] = customRoles || [];

    if (step === 'add-user') {
      delete form.password;
      delete form.username;
      if (!isInviteUserVisible) {
        return setProfileStep();
      }
    }

    if (state.isSubmitting) return;
    setIsSubmitting(true);

    if (role.value === 'installer') {
      const sendEmailInvite = hasEmail && viaEmail;
      const sendSMSInvite = hasPhone && viaSMS;
      return controller.defer
        .createInstaller(form, { sendEmailInvite, sendSMSInvite })
        .then(close)
        .catch(handleError)
        .finally(() => setIsSubmitting(false));
    }

    const user = { ...form, role: role.value, customRoleId: customRole._id };
    return controller.defer
      .createUser(user)
      .then(close)
      .catch(handleError)
      .finally(() => setIsSubmitting(false));
  };

  React.useEffect(() => {
    if (step === 'add-user') {
      firstNameRef.current.focus();
    } else {
      usernameRef.current.focus();
    }
  }, [firstNameRef, usernameRef, step]);
  const { firstName, lastName, password } = getValues();
  return (
    <Modal show={true} onHide={close} data-testid="modal-add-user">
      <Form noValidate onSubmit={handleSubmit(submitUser)}>
        <Modal.Header closeButton>
          <Modal.Title>{step === 'add-user' ? 'Add User' : 'Set Installer Profile'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={cn({ 'd-none': step !== 'add-user' })}>
          <input type="hidden" ref={register} name="step" />
          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              autoComplete="off"
              size="lg"
              isInvalid={errors.firstName}
              isValid={firstName && !errors.firstName}
              placeholder="Ex. Elijah"
              onChange={() => trigger(['firstName'])}
              ref={e => {
                register(e);
                firstNameRef.current = e;
              }}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` ${errors.firstName?.message}`}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> First Name is valid
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              size="lg"
              autoComplete="off"
              isInvalid={errors.lastName}
              isValid={lastName && !errors.lastName}
              placeholder="Ex. Thompson"
              onChange={() => trigger(['lastName'])}
              ref={register}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` ${errors.lastName?.message}`}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> Last Name is valid
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="roles">
            <Form.Label>Type of User</Form.Label>
            <Controller
              control={control}
              name="roles"
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="new-user-role"
                  bsSize="lg"
                  className={cn({
                    'is-invalid': errors.roles,
                  })}
                  placeholder="Choose type of user"
                  labelKey={option => option.name}
                  onChange={e => {
                    onChange(e);
                    trigger(['email']);
                    toggleUserType();
                  }}
                  onBlur={onBlur}
                  selected={value || []}
                  options={ROLES}
                  multiple={false}
                  clearButton
                  isInvalid={!!errors.roles}
                >
                  {!value && !errors.roles && <FontAwesomeIcon icon="chevron-down" />}
                </Typeahead>
              )}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> Type is required
            </Form.Control.Feedback>
            {/* <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> Looks good!
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group controlId="custom-role" className={cn('d-none', { 'd-block': isTrade(getValues('roles')) })}>
            <Form.Label>Custom Role</Form.Label>
            <Controller
              control={control}
              name="customRoles"
              render={({ onChange, onBlur, value }) => (
                <Typeahead
                  id="custom-role"
                  bsSize="lg"
                  placeholder="Choose Custom Role"
                  labelKey={option => option.name}
                  options={customRoles.toJS()}
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value || []}
                  multiple={false}
                  clearButton
                >
                  {!value && <FontAwesomeIcon icon="chevron-down" />}
                </Typeahead>
              )}
            />
            <div className="text-secondary font-size-14">* Optional</div>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder="Ex. username@email.com"
              size="lg"
              autoComplete="off"
              isInvalid={errors.email}
              isValid={hasEmail}
              onBlur={() => trigger(['email'])}
              ref={e => {
                register(e);
                emailRef.current = e;
              }}
            />
            {isInstallerSelected && <div className="text-secondary font-size-14">* Optional</div>}
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.email?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              {hasEmail && (
                <>
                  <FontAwesomeIcon icon="circle-check" /> Email is valid
                </>
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              placeholder="Ex. 602 555 7890"
              size="lg"
              autoComplete="off"
              onBlur={() => trigger(['phone'])}
              isInvalid={errors.phone}
              isValid={hasPhone}
              ref={e => {
                register(e);
                phoneRef.current = e;
              }}
            />
            <div className="text-secondary font-size-14">* Optional</div>
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.phone?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              {hasPhone && (
                <>
                  <FontAwesomeIcon icon="circle-check" /> Phone number is valid
                </>
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <p className={cn('small', { 'd-none': isCheckboxesHidden })}>
            You can either set the username and password for the new user or invite the user to do so via email or SMS.
          </p>
          <div className={cn('flex-row mb-4', isCheckboxesHidden ? 'd-none' : 'd-flex')}>
            <span className="form-label">Send invite via:</span>
            <Form.Check className={cn('text-muted font-size-16', { 'd-none': !hasEmail })}>
              <input
                type="checkbox"
                name="viaEmail"
                id="chkEmail"
                onChange={toggleCheck('viaEmail')}
                style={{ width: '15px', height: '15px' }}
              />
              <label className="ml-2 font-size-16">Email</label>
            </Form.Check>
            <Form.Check className={cn('text-muted font-size-16', { 'd-none': !hasPhone })}>
              <input
                type="checkbox"
                name="viaSMS"
                id="chkSMS"
                onChange={toggleCheck('viaSMS')}
                style={{ width: '15px', height: '15px' }}
              />
              <label className="ml-2 font-size-16">SMS</label>
            </Form.Check>
          </div>
        </Modal.Body>
        <Modal.Footer className={cn('pt-1', { 'd-none': step !== 'add-user' })}>
          {isInstallerSelected && (
            <PrimaryButton
              onClick={setProfileStep}
              disabled={state.viaEmail || state.viaSMS}
              variant={isInviteUserVisible ? 'secondary' : 'primary'}
            >
              Set User Profile
            </PrimaryButton>
          )}
          {isInviteUserVisible && (
            <PrimaryButton type="submit" disabled={isInviteUserDisabled || state.isSubmitting}>
              Invite User
            </PrimaryButton>
          )}
        </Modal.Footer>
        <Modal.Body className={cn({ 'd-none': step !== 'set-profile' })}>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
              autoComplete="new-password"
              size="lg"
              isInvalid={errors.username}
              isValid={hasUsername}
              ref={e => {
                register(e);
                usernameRef.current = e;
              }}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" /> {errors.username?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" /> Username is valid
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <div className="d-flex flex-row justify-content-between">
              <Form.Label>Password</Form.Label>
              <button type="button" onClick={generatePassword} className="btn btn-link btn-sm p-0">
                Generate strong password
              </button>
            </div>
            <PasswordWrapper onClick={togglePassword} isPlain={!hidePassword}>
              <Form.Control
                type={hidePassword ? 'password' : 'text'}
                name="password"
                autoComplete="new-password"
                size="lg"
                isInvalid={errors.password}
                isValid={password && !errors.password}
                ref={register}
              />
              <Form.Control.Feedback type="invalid">
                <FontAwesomeIcon icon="circle-exclamation" /> {errors.password?.message}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid">
                <FontAwesomeIcon icon="circle-check" /> Password is ready to go
              </Form.Control.Feedback>
            </PasswordWrapper>
          </Form.Group>
          <p className="text-secondary pt-3 small">
            <strong>Important:</strong> Write down these credentials to share them with installer user later.
          </p>
        </Modal.Body>
        <Modal.Footer className={cn({ 'd-none': step !== 'set-profile' })}>
          <PrimaryButton type="submit" disabled={state.isSubmitting}>
            Add User
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
