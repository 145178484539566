import React from 'react';
import { useAppContext } from 'app/AppContext';
import { SideBar, SideBarLink, SideBarSeparator } from '@tradetrax/web-common';
import { SideBarLinkOverlay, HelpCenterLink } from '@tradetrax/web-common/lib/SideBar';
import { Paths } from 'app/navigate';

const SubsSideBar = () => {
  const { appState, hasPermission } = useAppContext();
  const { counters } = appState.toObject();

  return (
    <SideBar expanded={appState.get('expanded')} companyName={appState.getIn(['account', 'name'])}>
      <SideBarLinkOverlay to={Paths.DashboardV2} icon="table-columns" counters={counters} isTrade exact>
        Dashboard
      </SideBarLinkOverlay>
      <SideBarLink to={Paths.ToDo} icon="circle-check" badge={counters} isTrade exact>
        To-Dos
      </SideBarLink>
      <SideBarLink to={Paths.UpcomingTasks} icon="clipboard-list">
        Upcoming
      </SideBarLink>
      <SideBarLink to={Paths.Community} icon="building">
        Communities
      </SideBarLink>
      <SideBarLink to={Paths.Job} icon="house">
        Jobs
      </SideBarLink>
      <SideBarLink to={Paths.TaskOpen} icon="wrench">
        Open Tasks
      </SideBarLink>
      <SideBarLink to={Paths.TaskCompleted} icon="circle-check">
        Completed Tasks
      </SideBarLink>
      <SideBarSeparator />
      <SideBarLink to={Paths.MyProfile} icon="circle-user">
        My Profile
      </SideBarLink>
      {hasPermission('user_management') && (
        <SideBarLink to={Paths.Users} icon="users">
          Users
        </SideBarLink>
      )}
      {hasPermission('user_management') && (
        <SideBarLink to={Paths.Settings} icon="gear">
          Settings
        </SideBarLink>
      )}
      <HelpCenterLink />
    </SideBar>
  );
};

//       <NavigationItem link="/task/open" text="Open Tasks" count={tasksStore.countOpenTasks} />

export default SubsSideBar;
