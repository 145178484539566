import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { Context, AppContext } from './AppContext';
import navigate, { history, Paths } from './navigate';
import { FiltersContext, createFiltersContext } from '@tradetrax/web-common/lib/Filters/Filters.context';
import { MiniAlerts, Dialog, Notifications, LayoutContent, LandingPage, NotFound } from '@tradetrax/web-common';
import { subsService } from 'services';
import * as Layout from './layout';
import * as Pages from './pages';
import '../styles/react-virtualized.css';

const taskUrl = ({ ...props }) => navigate.to.TaskDetails({ status: 'open', ...props }, false);
const jobUrl = props => navigate.to.JobDetails(props, false);
const openTasksUrl = props => navigate.to.TaskOpen(props, false);
const jobsUrl = props => navigate.to.Job(props, false);
const dashboardUrl = props => navigate.to.DashboardV2(props, false);

function App({ user, device }) {
  const context = Context(user);
  const filtersContext = createFiltersContext(context, subsService);
  const { appState, alert, miniAlertsRef } = context;
  const { add } = alert || {};

  const showLanding = device.isIOS || device.isAndroid;
  const [show, setShow] = React.useState(showLanding);
  const accountUsers = appState.getIn(['account', 'assignees']);

  React.useEffect(() => {
    if (showLanding && add) {
      add({
        message: 'For the best experience go to the desktop version or download the mobile app.',
        autoClose: false,
        variant: 'primary',
      });
    }
  }, [showLanding, add]);

  if (show)
    return (
      <LandingPage device={device}>
        <MiniAlerts ref={miniAlertsRef} />
        <button onClick={() => setShow(false)} className="btn btn-link">
          Skip and Continue
        </button>
      </LandingPage>
    );

  return (
    <AppContext.Provider value={context}>
      <FiltersContext.Provider value={filtersContext}>
        <Router history={history}>
          <Layout.SideBar />
          <Layout.TopBar />
          <Notifications
            context={context}
            taskUrl={taskUrl}
            jobUrl={jobUrl}
            jobsUrl={jobsUrl}
            openTasksUrl={openTasksUrl}
            dashboardUrl={dashboardUrl}
            accountUsers={accountUsers}
          />
          <LayoutContent expanded={appState.get('expanded')}>
            <Dialog controller={context.modal} />
            <MiniAlerts ref={miniAlertsRef} />
            <Switch>
              <Route exact path={Paths.Home}>
                <Redirect to={Paths.DashboardV2} />
              </Route>
              <Route path={Paths.DashboardV2} component={Pages.DashboardV2} />
              <Route path={Paths.ToDo} component={Pages.ToDo} />
              <Route path={Paths.UpcomingTasks} component={Pages.Upcoming} />
              <Route path={Paths.Job} component={Pages.Jobs} />
              <Route path={Paths.TaskStatus} component={Pages.Tasks} />
              <Route path={Paths.Installer} component={Pages.Installers} />
              <Route path={Paths.CommunityIntake} component={Pages.Intake} />
              <Route path={Paths.Community} component={Pages.Communities} />
              <Route path={Paths.MyProfile} component={Pages.MyProfile} exact />
              <Route path={Paths.Users} component={Pages.Users} />
              <Route path={Paths.Settings} component={Pages.Settings} />
              <Route path={Paths.NotPermission} component={NotFound} exact />
              <Redirect from="/token" to="/" />
              <Route component={NotFound} />
            </Switch>
          </LayoutContent>
        </Router>
      </FiltersContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
