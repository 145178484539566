import moment from 'moment';
import { fromJS } from 'immutable';
import { subsService } from 'services';
import { TasksGraph } from '@tradetrax/tasks-util';
import { formatISO, setCheckedInStatus } from '@tradetrax/web-common/lib/utils';
import { markAsSync, markAsSideEffect } from '@tradetrax/web-common';
import { TaskUpdateRequestModal } from '@tradetrax/web-common/lib/ToDo/TaskUpdateRequestModal';
import { emptyList } from 'app/entities';

export * from './TaskDetails.RealTime.actions';

export function load(jobId, taskId) {
  taskId = parseInt(taskId, 10);
  return subsService
    .readJobDetail({}, { params: { jobId } })
    .then(job => {
      const graph = new TasksGraph(job.tasks, job);
      job = fromJS(job);
      let task = job.get('tasks').find(t => t.get('id') === taskId);
      if (!task) return state => state.set('hasPermission', false);
      task = setCheckedInStatus(task);
      return state => state.merge({ job, task, graph });
    })
    .catch(error => {
      let hasPermission = true;
      if (error.httpCode === 404) hasPermission = false;
      return state => state.set('hasPermission', hasPermission);
    });
}

// TODO delete function
export function readTaskHistory(jobId, taskId) {
  return subsService
    .readTaskHistory({}, { params: { jobId, taskId } })
    .then(fromJS)
    .catch(() => {
      // the sub doesn't have this task assigned, can't see history.
      return emptyList;
    })
    .then(history => state => state.set('history', history));
}

function getJobIdTaskId(context) {
  const task = context.state.get('task');
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  return { jobId, taskId, task };
}

markAsSync(updateStatus);
export function updateStatus(state, { status }) {
  const { taskId, jobId, task } = getJobIdTaskId(this);

  subsService
    .updateTask({ status }, { params: { jobId, taskId } })
    .then(this.invalidateTaskHistory)
    .then(this.refreshTask())
    .catch(() => {
      this.errorMessage('There was a problem updating this Task. Please try again.');
      this.controller.dispatch([state => state.set('task', task)]);
    });

  return state.setIn(['task', 'status'], status);
}

export function assignTask(installer) {
  const { taskId, jobId } = getJobIdTaskId(this);
  const installerId = installer ? installer.get('userId') || installer.get('installerUserId') : null;
  const installerName = installer ? installer.get('name') : null;

  return subsService
    .updateTask({ installerId, installerName }, { params: { jobId, taskId } })
    .then(this.invalidateTaskHistory)
    .then(() => state => state.updateIn(['task', 'assigneeAccount'], ac => ac.merge({ installerId, installerName })))
    .catch(this.errorMessage('There was an error assigning the Task, operation not completed!'));
}

markAsSync(updateExpFinishDateRequest);
export function updateExpFinishDateRequest(state, date) {
  const task = state.get('task');
  const job = state.get('job');
  const graph = state.get('graph');

  const jobDelay = getJobDelay(graph, job, task, date);

  return state.updateIn(['task', 'changeRequest'], changeRequest =>
    changeRequest.set('proposedFinishDate', date).set('jobDelay', jobDelay)
  );
}

export function sendProposeStartDateToBuilder(reasonsWeather) {
  const reasons = reasonsWeather ? ['weather'] : [];
  const { jobId, taskId } = getJobIdTaskId(this);

  return subsService
    .acceptFinishDateRequest({ reasons }, { params: { jobId, taskId } })
    .then(fromJS)
    .then(response => {
      this.alert.success({ message: 'Update Request successfully sent to Builder.' });
      return state =>
        state.update('task', task =>
          task
            .set('changeRequest', response.get('changeRequest'))
            .set('changeRequestOnReviewByBuilder', response.get('changeRequestOnReviewByBuilder'))
        );
    });
}

export function cancelExpFinishDateRequest() {
  const { jobId, taskId } = getJobIdTaskId(this);

  return subsService
    .rejectFinishDateRequest({}, { params: { jobId, taskId } })
    .then(() => {
      this.alert.success({ message: 'Update request declined. Installer will be notified.' });
      return state => state.setIn(['task', 'changeRequest', 'activeForCurrentUser'], false);
    })
    .catch(
      this.errorMessage('There was a problem declining this update request from the Installer. Please try again.')
    );
}

export function sendExpFinishDateRequestToBuilder(reasonsWeather) {
  const reasons = reasonsWeather ? ['weather'] : [];

  const { jobId, taskId, task } = getJobIdTaskId(this);
  const newEndDate = formatISO(task.getIn(['changeRequest', 'proposedFinishDate']));

  return subsService
    .acceptFinishDateRequest({ newEndDate, reasons }, { params: { jobId, taskId } })
    .then(fromJS)
    .then(response => state => {
      this.alert.success({ message: 'Update Request from Installer successfully sent to Builder.' });
      return state.update('task', task =>
        task
          .set('changeRequest', response.get('changeRequest'))
          .set('changeRequestOnReviewByBuilder', response.get('changeRequestOnReviewByBuilder'))
      );
    })
    .catch(
      this.errorMessage('There was a problem confirming this update request from the installer. Please try again.')
    );
}

markAsSideEffect(sendDateUpdateRequest);
export async function sendDateUpdateRequest(task) {
  const isStartDateConfirmationRequest = !!task.get('startDateConfirmationRequest');
  const canProposeFinish = !isStartDateConfirmationRequest;
  const { form, isAccept } = await this.modal.open(TaskUpdateRequestModal, { task, canProposeFinish });

  if (!isAccept) return;

  try {
    const { taskId, jobId, task } = getJobIdTaskId(this);
    const { startDate, finishDate, reasons } = form;
    const reasonsArray = reasons ? ['weather'] : [];

    if (isStartDateConfirmationRequest) {
      return this.controller.confirmDateChangeRequest(task, formatISO(startDate), reasonsArray);
    }

    if (startDate) {
      return await this.controller.sendStartDateRequest(startDate, jobId, taskId, reasonsArray);
    }

    if (finishDate) {
      return await this.controller.sendFinishDateRequest(finishDate, jobId, taskId, reasonsArray);
    }
  } catch {
    this.alert.error({ message: 'There was a problem sending this update request. Please try again.' });
  }
}

export function sendStartDateRequest(startDate, jobId, taskId, reasons) {
  const newStartDate = formatISO(startDate);
  const startDateConfirmation = false;

  return subsService
    .sendStartDateRequest({ newStartDate, startDateConfirmation, reasons }, { params: { jobId, taskId } })
    .then(response => state => {
      this.alert.success({ message: 'Update request successfully sent.' });
      const { changeRequestOnReviewByBuilder } = response;
      return state.setIn(['task', 'changeRequestOnReviewByBuilder'], fromJS(changeRequestOnReviewByBuilder));
    });
}

export function sendFinishDateRequest(finishDate, jobId, taskId, reasons) {
  const newEndDate = formatISO(finishDate);
  return subsService
    .requestNewEndDate({ newEndDate, reasons }, { params: { jobId, taskId } })
    .then(response => state => {
      this.alert.success({ message: 'Update request successfully sent.' });
      const { changeRequestOnReviewByBuilder } = response;
      return state.setIn(['task', 'changeRequestOnReviewByBuilder'], fromJS(changeRequestOnReviewByBuilder));
    });
}

function getJobDelay(tasksGraph, job, task, proposedFinishDate) {
  tasksGraph.setEndDate(task.get('id'), moment.utc(proposedFinishDate).format('YYYY-MM-DD'));
  const newEndDate = moment.utc(tasksGraph.getEndDate());
  const days = moment.utc(newEndDate).diff(job.get('expectedFinishDate'), 'days');
  return days; //> 0 ? days : 0;
}

export function updateTaskSuper({ user }) {
  const { jobId, taskId } = getJobIdTaskId(this);
  const userSuperId = user ? user.get('_id') : null;

  return subsService
    .updateTask({ userSuperId }, { params: { jobId, taskId } })
    .then(this.invalidateTaskHistory)
    .then(() => state => state.setIn(['task', 'userSuper'], user))
    .catch(this.errorMessage('There was a problem updating this Task. Please try again.'));
}

export function updateTaskScheduler({ user }) {
  const { jobId, taskId } = getJobIdTaskId(this);
  const userSchedulerId = user ? user.get('_id') : null;
  return subsService
    .updateTask({ userSchedulerId }, { params: { jobId, taskId } })
    .then(this.invalidateTaskHistory)
    .then(() => state => state.setIn(['task', 'userScheduler'], user))
    .catch(this.errorMessage('There was a problem updating this Task. Please try again.'));
}

markAsSideEffect(changeDate);
export async function changeDate(task, canSendUpdateRequest) {
  if (canSendUpdateRequest) {
    const { form, isAccept } = await this.modal.open(TaskUpdateRequestModal, { task });
    const reasonsArray = form?.reasons ? ['weather'] : [];

    if (isAccept) {
      const { startDate } = form;
      this.controller.confirmDateChangeRequest(task, formatISO(startDate), reasonsArray);
    }
  } else {
    this.addAlert("You don't have permission to manage Update Requests. Please contact your supervisor.", 'danger');
  }
}

markAsSideEffect(confirmDateChangeRequest);
export function confirmDateChangeRequest(task, newStartDate, reasons) {
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const startDateConfirmation = true;

  subsService
    .sendStartDateRequest({ newStartDate, startDateConfirmation, reasons }, { params: { jobId, taskId } })
    .then(({ changeRequestOnReviewByBuilder }) => {
      this.controller.dispatch([
        state => state.setIn(['task', 'changeRequestOnReviewByBuilder'], fromJS(changeRequestOnReviewByBuilder)),
      ]);
      this.addAlert('Update Request successfully sent.');
    })
    .catch(err => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      throw err;
    });
}

markAsSideEffect(confirmDate);
export function confirmDate(task) {
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const startDateConfirmed = true;

  subsService
    .updateTask({ startDateConfirmed }, { params: { jobId, taskId } })
    .then(this.invalidateTaskHistory)
    .then(() => {
      this.controller.dispatch([
        state =>
          state.update('task', task =>
            task.set('startDateConfirmationRequest', false).set('startDateConfirmed', startDateConfirmed)
          ),
      ]);
      this.addAlert('Start Date Confirmation successfully sent.');
    })
    .catch(err => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      throw err;
    });
}

markAsSync(updateSubTaskStatus);
export function updateSubTaskStatus(state, task, subTask) {
  const children = task.get('children');
  const childIndex = children.indexOf(subTask);
  const taskId = task.get('id');
  const jobId = state.getIn(['job', '_id']);
  const childId = subTask.get('_id');
  const status = subTask.get('status') === 'not-started' ? 'completed' : 'not-started';

  subsService.updateTaskChild({ status }, { params: { jobId, taskId, childId } }).catch(error => {
    this.addAlert('There was a problem updating this Sub-Task. Please try again.', 'danger');
    this.controller.dispatch([state => state.setIn(['task', 'children', childIndex], children.get(childIndex))]);
  });

  return state.setIn(['task', 'children', childIndex, 'status'], status);
}
