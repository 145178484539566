import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { PrimaryButton, MiniProfileModal } from '@tradetrax/web-common';
import cn from 'classnames';

export function ResendInviteModal({ accept, cancel, user }) {
  const hasPhone = !!user.get('phone');
  const hasEmail = !!user.get('email');
  const [state, setState] = React.useState({ viaEmail: hasEmail, viaSMS: hasPhone && !hasEmail });
  const btnDisabled = !state.viaEmail && !state.viaSMS;
  const name = `${user.get('firstName')} ${user.get('lastName')}`;
  const onChange = e => {
    const { name, checked } = e.target;
    setState({
      ...state,
      [name]: checked,
    });
  };

  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        <Modal.Title>Resend Invite</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <p>You can resend the invite through SMS or Email.</p>
        <MiniProfileModal initials={user.get('initials')} name={name} email={user.get('email')} />
        <div className="d-flex flex-row mt-3 ml-4 text-secondary">
          <span>Via:</span>
          <Form.Group controlId="viaSMS" className={cn('ml-2', { 'd-none': !hasPhone })}>
            <Form.Check
              type="checkbox"
              label="SMS"
              name="viaSMS"
              disabled={!hasPhone}
              checked={state.viaSMS}
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group controlId="viaEmail" className={cn('ml-2', { 'd-none': !hasEmail })}>
            <Form.Check
              type="checkbox"
              label="Email"
              name="viaEmail"
              disabled={!hasEmail}
              checked={state.viaEmail}
              onChange={onChange}
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton variant="secondary" className="mr-3" onClick={() => cancel()}>
          Cancel
        </PrimaryButton>
        <PrimaryButton onClick={() => accept({ ...state })} disabled={btnDisabled}>
          Resend Invite
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}
