import React from 'react';
import cn from 'classnames';
import { Link, Redirect } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { Map } from 'immutable';
import { ToggleButtons, ToggleButton, JobFeed, IconButton } from '@tradetrax/web-common';
import { Attachments } from '@tradetrax/web-common/lib/Photos/Attachments/Attachments';
import { JobDetailsContext } from './JobDetailsContext';
import { Schedule } from './Schedule';
import { JobHeader } from './JobHeader';
import { FilterSets } from '@tradetrax/web-common/lib/Filters/FilterSets';
import { CALENDAR_VIEW } from '@tradetrax/web-common/lib/CalendarView/CalendarContext';
import { CalendarView } from '@tradetrax/web-common/lib/CalendarView';
import { subsService } from 'services';
import { useAppContext } from 'app/AppContext';
import { PlaceHolder } from '@tradetrax/web-common/lib/LoadingPlaceholder';
import navigate, { Paths } from 'app/navigate';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';
import { jobTaskFilter } from '@tradetrax/web-common/lib/Filters/Filter.collections';

const icons = {
  grid: 'list',
  list: 'grid',
};

const getDefaultTab = location => {
  if (location.hash && location.hash !== '#calendar') return location.hash.replace('#', '');
  if (location.state) return location.state.referrer;
  return 'schedule';
};

export function JobDetails({ match, location }) {
  const { jobId } = match.params;
  const defaultTab = getDefaultTab(location);
  const { state, controller, feedLoaderRef, tableRef, attachmentsRef, filterContext } = JobDetailsContext(
    jobId,
    defaultTab
  );
  const appContext = useAppContext();
  const { account, appState } = appContext;
  const user = appState.get('user');
  const userId = user.get('_id');
  const { job, tab, tasks: allTasks, attachmentsCount, attachmentsView, view, isLoading, taskTypes } = state.toObject();
  const { filterState, isFiltering } = filterContext;
  const filter = filterState.get('values');
  const showCalendar = view === CALENDAR_VIEW;
  const lookups = { taskTypes };

  const tasks = React.useMemo(() => jobTaskFilter(filterState, allTasks, userId), [allTasks, filterState, userId]);

  const getTaskLink = React.useMemo(() => {
    const url = navigate.from.JobDetails.to.TaskDetails;
    const byId = tasks.reduce((memo, task) => memo.set(task.get('id'), task), Map());
    return ({ taskId }) => {
      const index = tasks.findIndex(task => task.get('id') === taskId);
      const status = tasks.getIn([index, 'status']) === 'completed' ? 'completed' : 'open';

      return byId.get(taskId) ? url({ taskId, status }, false) : null;
    };
  }, [tasks]);

  if (!state.get('hasPermission') || job.get('released') === false) return <Redirect to={Paths.NotPermission} />;

  return (
    <>
      {isLoading ? (
        <div className="mt-2">
          <PlaceHolder className="mx-4 mt-4" style={{ width: 112 }} />
        </div>
      ) : (
        <Breadcrumb className="mx-4 mt-4" tag="nav">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Job }}>
            Jobs
          </Breadcrumb.Item>
          <Breadcrumb.Item active tag="span">
            {job.get('name')}
          </Breadcrumb.Item>
        </Breadcrumb>
      )}
      <JobHeader job={job} users={state.get('users')} isLoading={isLoading} />
      <div className="d-flex flex-row justify-content-between mt-4 mr-4 mb-2">
        <ToggleButtons onChange={controller.setTab} className="mb-2 mr-auto" type="radio" name="options" value={tab}>
          <ToggleButton value="schedule">Schedule</ToggleButton>
          <ToggleButton value="feed">Job Feed</ToggleButton>
          <ToggleButton value="attachments">Attachments ({attachmentsCount})</ToggleButton>
        </ToggleButtons>
        <div className={cn('d-none', { 'd-flex': tab === 'attachments' })}>
          <IconButton
            className="active-on-hover cursor-pointer ml-3"
            icon={icons[state.get('attachmentsView')]}
            onClick={() => {
              controller.toggleAttachmentsView('job-details');
            }}
          />
        </div>
      </div>

      <FilterSets.Panel filterContext={filterContext} visible={tab === 'schedule'}>
        {/* <div className="d-flex" data-testid="job-add-task-panel"> */}
        <IconButton
          icon={showCalendar ? 'list' : 'calendar-days'}
          onClick={() => controller.toggleView()}
          className="active-on-hover"
        />
        <FilterImplementation filterContext={filterContext} lookups={lookups} isTrade className="ml-3 size-sm">
          <FilterSets.Section filterContext={filterContext} />
        </FilterImplementation>
        {/* <JobFilter isTrade /> //TODO: what the `isTrade` props was used for?*/}
      </FilterSets.Panel>
      {showCalendar ? (
        <CalendarView
          jobId={jobId}
          filter={filter}
          appContext={appContext}
          calendarService={subsService}
          navigate={navigate}
        />
      ) : (
        <>
          {tab === 'schedule' && (
            <Schedule
              tasks={tasks}
              account={account}
              totalCount={tasks.size}
              expandedRows={state.get('expandedRows')}
              tableRef={tableRef}
              controller={controller}
              isFilterSet={isFiltering}
              isLoading={isLoading}
            />
          )}
          {tab === 'feed' && (
            <JobFeed
              jobFeed={state.get('feed')}
              loadMoreRows={params => controller.readJobFeed({ jobId, ...params })}
              loaderRef={feedLoaderRef}
              jobFeedCount={state.get('jobFeedCount')}
              appContext={appContext}
              user={user}
              account={account}
              linkToTask={getTaskLink}
              emptyStateText="When a Job is started all schedule and status updates will appear here."
            />
          )}
        </>
      )}
      <Attachments
        job={job}
        visible={tab === 'attachments'}
        appContext={appContext}
        setAttachmentsCount={controller.setAttachmentsCount}
        service={subsService}
        ref={attachmentsRef}
        view={attachmentsView}
      />
    </>
  );
}
