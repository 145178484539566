import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'app/navigate';
import { TaskDetails } from './TaskDetails';
import { TaskPage } from './TaskPage';
import { NotFound } from '@tradetrax/web-common';

export const TaskRoutes = () => {
  return (
    <Switch>
      <Route exact path={Paths.TaskDetails} component={TaskDetails}></Route>
      <Route exact path={Paths.TaskStatus} component={TaskPage}></Route>
      <Route component={NotFound} />
    </Switch>
  );
};
