import { debounce } from 'lodash';
import { fromJS } from 'immutable';
import { markAsSideEffect } from '@tradetrax/web-common/lib/useController';

const RT_JOB_ACTIONS = {
  CREATE_TASK: 'craete-task',
  UPDATE: 'update', //TODO: Why BE is sending `update` instead of `update-task`?
  DELETE_TASK: 'delete-task',
  UPDATE_HISTORY: 'update-history',
  UPDATE_FEED: 'update-feed',
};

// this method will wait 5 secconds to read the entire job from BE
// if it's called many times withing that time frame it wil wait until the last call
// to perform the request to the BE API.
const FIVE_SECONDS = 5 * 1000;
const refreshJobAfterRealTime = debounce((controller, jobId, taskId) => {
  controller.load(jobId, taskId);
}, FIVE_SECONDS);

markAsSideEffect(setTaskDetails);
export function setTaskDetails({ action, task, jobId, taskId }) {
  if (action !== RT_JOB_ACTIONS.UPDATE_HISTORY) {
    refreshJobAfterRealTime(this.controller, jobId, taskId);
  }

  if (action !== RT_JOB_ACTIONS.UPDATE || !task) return;

  const {
    status,
    startDate,
    endDate,
    duration,
    installerId,
    installerName,
    installerStatus,
    assigneeAccountType,
    assigneeAccountName,
    assigneeAccountId,
    subUserScheduler,
    subUserSuper,
    overdue,
  } = task;

  const assignedData = fromJS({
    companyId: assigneeAccountId,
    companyName: assigneeAccountName,
    companyType: assigneeAccountType,
    installerId,
    installerName,
    installerStatus,
  });

  this.controller.dispatch([
    state =>
      state.update('task', task =>
        task.merge({
          status,
          startDate,
          expectedFinishDate: endDate,
          durationDays: duration,
          assigneeAccount: assignedData,
          userScheduler: subUserScheduler,
          userSuper: subUserSuper,
          overdue,
        })
      ),
  ]);
}
