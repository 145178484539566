import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { InlineInputEdit } from '@tradetrax/web-common/lib/EditableField/InlineInputEdit';
import { UserPermissions } from '@tradetrax/web-common/lib/UserProfile/UserPermissions';

import { Notifications } from './Notifications';
import { FilterSets } from './FilterSets';
import {
  CustomRoleDetailsContext,
  checkValidName,
  nameSchema,
  NOTIFICATIONS,
  FILTER_SETS,
  PERMISSIONS,
} from './CustomRoleDetailsContext';
import { Paths } from 'app/navigate';

export function CustomRoleDetails({ match, context, customRoles, globalTasks }) {
  const { customRoleId } = match.params;
  const { state, controller, loggedUser } = CustomRoleDetailsContext(customRoleId, context);
  const { customRole, tab, filterSets } = state.toObject();
  return (
    <>
      <Breadcrumb className="mx-4 mt-4" tag="nav">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Settings }}>
          Custom Roles
        </Breadcrumb.Item>
        <Breadcrumb.Item active tag="span">
          {customRole.get('name')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <RoleNameInputEdit customRole={customRole} controller={controller} customRoles={customRoles} />
      <div className="d-flex flex-row justify-content-between mt-4 mb-4">
        <ToggleButtons onChange={controller.setTab} type="radio" name="options" value={tab}>
          <ToggleButton value={NOTIFICATIONS}>Notifications</ToggleButton>
          <ToggleButton value={FILTER_SETS}>Filter Sets</ToggleButton>
          <ToggleButton value={PERMISSIONS}>Permissions</ToggleButton>
        </ToggleButtons>
      </div>
      {tab === NOTIFICATIONS && <Notifications state={state} controller={controller} globalTasks={globalTasks} />}
      {tab === FILTER_SETS && <FilterSets controller={controller} filterSets={filterSets} loggedUser={loggedUser} />}
      {tab === PERMISSIONS && <UserPermissions user={customRole} controller={controller} isCustomRole />}
    </>
  );
}

function RoleNameInputEdit({ customRole, customRoles, controller }) {
  const { active, name } = customRole.toObject();
  const isNameValid = (name, setError) => checkValidName(name, customRoles, setError);

  if (active)
    return (
      <InlineInputEdit
        labelClassName="h4 mx-3 mt-2 mb-4 font-weight-bold"
        inputClassName="ml-4"
        inputSize="fit-content"
        value={name}
        field="name"
        schema={nameSchema}
        update={controller.updateName}
        onBlur={isNameValid}
      />
    );

  return (
    <>
      <span className="font-weight-bold h4 ml-2">{name}</span>
      <button className="btn btn-link text-green-300 mr-5 pr-5" onClick={() => controller.toggleRoleActive(customRole)}>
        <FontAwesomeIcon icon="circle-plus" className="mr-2" />
        Reactivate Custom Role
      </button>
    </>
  );
}
