import React from 'react';
import { CompanyInfo } from './CompanyInfo';
import { CustomRoles } from './CustomRoles';
import { SubTasks } from './CustomSubTasks';
import { Billing } from './Billing';
import { NotPermission, ToggleButtons, ToggleButton } from '@tradetrax/web-common';
import { EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { useAppContext } from 'app/AppContext';
import styled from 'styled-components';

export const SettingsPage = ({ context }) => {
  const { state, controller } = context;
  const tab = state.get('tab');
  const { hasPermission, appState } = useAppContext();
  const isManager = appState.getIn(['user', 'role']) === 'manager';

  if (!hasPermission('user_management'))
    return (
      <EmptyStateCenterContainer>
        <NotPermission />
      </EmptyStateCenterContainer>
    );

  return (
    <>
      <h4 className="font-weight-bold mx-4 my-4 ">Settings</h4>
      <div className="d-flex flex-row justify-content-between mt-4" style={{ marginBottom: '36px' }}>
        <TabButtons onChange={controller.setTab} type="radio" name="options" value={tab}>
          <ToggleButtonContainer value="company">Company</ToggleButtonContainer>
          <ToggleButtonContainer value="custom-roles">Custom Roles</ToggleButtonContainer>
          <ToggleButtonContainer hidden={!isManager} value="sub-tasks" subTasks>
            Custom Sub-Tasks
          </ToggleButtonContainer>
          <ToggleButtonContainer value="billing">Billing</ToggleButtonContainer>
          {/* <ToggleButton value="preceding-tasks">Definition of Ready</ToggleButton>
           */}
        </TabButtons>
      </div>
      {tab === 'company' && <CompanyInfo context={context} />}
      {tab === 'custom-roles' && <CustomRoles context={context} />}
      {tab === 'sub-tasks' && <SubTasks settingsContext={context} />}
      {tab === 'billing' && <Billing companyName={context.state.getIn(['companyInfo', 'name'])} />}
    </>
  );
};

const TabButtons = styled(ToggleButtons)`
  .btn.btn-primary {
    width: 260.2px;
    height: 39px;
  }
`;

const ToggleButtonContainer = styled(ToggleButton)`
  ${props => `
    padding: ${props.subTasks ? '10px 5px' : '10px 10px '};
  `}
  max-width: 140px;

  @media (min-width: 992px) {
    max-width: 196px;
  }
  @media (min-width: 1200px) {
    max-width: 210px;
  }
`;
