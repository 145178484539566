import { fromJS } from 'immutable';
import { subsService, accountsService } from 'services';
import { markAsSync } from '@tradetrax/web-common';
import { getUserDomain } from '@tradetrax/web-common/lib/utils';

// TODO: deprecate => don't fetch `mySelf` that information is already present
// on first load `/self`
// TODO: review if responses from `/self` and `getUser` are differents.
export async function initializeAccount() {
  const userId = this.state.getIn(['user', '_id']);
  const [account, mySelf] = await Promise.all([
    accountsService.self().then(fromJS),
    subsService.getUser({}, { params: { userId } }).then(fromJS),
  ]);

  const assignees = account
    .get('users')
    .map(user => user.set('name', user.get('firstName') + ' ' + user.get('lastName')).set('userId', user.get('_id')));
  const assigneesActive = assignees.filter(assignee => assignee.get('status') === 'active');
  const regularUsersActive = assigneesActive.filter(assignee => {
    const { isTrade } = getUserDomain(assignee);
    return isTrade;
  });

  return state =>
    state.set(
      'account',
      account
        .set('assignees', assignees)
        .set('assigneesActive', assigneesActive)
        .set('regularUsersActive', regularUsersActive)
        .set('legalAgreements', mySelf.get('legalAgreements'))
    );
}

export async function updateSettings(settings) {
  return subsService
    .updateSettings(settings)
    .then(() =>
      this.alert.add({
        message: 'Account settings successfully updated',
        timeout: 5,
      })
    )
    .then(() => state => state.updateIn(['account'], account => account.merge(fromJS(settings))))
    .catch(this.errorMessage('There was an error updating the settings'));
}

markAsSync(updateUserPermission);
export function updateUserPermission(state, user, status) {
  const assignee = state.getIn(['account', 'assignees']).find(assignee => assignee.get('_id') === user.get('_id'));
  const index = state.getIn(['account', 'assignees']).indexOf(assignee);
  const updatedState = state.updateIn(['account', 'assignees', index], user => user.set('status', status));
  return updateAccountUserPermission(updatedState, user, status);
}

function updateAccountUserPermission(state, user, status) {
  const assignee = state.getIn(['account', 'users']).find(assignee => assignee.get('_id') === user.get('_id'));
  const index = state.getIn(['account', 'users']).indexOf(assignee);
  const updatedState = state.updateIn(['account', 'users', index], user => user.set('status', status));
  return addRemoveRegularUser(updatedState, user, status);
}

function addRemoveRegularUser(state, user, status) {
  const isActive = status === 'active';
  if (!isActive) {
    const assignee = state
      .getIn(['account', 'regularUsersActive'])
      .find(assignee => assignee.get('_id') === user.get('_id'));
    const index = state.getIn(['account', 'regularUsersActive']).indexOf(assignee);
    const updatedState = state.updateIn(['account', 'regularUsersActive'], users => users.splice(index, 1));
    return addRemoveAssigneesActive(updatedState, user, status);
  } else {
    const assignee = state.getIn(['account', 'users']).find(assignee => assignee.get('_id') === user.get('_id'));
    const updatedState = state.updateIn(['account', 'regularUsersActive'], users => users.splice(0, 0, assignee));
    return addRemoveAssigneesActive(updatedState, user, status);
  }
}

function addRemoveAssigneesActive(state, user, status) {
  const isActive = status === 'active';
  if (!isActive) {
    const assignee = state
      .getIn(['account', 'assigneesActive'])
      .find(assignee => assignee.get('_id') === user.get('_id'));
    const index = state.getIn(['account', 'assigneesActive']).indexOf(assignee);
    return state.updateIn(['account', 'assigneesActive'], users => users.splice(index, 1));
  } else {
    const assignee = state.getIn(['account', 'users']).find(assignee => assignee.get('_id') === user.get('_id'));
    const updatedAssignee = assignee
      .set('name', assignee.get('firstName') + ' ' + assignee.get('lastName'))
      .set('userId', assignee.get('_id'));
    return state.updateIn(['account', 'assigneesActive'], users => users.splice(0, 0, updatedAssignee));
  }
}
