import React from 'react';
import { Tasks } from './Tasks';
import { SearchGTL } from '@tradetrax/web-common/lib/Inputs/SearchGTL';

export const LeadTimeTasks = ({ isMyProfile = true, ...props }) => {
  const [expanded, expand] = React.useState(null);
  const message = isMyProfile
    ? 'Select the Tasks for which you want to be notified days before they start.'
    : 'Select the Tasks that should be notified to your new users days before they start.';

  const { controller, state } = props;
  const leadTimeTasks = state.get('leadTimeTasks');
  const addTask = task => {
    const taskName = task.get('name');
    const taskId = task.get('_id');
    controller.createLeadTimeTask({ taskId, taskName, task });
  };

  return (
    <div>
      <h5 className="font-weight-bold mb-4">Lead Time</h5>
      <p className="mb-4">{message}</p>
      <SearchGTL selectedTasks={leadTimeTasks} expand={expand} addTask={addTask} state={state} />
      <Tasks {...props} expanded={expanded} expand={expand} />
    </div>
  );
};
