import { fromJS } from 'immutable';
import { markAsSideEffect } from '@tradetrax/web-common';
import { subsService } from 'services';
import { RemoveTaskOfInterestModal } from './TasksOfInterest.remove.modal';

markAsSideEffect(loadGTL);
export function loadGTL() {
  subsService
    .readGTL({}, { query: { isChildTask: 'all' } })
    .then(fromJS)
    .then(tasks => this.controller.dispatch([state => state.set('globalTasks', tasks)]));
}

markAsSideEffect(loadTasksOfInterest);
export function loadTasksOfInterest() {
  const isLoading = false;
  subsService
    .getTasksOfInterest()
    .then(fromJS)
    .then(tasksOfInterest => this.controller.dispatch([state => state.merge({ tasksOfInterest, isLoading })]));
}

export function addTaskOfInterest(globalTask) {
  const taskId = globalTask.get('_id');
  const name = globalTask.get('name');

  return subsService
    .createTaskOfInterest({ taskId })
    .then(({ _id }) => {
      this.addAlert('Task of Interest successfully added.');
      return state => state.update('tasksOfInterest', tois => tois.unshift(fromJS({ _id, name })));
    })
    .catch(this.errorMessage('There was a problem adding this Task of Interest. Please try again.', 'danger'));
}

markAsSideEffect(deleteTaskOfInterest);
export async function deleteTaskOfInterest(toi) {
  const { isCancel } = await this.modal.open(RemoveTaskOfInterestModal);
  if (isCancel) return;

  const taskId = toi.get('_id');
  subsService
    .deleteTaskOfInterest({}, { params: { taskId } })
    .then(() => {
      this.controller.dispatch([
        state =>
          state.update('tasksOfInterest', tois => {
            const index = tois.indexOf(toi);
            return index >= 0 ? tois.remove(index) : tois;
          }),
      ]);
      this.addAlert('Task Type successfully removed.');
    })
    .catch(this.errorMessage('There was a problem removing this Task Type. Please try again.'));
}
