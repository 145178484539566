import { useEffect } from 'react';
import { fromJS } from 'immutable';
import { markAsSync, useController } from '@tradetrax/web-common';
import { emptyFilter } from '@tradetrax/web-common/lib/Task/TasksFilter';
import * as requestsActions from '@tradetrax/web-common/lib/Dashboard/Requests/RequestAndOverdueActions';
import { updateFiltersOnDataBase } from '@tradetrax/web-common/lib/Dashboard/DashboardActions';
import { useEvent } from '@tradetrax/web-common/lib/useEvents';
import { openChangeDateModalUR } from '../../DashboardPageContext';
import { subsService } from 'services';
import { useAppContext } from 'app/AppContext';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

export const BULK_REQUESTS = 'BULK_REQUESTS';
export const BULK_OVERDUE = 'BULK_OVERDUE';

export const emptyState = fromJS({
  tasksUpdateRequest: { isLoading: true },
  tasksOverdue: { isLoading: true },
  isFilterActive: false,
  filter: emptyFilter,
  isManageTasks: false,
  disabledBulk: false,
});

const actions = {
  ...requestsActions,
  updateFiltersOnDataBase,
  openChangeDateModalUR,
  toggleManageTasks,
  disableRequestsSection,
  disableOverdueSection,
  enableBulkSections,
};

export function RequestAndOverdueDetailsContext() {
  const appContext = useAppContext();
  const filterContext = useFilterContext(FILTER_NAME.UR_OVERDUE);
  const { filterState } = filterContext;
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    dashboardService: subsService,
    filterState,
  });
  const filter = filterState.get('values');

  useEvent('bulkActions:disableRequests', controller.disableRequestsSection);
  useEvent('bulkActions:disableOvedue', controller.disableOverdueSection);
  useEvent('bulkActions:enableSections', controller.enableBulkSections);

  useEffect(() => {
    controller.readUpdateRequests();
    controller.readTasksOverdue();
  }, [controller, filter]);

  return { state, controller, filterContext };
}

markAsSync(toggleManageTasks);
function toggleManageTasks(state) {
  return state.update('isManageTasks', isManageTasks => !isManageTasks);
}

markAsSync(disableRequestsSection);
function disableRequestsSection(state) {
  return state.set('disabledBulk', BULK_REQUESTS);
}

markAsSync(disableOverdueSection);
function disableOverdueSection(state) {
  return state.set('disabledBulk', BULK_OVERDUE);
}

markAsSync(enableBulkSections);
function enableBulkSections(state) {
  return state.set('disabledBulk', false);
}
