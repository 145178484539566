import React from 'react';
import { subsService } from 'services';
import {
  DashboardContainer,
  RequestsOverdueSection,
  ToDoSection,
  UpcomingSection,
  CommitmentRequests,
  RecentActivitySection,
} from '@tradetrax/web-common/lib/Dashboard';
import { DashboardPageContext } from '../DashboardPageContext';
import { useCommitmentRequestsCounters } from '../WorkloadPlanning/useCommitmentRequestsCounters';
import { useAppContext } from 'app/AppContext';
import navigate, { Paths } from 'app/navigate';

export const DashboardV2 = () => {
  const appContext = useAppContext();
  const context = DashboardPageContext(appContext);
  const counters = useCommitmentRequestsCounters();
  const { state } = context;
  const { tasksUpdateRequest, tasksOverdue } = state.toObject();
  const emptyRequest = tasksUpdateRequest.size === 0 && tasksOverdue.size === 0;

  const linkToTaskDetails = (jobId, taskId) => navigate.to.TaskDetails({ jobId, taskId, status: 'open' }, false);
  const linkToRequestAndOverdueDetails = () => navigate.to.RequestAndOverdueDetails();
  const linkToJob = jobId => navigate.to.JobDetails({ jobId }, false);
  const linkUpcomingTasks = () => navigate.to.UpcomingTasks({}, false);
  const linkCommunityDetails = communityId => navigate.to.CommunityDetails({ communityId }, false);

  return (
    <DashboardContainer emptyRequest={emptyRequest}>
      <h4 className="font-weight-bold mb-4">Dashboard</h4>
      <div className="dashboard-container">
        <RequestsOverdueSection
          context={context}
          appContext={appContext}
          service={subsService}
          linkToTaskDetails={linkToTaskDetails}
          linkToRequestAndOverdueDetails={linkToRequestAndOverdueDetails}
        />
        <ToDoSection context={context} appContext={appContext} service={subsService} />
        <CommitmentRequests>
          <CommitmentRequests.Card
            variant="primary"
            count={counters.open}
            to={Paths.WorkloadPlanning}
            label="Commitment Requests for this week"
          />
          <CommitmentRequests.Card
            variant="danger"
            count={counters.overdue}
            to={`${Paths.WorkloadPlanning}#overdue`}
            label="Overdue Commitment Requests"
          />
        </CommitmentRequests>
        <UpcomingSection
          context={context}
          appContext={appContext}
          service={subsService}
          linkToTaskDetails={linkToTaskDetails}
          linkToJob={linkToJob}
          linkUpcomingTasks={linkUpcomingTasks}
          linkCommunityDetails={linkCommunityDetails}
        />
        <RecentActivitySection
          context={context}
          appContext={appContext}
          linkToTask={linkToTaskDetails}
          linkToJob={linkToJob}
        />
      </div>
    </DashboardContainer>
  );
};
