import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'app/navigate';
import { NotFound } from '@tradetrax/web-common';
import { CustomRoleDetails } from './CustomRoles/CustomRoleDetails';
import { SettingsPage } from './SettingsPage';
import { SettingsContext } from './SettingsContext';

export const SettingsRoutes = () => {
  const context = SettingsContext();
  const { customRoles, globalTasks } = context.state.toObject();
  return (
    <Switch>
      <Route exact path={Paths.Settings} render={props => <SettingsPage {...props} context={context} />} />
      <Route
        path={Paths.CustomRoleDetails}
        render={props => (
          <CustomRoleDetails {...props} context={context} customRoles={customRoles} globalTasks={globalTasks} />
        )}
      />
      <Route component={NotFound} />
    </Switch>
  );
};
